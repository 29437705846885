<template>
  <li class="menu-mobile__item">
    <a v-if="href" :href="href" target="_blank">{{ label }}</a>
    <RouterLink
      v-else
      :to="to"
      active-class="menu-mobile__item--active"
      @click="handleSameRoute"
    >
      {{ label }}
    </RouterLink>
  </li>
</template>

<script setup>
import { usePopupStore } from "@/store/modules/popup.store";
import { defineProps, onBeforeUnmount, ref } from "vue";

const closeTimeout = ref(null);
const popupStore = usePopupStore();

defineProps({
  to: [Object, String],
  href: String,
  label: String,
});

function handleSameRoute() {
  closeTimeout.value = window.setTimeout(() => {
    if (popupStore.menu) {
      popupStore.closeMenu();
    }
  });
}

onBeforeUnmount(() => {
  if (closeTimeout.value) {
    window.clearTimeout(closeTimeout.value);
  }
});
</script>

<style lang="scss" scoped>
.menu-mobile__item {
  font-family: $font-family;

  & > a {
    font-size: $font-size-lg;
    font-weight: 700;
    text-decoration: none;
    // text-transform: uppercase;
    color: $color-menu-item;
    padding: 20px 30px;
    display: block;
  }

  &--active {
    color: $color-primary !important;
  }
}
</style>

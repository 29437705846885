<template>
  <div class="form-group">
    <label v-if="label" :for="name" @click="$refs.input.focus()"
      >{{ label }}
      <slot name="label-link"></slot>
    </label>
    <Field
      v-slot="{ field, errors }"
      :rules="rules"
      :name="name"
      :label="(label || placeholder).toLowerCase()"
      :model-value="inputValue"
    >
      <div class="password">
        <input
          v-bind="field"
          class="form-control"
          :name="name"
          :type="typePassword"
          :placeholder="placeholder"
          :maxlength="maxLength"
          :max="maxLimit"
          :min="minLimit"
          :disabled="disabled"
          :class="{ 'form-control--is-invalid': !!errors.length }"
        />
        <img
          :src="passwordIcon"
          alt="Password visibility"
          @click="toggleVisibility"
        />
      </div>
    </Field>
    <ErrorMessage v-slot="{ message }" :name="name">
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>
<script>
import { ErrorMessage, Field } from "vee-validate";
import IconEye from "@/assets/eye-outline.svg";
import IconEyeOff from "@/assets/eye-off-outline.svg";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
    rows: Number,
    maxLength: Number,
    maxLimit: Number,
    minLimit: Number,
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
      default: "",
    },
  },
  data() {
    return {
      innerValue: "",
      passwordVisible: false,
    };
  },
  computed: {
    typePassword() {
      return !this.passwordVisible ? "password" : "text";
    },
    passwordIcon() {
      return this.passwordVisible ? IconEyeOff : IconEye;
    },
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    },
  },
  mounted() {
    if (this.inputValue !== null) {
      this.innerValue = this.inputValue;
    }
  },
  methods: {
    toggleVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-family: $font-family;
  font-size: 13px;
  margin-top: 5px;
}

.password {
  position: relative;

  img {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
  }
}

input {
  padding: 15px 45px 15px 20px;
  display: block;

  width: 100%;

  background-color: $color-light-grey;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;

  &:focus {
    background-color: #fff;
    border: 1px solid $color-menu-item;
  }
}
</style>

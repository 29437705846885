<template>
  <div class="clients">
    <div class="container">
      <h3 v-dompurify-html="content.title"></h3>
      <p v-dompurify-html="content.content"></p>

      <div class="clients__wrap">
        <swiper
          :breakpoints="swiperOptions.breakpoints"
          :loop="true"
          :slides-per-view="6"
          :space-between="50"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="client in clients" :key="client.title">
            <div class="clients__block">
              <a :href="client.link">
                <img :src="client.image" />
              </a>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    content: Object,
  },
  data() {
    return {
      clients: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    };
  },
  watch: {
    "$i18n.locale": async function () {
      await this.getClientsList();
    },
  },
  async created() {
    this.getClientsList();
  },
  methods: {
    onSlideChange() {},
    async getClientsList() {
      let vm = this;
      this.loading = true;
      await axios
        .get("/banners/8")
        .then((response) => {
          vm.clients = response.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.clients {
  height: 310px;
  border-top: 1px solid #dcdee9;

  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $header-h3-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    padding-top: 50px;
  }

  p {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    padding-top: 5px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    flex-wrap: wrap;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.clients {
  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}
</style>

import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";
import i18n from "@/i18n";
import { useProductsStore } from "@/store/modules/products.store";
import { useAlertStore } from "@/store";

export const useReviewStore = defineStore("review", () => {
  const productsStore = useProductsStore();
  const alertStore = useAlertStore();

  const loading = ref(false);

  const storeReview = async (courseShortName, rating, review) => {
    await backService
      .post("reviews", {
        courseId: productsStore.current.id,
        rating,
        review,
      })
      .then(() => {
        productsStore.requestCurrent(courseShortName);
      })
      .catch((response) => {
        if (response.response.status === 400) {
          alertStore.error(i18n.global.t("reviews.incorrect_data"));
          return;
        }
        alertStore.error(
          i18n.global.t("reviews.error_while_trying_to_save_review"),
        );
      });
  };

  return {
    loading,

    storeReview,
  };
});

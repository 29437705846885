<template>
  <div class="contact-us">
    <div class="container">
      <h3>{{ $t("common.interested_in_courses_for_larger_groups") }}</h3>
      <p>
        {{
          $t(
            "common.fill_out_the_inquiry_form_and_we_will_contact_you_shortly_with_an_individual_offer",
          )
        }}
      </p>
      <form>
        <div class="contact-us__form-group">
          <button type="submit" name="" @click="contact()">
            {{ $t("common.fill_out_the_request_form") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    return {
      router,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    contact() {
      this.router.push({
        name: "contacts",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-us {
  min-height: 300px;
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  h3 {
    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 0px;
  }

  p {
    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;
  }

  &__form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 40px;

    flex-wrap: wrap;

    input {
      border: 1px solid #dcdee9;
      border-radius: 4px;
      background-color: $color-primary;
      color: #ffffff;
      padding: 17px 20px;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
      width: 100%;
      max-width: 335px;

      &::placeholder {
        color: #ffffff;
      }
    }

    button {
      padding: 17px 20px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #ffffff;
      font-size: 13px;
      color: $color-blue;
      font-family: $font-family;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}
</style>

<template>
  <div>
    <Navigation />
    <HelpHeader />

    <div class="offer">
      <div class="container">
        <h3 class="offer__header">
          {{ $t("common.get_in_touch_easily_by_filling_out_the_contact_form") }}
        </h3>
        <Form @submit="send">
          <div class="offer__row">
            <div class="offer__column">
              <TextField
                rules="required"
                placeholder="Vardenis Pavardenis"
                :name="'name'"
                :label="'Jūsų vardas'"
              />
            </div>
            <div class="offer__column">
              <TextField
                rules="required"
                placeholder="+370"
                :name="'phone'"
                :label="'Telefono numeris'"
              />
            </div>
          </div>
          <div class="offer__row">
            <div class="offer__column">
              <TextField
                rules="required"
                :name="'email'"
                placeholder="Jūsų el. paštas"
                :label="'El. paštas'"
              />
            </div>
            <div class="offer__column">
              <SelectInputField
                rules="required"
                :options="subjects"
                :name="'subject'"
                :label="'Pasirinkite temą'"
              />
            </div>
          </div>

          <TextareaField
            rules="required"
            placeholder="Įrašykite savo žinutę"
            :label="'Žinutė'"
          />

          <div>
            <CustomButton :type="'dark'" :text="$t('common.send_request')" />
          </div>
        </Form>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import HelpHeader from "@/components/HelpHeader.vue";
import CustomButton from "@/components/CustomButton.vue";
import TextField from "@/components/form/TextField.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import { Form } from "vee-validate";
import backService from "@/plugins/service";
import { useAlertStore } from "@/store";
import SelectInputField from "@/components/form/SelectInputField.vue";

export default {
  components: {
    Navigation,
    Footer,
    HelpHeader,
    CustomButton,
    TextField,
    TextareaField,
    Form,
    SelectInputField,
  },
  setup() {
    const alertStore = useAlertStore();

    return {
      alertStore,
    };
  },
  data() {
    return {
      subjects: [],
    };
  },
  async created() {
    await this.getSubjects();
  },
  methods: {
    async getSubjects() {
      await backService.get("/contact/subjects").then((response) => {
        response.data.forEach((item) => {
          this.subjects.push({
            name: item,
            value: item,
          });
        });
      });
    },
    async send(values, actions) {
      await backService
        .post("/contact/by-email", {
          ...values,
        })
        .then(() => {
          this.alertStore.success("Pavyko!", "Sėkmingai išsiųsta");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.offer {
  margin-bottom: 80px;

  &__header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $help-header-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 30px;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__column {
    flex-basis: 100%;
  }
}
</style>

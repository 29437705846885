<template>
  <div class="material">
    <div class="material__wrap">
      <div
        v-if="material"
        class="material__item"
        :class="{ 'material__item--done': material.completed }"
      >
        <div class="material__name">
          <div v-if="isCompletionAutomatic" class="material__status">
            <img :src="completionIcon" alt="checkbox" />
          </div>
          <div
            v-if="isCompletionManual"
            class="material__status material__set-completed"
          >
            <img
              :src="completionIcon"
              alt="checkbox"
              @click="setManualCompletion"
            />
          </div>
          <div class="material__title">
            <div v-dompurify-html="material.title" />
          </div>
        </div>
        <div v-if="readonly" class="material__description">
          <div v-if="hasTimer" class="material__time material__time--desktop">
            <img src="../../assets/course/course_clock.svg" alt="clock" />
            {{ material.duration }}
          </div>
          <a
            v-if="material.dynamic_form"
            class="material__video"
            :href="material.dynamic_form"
            :class="{ material__activity__unavailable: !isAvailable }"
            target="_blank"
            @click="(e) => openActivity(e, true)"
          >
            <img
              src="../../assets/course/dynamicform-gray.svg"
              alt="dynamic form"
            />
            {{ $t("common.situation_material") }}
          </a>
          <div
            v-if="material.video"
            class="material__video"
            :class="{ material__activity__unavailable: !isAvailable }"
            @click.prevent="onVideo"
          >
            <img src="../../assets/course/course_video.svg" alt="video" />
            {{ $t("common.video_material") }}
          </div>
          <a
            v-if="material.document"
            class="material__activity"
            :href="material.document"
            :class="{ material__activity__unavailable: !isAvailable }"
            target="_blank"
            @click="(e) => openActivity(e, true)"
          >
            <img src="../../assets/course/document-gray.svg" alt="document" />
            {{ $t("common.document_material") }}
          </a>
          <a
            v-if="material.document_video"
            class="material__activity"
            :class="{ material__activity__unavailable: !isAvailable }"
            :href="material.document_video"
            target="_blank"
            @click="(e) => openActivity(e, true)"
          >
            <img
              src="../../assets/course/course_video.svg"
              alt="course video"
            />
            {{ $t("common.video_material") }}
          </a>
          <a
            v-if="material.algorithm"
            :href="material.algorithm"
            class="material__activity"
            :class="{ material__activity__unavailable: !isAvailable }"
            target="_blank"
            @click="(e) => openActivity(e, true)"
          >
            <img
              src="../../assets/course/hlalgorithm-gray.svg"
              alt="algorithm"
            />
            {{ $t("common.algorithm") }}
          </a>
          <a
            v-if="material.extlmsroom"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            :href="material.extlmsroom"
            target="_blank"
            @click="(e) => openActivity(e)"
          >
            <img
              src="../../assets/course/extlms-room-gray.svg"
              alt="extlms room"
            />
            {{ $t("common.extlms_room_material") }}
          </a>
          <a
            v-if="material.hasOwnProperty('resource')"
            :class="[
              !isAvailable ? 'material__activity__unavailable' : '',
              !material.resource ? 'cursor-not-allowed' : '',
            ]"
            class="material__activity"
            :href="material.resource"
            target="_blank"
            @click="(e) => openActivity(e, false, !material.resource)"
          >
            <img
              src="../../assets/course/resource-gray.svg"
              alt="extlms room"
            />
            {{ $t("common.resource") }}
          </a>
          <a
            v-if="material.survey"
            :href="material.survey"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            @click.prevent="
              (e) => viewActivity(e, material.survey, material.title)
            "
          >
            <img
              src="../../assets/course/questionnaire-gray.svg"
              alt="questionnaire"
            />
            {{ $t("common.questionnaire") }}
          </a>
          <a
            v-if="material.quiz"
            :href="material.quiz"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            @click.prevent="
              (e) => viewActivity(e, material.quiz, material.title)
            "
          >
            <img src="../../assets/course/quiz-gray.svg" alt="quiz" />
            {{ $t("common.quiz") }}
          </a>
          <a
            v-if="material.forum"
            :href="material.forum"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            @click.prevent="
              (e) => viewActivity(e, material.forum, material.title)
            "
          >
            <img src="../../assets/course/forum-gray.svg" alt="forum" />
            {{ $t("common.forum") }}
          </a>
          <a
            v-if="material.hvp"
            :href="material.hvp"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            @click.prevent="
              (e) => viewActivity(e, material.hvp, material.title)
            "
          >
            <img src="../../assets/course/hvp-gray.svg" alt="hvp" />
            {{ $t("common.hvp") }}
          </a>
          <a
            v-if="material.link"
            class="material__activity"
            :href="material.link"
            target="_blank"
            @click="(e) => openActivity(e, true)"
          >
            <img src="../../assets/course/link-gray.svg" alt="link" />
            {{ $t("common.link_material") }}
          </a>
          <a
            v-if="material.certificate"
            :href="material.certificate"
            :class="{ material__activity__unavailable: !isAvailable }"
            class="material__activity"
            @click.prevent="
              (e) => viewActivity(e, material.certificate, material.title)
            "
          >
            <img
              src="../../assets/icons/certificate-lightblue.svg"
              alt="certificate"
            />
            {{ $t("common.certificate") }}
          </a>
          <div v-if="hasTimer" class="material__time material__time--mobile">
            <img src="../../assets/course/course_clock.svg" alt="clock" />
            {{ material.duration }}
          </div>
        </div>
        <div
          v-if="material.availability_information"
          class="material__availability"
        >
          <img alt="alert" src="../../assets/icons/alert-red.svg" />
          <span v-dompurify-html="material.availability_information" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, getCurrentInstance } from "vue";
import {
  AUTO_COMPLETION,
  MANUAL_COMPLETION,
} from "@/store/modules/completions.store";
import router from "@/router";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import CheckWhite from "@/assets/icons/check-white.svg";
import CheckGrey from "@/assets/icons/check-grey.svg";

const NO_TIMER_ACTIVITIES = [
  "questionnaire",
  "forum",
  "quiz",
  "certificate",
  ...(!isShoppingEnabled() ? ["extlmsroom"] : []),
];

const emit = defineEmits(["set:completion"]);
const props = defineProps({ material: Object });

const vm = getCurrentInstance();

const isCompletionManual = computed(
  () => props.material.completion === MANUAL_COMPLETION,
);
const isCompletionAutomatic = computed(
  () => props.material.completion === AUTO_COMPLETION,
);
const isAvailable = computed(() => props.material.availability_status);
const readonly = computed(() => props.material.readonly === false);
const hasTimer = computed(
  () => !NO_TIMER_ACTIVITIES.includes(props.material.module),
);
const completionIcon = computed(() =>
  props.material.completed ? CheckWhite : CheckGrey,
);

const onVideo = () => {
  if (!isAvailable.value) {
    return;
  }

  setAutoCompletion();
  /** @type {import('mitt').Emitter} */
  const emitter = vm.appContext.config.globalProperties.emitter;

  emitter.emit("video", {
    description: props.material.description,
    video: props.material.video,
  });
};
const setManualCompletion = () => {
  if (props.material.completion === MANUAL_COMPLETION) {
    emit("set:completion", props.material);
  }
};
const setAutoCompletion = () => {
  if (props.material.completion === AUTO_COMPLETION) {
    emit("set:completion", props.material);
  }
};
const openActivity = (e, checkAutoCompletion = false, preventOpen = false) => {
  if (!isAvailable.value || preventOpen) {
    e.preventDefault();
    return;
  }

  if (checkAutoCompletion) {
    setAutoCompletion();
  }
};
const viewActivity = (e, url, title) => {
  if (!isAvailable.value) {
    e.preventDefault();
    return;
  }

  router.push({
    name: ROUTE.Course.Activity,
    query: {
      json: JSON.stringify({ url, title, backUrl: window.location.href }),
    },
  });
};
</script>

<style lang="scss" scoped>
:deep(.material__availability) {
  background-color: $color-light-grey;
  padding: 15px 0;
  color: $color-menu-item;
  font-size: $font-size-xs;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  img {
    padding: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      padding: 5px 0;
    }
  }

  a {
    color: $color-menu-item;
  }
}

.material {
  border-bottom: 1px solid $color-light-grey;

  &:last-child {
    box-shadow: none;
  }

  &__set-completed {
    cursor: pointer;
  }

  &__item {
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    gap: 22px 0;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    padding: 10px 0;

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      padding: 10px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 0;
    }
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: unset;
    }

    &--done {
      .material__status {
        background: $color-green;
        width: 38px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: 49%;

    @media screen and (max-width: $breakpoint-sm) {
      max-width: unset;
    }
  }

  &__status {
    height: 26px;
    width: 26px;
    background-color: $color-light-grey;
    border-radius: 30px;
    margin: 0 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 24px;
    min-height: 24px;
  }

  &__title {
    flex: 1;
    color: $color-black;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 22px;
  }

  &__description {
    display: flex;
    gap: 10px;

    @media screen and (max-width: 1200px) and (min-width: 900px) {
      flex-direction: column;
    }
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: space-between;
    }
  }

  &__video,
  &__activity {
    justify-content: flex-start;

    &:hover {
      background-color: darken($color-light-blue, 3);
    }

    img {
      width: 18px;
      height: 18px;
    }

    &__unavailable {
      cursor: not-allowed;
    }
  }

  &__activity:not(.cursor-not-allowed) {
    cursor: pointer;
  }

  &__time,
  &__video,
  &__activity {
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: 17px;
    background-color: $color-light-blue;

    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 18px;

    padding: 5px 16px;
    gap: 10px;
    width: 160px;
    text-decoration: none;
  }

  &__time {
    justify-content: center;

    @media screen and (max-width: $breakpoint-sm) {
      width: unset !important;
      background-color: transparent !important;
      padding-left: 54px !important;

      img {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @media screen and (max-width: $breakpoint-sm) {
        display: flex;
      }
    }

    &--desktop {
      display: flex;

      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
    }
  }
}
</style>

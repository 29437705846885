<template>
  <div>
    <Navigation />
    <HeaderBlock />
    <div class="bg-grey">
      <div class="container">
        <div class="order">
          <div class="order__back">
            <router-link :to="{ name: 'dashboard.purchases' }">
              {{ $t("common.back") }}
            </router-link>
          </div>

          <div class="order__header">
            <div>
              <h3>{{ $t("common.order_id") }} {{ order.order_id }}</h3>
              <div class="order__date">{{ order.date_added }}</div>
            </div>
            <div>
              <CustomButton
                :type="'round-white'"
                :size="'md'"
                :text="$t('common.create_invoice')"
                @click="generateInvoice"
              />
            </div>
          </div>

          <div class="order__wrap">
            <div
              v-for="product in order.products"
              :key="product.product_id"
              class="order__item"
            >
              <div class="order__name">{{ product.name }}</div>
              <div class="order__price">{{ product.price_raw }} €</div>
            </div>
            <!-- <div class="order__item" v-if="order.vouchers.length != 0">
                            <div class="order__name">Nuolaida</div>
                            <div class="order__price">– 5,00 €</div>
                        </div> -->
            <div class="order__item">
              <div class="order__name">{{ $t("common.payment_method") }}</div>
              <div class="order__price">{{ order.payment_method }}</div>
            </div>
            <div
              v-for="total in order.totals"
              :key="total.code"
              class="order__item"
            >
              <div class="order__name order__name--bold">
                {{ total.title }}:
              </div>
              <div class="order__price order__price--bold">
                {{ parseFloat(total.value).toFixed(2) }} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import Footer from "@/components/Footer";
import CustomButton from "@/components/CustomButton";
import Navigation from "@/components/Navigation.vue";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  components: {
    HeaderBlock,
    Footer,
    CustomButton,
    Navigation,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      order: {},
      loading: false,
    };
  },
  async created() {
    await this.getPurchasedCourse(this.route.params.id);
  },
  methods: {
    print(html) {
      var w = window.open("", "_blank", "");
      w.document.write(html);
      w.document.close();
      w.focus();
      w.onload = function () {
        setTimeout(() => {
          w.print();
        }, 500);
      };
    },
    async generateInvoice() {
      let vm = this;
      await axios
        .get(`/customerorderinvoice/${this.route.params.id}`)
        .then((response) => {
          vm.print(response.data);
        })
        .catch(console.error);
    },
    async getPurchasedCourse(id) {
      let vm = this;
      this.loading = true;
      await axios
        .get(`/customerorders/${id}`)
        .then((response) => {
          vm.order = response.data.data;
          this.loading = false;
        })
        .catch(console.error);
    },
  },
};
</script>
<style lang="scss" scoped>
.order {
  padding-top: 60px;
  padding-bottom: 80px;

  &__back {
    margin-bottom: 16px;

    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;

    a {
      text-decoration: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      gap: 20px;
    }

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__date {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid $color-light-grey;
  }

  &__name {
    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;

    @media screen and (max-width: 600px) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 230px;
    }

    &--bold {
      font-weight: 500;
    }
  }

  &__price {
    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;

    &--bold {
      font-size: 20px;
      font-weight: 500;
    }
  }
}
</style>

import { defineStore } from "pinia";
import { useCountriesStore } from "./countries.store";
import axios from "axios";

export const useCitiesStore = defineStore({
  id: "cities",
  state() {
    return {
      loading: false,
      error: null,
      data: new Map(),
    };
  },
  getters: {
    options() {
      const countriesStore = useCountriesStore();
      const current = countriesStore.currentCountryId;

      if (!current) return [];
      if (!this.data.has(current)) return [];

      return this.data.get(current).data.zone.map((x) => ({
        name: x.name,
        value: x.name,
      }));
    },
  },
  actions: {
    async request() {
      const countriesStore = useCountriesStore();

      const country = countriesStore.currentCountryId;
      if (!country) return;

      if (this.data[country]) {
        this.loading = false;
        this.error = null;

        return true;
      }

      this.loading = true;

      try {
        const id = countriesStore.currentCountryId;
        const { data } = await axios.request(`/countries/${id}`);

        this.loading = false;
        this.error = null;
        this.data.set(country, data);
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
  },
});

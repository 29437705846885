import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useScrollPos() {
  const scrollPosition = ref(null);

  const checkScroll = () => {
    scrollPosition.value = window.scrollY;
  };

  onMounted(() => {
    window.addEventListener("scroll", checkScroll);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", checkScroll);
  });

  return {
    scrollPosition,
  };
}

import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";
import { getPurchasedStore } from "@/store/modules/purchased.store";

export const MANUAL_COMPLETION = 1;
export const AUTO_COMPLETION = 2;

export const useCompletionsStore = defineStore("completions", () => {
  const purchasedStore = getPurchasedStore();

  const loading = ref(false);

  const setCompleted = async (
    moduleName,
    courseModuleId,
    completed,
    courseId,
  ) => {
    try {
      loading.value = true;
      await backService.post("/courses/activity/completion", {
        module: moduleName,
        id: courseModuleId.toString(),
        state: completed,
      });
      await purchasedStore.request(courseId);

      loading.value = false;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return {
    loading,
    setCompleted,
  };
});

/*
 * Object.prototype.exceptProps
 * propsToExclude - array of strings
 */
Object.defineProperty(Object.prototype, "exceptProps", {
  value: function (propsToExclude) {
    const obj = this;
    const newObj = {};

    for (const key in obj) {
      if (!propsToExclude.includes(key)) {
        newObj[key] = obj[key];
      }
    }

    return newObj;
  },
  writable: false,
  enumerable: false,
});

<template>
  <div class="menu-mobile__navbar">
    <button
      class="menu-mobile__navbar-close"
      type="button"
      @click="popupStore.closeMenu"
    >
      <img src="../../assets/x-1.svg" />
    </button>
    <div class="menu-mobile__navbar-label">
      <router-link
        class="menu-mobile__navbar-brand"
        :to="{ name: ROUTE.Home }"
        :title="$t('common.home')"
      >
        <img src="../../assets/menu/hl-logo.svg" alt="hl-logo" />
      </router-link>
      <template v-if="!isShopEnabled">
        <router-link
          class="menu-mobile__navbar-brand"
          :to="{ name: ROUTE.Home }"
          :title="$t('common.home')"
        >
          <img src="../../assets/menu/lsmu-logo.svg" alt="lsmu-logo" />
        </router-link>
      </template>
    </div>
    <Avatar
      v-if="auth2Store.isLoggedIn"
      :image="settingsStore.values.image"
      :account="accountStore.values"
      @click.prevent="popupStore.openMenuSettings"
    />
  </div>
</template>

<script setup>
import { isShoppingEnabled } from "@/util/helpers";
import { computed } from "vue";
import { ROUTE } from "@/router/routenames";
import Avatar from "@/components/Avatar.vue";
import { useStores } from "@/store/use.store";

const { popupStore, auth2Store, settingsStore, accountStore } = useStores();

const isShopEnabled = computed(() => isShoppingEnabled());
</script>

<style lang="scss" scoped>
.menu-mobile__navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 20px;

  &-close {
    border: none;
    background: transparent;
    padding: 0;
  }

  &-label {
    display: flex;
    gap: 16px;
    height: 100%;
    align-items: center;
    font-weight: 500;
    font-size: $font-size-lg;
  }

  &-link--icon {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;

    & > img {
      margin-right: 0;
    }
  }

  &-brand {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 2px 0;

    img {
      height: 52px;
    }
  }
}
</style>

<template>
  <div class="progress" :style="{ 'background-color': backgroundColor }">
    <div
      class="progress__inner"
      :style="[
        { width: `${percent}%` },
        { 'background-color': backgroundColorActive },
      ]"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      default: "$color-blue",
    },
    backgroundColorActive: {
      default: "red",
    },
    percent: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.progress {
  margin-top: 5px;
  position: relative;
  height: 3px;
  width: 100%;
  background-color: $color-blue;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    border-radius: 3px;
    background-color: $color-blue;
  }
}
</style>

<template>
  <Loading />
</template>

<script setup>
import Loading from "@/components/Loading.vue";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";

const router = useRouter();

const isIOS = () => {
  if (typeof navigator === `undefined`) return false;

  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

if (isIOS() && process.env.VUE_APP_APPLE_APP_LINK) {
  window.location.replace(process.env.VUE_APP_APPLE_APP_LINK);
} else if (process.env.VUE_APP_GOOGLE_APP_LINK) {
  window.location.replace(process.env.VUE_APP_GOOGLE_APP_LINK);
} else {
  router.replace({ name: ROUTE.Home });
}
</script>

<style lang="scss"></style>

<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :is-main="true"
    :show-navigation="true"
    :show-header-block="true"
    :title="$t('common.favourites')"
  >
    <div class="container">
      <div class="courses" :class="{ courses_mobile: isMobileShop }">
        <div class="courses__favourites">
          <Loading v-if="loading" />
          <Course
            v-for="course in list"
            v-else
            :key="course.id"
            :course="course"
            :paid="false"
          />
        </div>
        <div v-if="pageCount > 1" class="discover-courses__footer">
          <Pagination
            :page="page"
            :page-count="pageCount"
            :total="total"
            :from="from"
            :to="to"
            @update="favouriteCoursesStore.requestCourseList()"
          />
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";
import Course from "@/components/course/Course.vue";
import Loading from "@/components/Loading.vue";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount } from "vue";
import Pagination from "@/components/Pagination.vue";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { isShoppingEnabled } from "@/util/helpers";
import useIsMobile from "@/util/useIsMobile";

const favouriteCoursesStore = useFavouriteCourses();

const { isMobile } = useIsMobile();
const { list, page, pageCount, from, to, total, loading } = storeToRefs(
  favouriteCoursesStore,
);

const isMobileShop = computed(() => isMobile.value && isShoppingEnabled());

onBeforeMount(() => favouriteCoursesStore.requestCourseList());
</script>

<style lang="scss" scoped>
.courses {
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  padding-bottom: 80px;

  &_mobile {
    padding-top: 10px;
  }

  &__favourites {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 20px;
    margin-top: 45px;
    margin-bottom: 60px;
    justify-content: flex-start;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 0;
      margin-bottom: 10px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      justify-content: center;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__settings-label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__column {
    display: flex;
    align-items: center;
    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
    }

    &--switch {
      gap: 40px;
      @media screen and (max-width: $breakpoint-lg) {
        gap: 10px;
        width: 100%;
        align-items: flex-start;
      }
    }
  }

  &__wrap {
    overflow-x: auto;

    font-family: $font-family;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .course {
      padding: 13px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $color-light-grey;
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>

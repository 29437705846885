<template>
  <div class="discover-courses__block discover-courses__block--sidebar">
    <accordion :hide-others="false" :margin-bottom="0">
      <accordion-item
        v-for="filter in globalStore.listFilters()"
        :key="filter.filter_group_id"
        :active="filter.filter_group_id === route.query.filter_group_id"
      >
        <template #accordion-trigger>
          <div class="accordion__header">
            <h4>{{ filter.name }}</h4>
          </div>
        </template>
        <template #accordion-content>
          <div>
            <div v-for="area in filter.filter" :key="area.name">
              <CheckboxField
                :value="area.filter_id"
                :name="area.name"
                :label="area.name"
                @change="onFilters(area)"
              >
                <span class="accordion-item">{{ area.name }}</span>
              </CheckboxField>
            </div>
          </div>
        </template>
      </accordion-item>
    </accordion>
  </div>
</template>
<script>
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import { useProductsStore } from "@/store/modules/products.store";
import { useGlobalStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    AccordionItem,
    CheckboxField,
    Accordion,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const globalStore = useGlobalStore();
    const productsStore = useProductsStore();

    return {
      globalStore,
      route,
      router,
      productsStore,
    };
  },
  computed: {
    sort() {
      return this.route.query.sort || "default";
    },
    query() {
      return this.route.params;
    },
    category() {
      return this.route.query.category_id || "59";
    },
  },
  watch: {
    "route.query.q": {
      immediate: true,
      async handler() {
        this.request();
      },
    },
    "$i18n.locale": {
      immediate: true,
      async handler() {
        await this.globalStore.getFilters();
        await this.request();
      },
    },
  },
  async created() {
    this.globalStore.current_filters = [];
    if (this.route.query.filter_id) {
      this.globalStore.addFilterById(
        this.route.query.filter_id,
        this.route.query.filter_group_id,
      );
    }
  },
  methods: {
    async onFilters(value) {
      const selectedFilterIndex = this.globalStore.current_filters.findIndex(
        (filter) => filter.value === value.filter_id,
      );
      if (selectedFilterIndex !== -1) {
        this.globalStore.current_filters.splice(selectedFilterIndex, 1);
      } else {
        this.globalStore.current_filters.push({
          name: value?.name,
          value: value.filter_id,
        });
      }

      this.request();
    },
    request(page = 1) {
      const { sort, category } = this;
      const { query: filters } = this.globalStore;
      const { q: search } = this.route.query;
      this.productsStore.request(page, 12, { sort, category, filters, search });
    },
    onFilterRemove(value) {
      this.globalStore.current_filters =
        this.globalStore.current_filters.filter(function (item) {
          return item.value !== value;
        });

      this.request();
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-top: 1px solid #f1f5f9;
  margin-bottom: 10px;
  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
  }

  &__trigger_active {
    .accordion__header {
      &:after {
        background: url("../../assets/minus.svg");
        height: 1px;
      }
    }

    .accordion__content {
      display: block;
      margin-bottom: 0px;
      box-shadow: inset 0 -1px 0 0 rgba(21, 31, 109, 0.1);
    }
  }

  .accordion-item {
    flex: 1;
    color: $color-blue;
    font-size: $font-size-md;
  }

  &__switch {
    display: flex;
    align-items: center;
    color: $color-blue;
    font-family: $font-family;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 23px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &__header {
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      margin: 20px 0;
    }

    &:after {
      position: absolute;
      content: "";
      background: url("../../assets/plus.svg");
      height: 13px;
      width: 13px;
      right: 0px;
    }
  }

  &__content {
    display: none;
  }

  &__read-more {
    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 23px;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      margin-top: 15px;
      margin-bottom: 20px;
      text-decoration: none;
    }
  }
}
</style>

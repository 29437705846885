<template>
  <div>
    <Swiper
      class="home-slider"
      :modules="[Autoplay]"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
    >
      <SwiperSlide v-for="(slide, index) in slideshowStore.list" :key="index">
        <SliderNewMobile v-if="isMobile" :slide="slide" />
        <SliderNewDesktop v-else :slide="slide" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup>
import { useSlideshowStore } from "@/store/modules/slideshow.store";
import { Swiper, SwiperSlide } from "swiper/vue";
import i18n from "@/i18n";
import { watch } from "vue";
import { useMediaQuery } from "@vueuse/core";
import SliderNewMobile from "./SliderNewMobile.vue";
import SliderNewDesktop from "./SliderNewDesktop.vue";
import { Autoplay } from "swiper";

const slideshowStore = useSlideshowStore();
const isMobile = useMediaQuery("screen and (max-width: 900px)");

watch(
  () => JSON.stringify([i18n.global.locale, isMobile.value]),
  () => slideshowStore.request(isMobile.value ? "mobile" : "desktop"),
  { immediate: true },
);
</script>

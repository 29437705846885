import { onBeforeUnmount, onMounted, ref } from "vue";

export default function useIsMobile(breakpoint = 900) {
  const isMobile = ref(false);

  const checkWidth = () => {
    isMobile.value = window.innerWidth < breakpoint;
  };

  onMounted(() => {
    window.addEventListener("resize", checkWidth);
    checkWidth();
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", checkWidth);
  });

  return {
    isMobile,
    checkWidth,
  };
}

<template>
  <div class="alerts">
    <div class="alerts__wrap">
      <div
        v-for="(alert, index) in alertStore.alerts"
        :key="index"
        class="alerts__block"
        :class="[
          { 'alerts__block--success': alert.type === 'success' },
          { 'alerts__block--danger': alert.type === 'danger' },
          { 'alerts__block--warning': alert.type === 'warning' },
        ]"
      >
        <div class="alerts__content">
          <div v-if="alert.subject" class="alerts__subject">
            {{ alert.subject }}
          </div>
          <div v-if="alert.message">{{ alert.message }}</div>
        </div>
        <a
          class="alerts__close"
          href="#"
          @click.prevent="alertStore.removeByIndex(index)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.6663 2.68331L21.3163 0.333313L11.9997 9.64998L2.68301 0.333313L0.333008 2.68331L9.64967 12L0.333008 21.3166L2.68301 23.6666L11.9997 14.35L21.3163 23.6666L23.6663 21.3166L14.3497 12L23.6663 2.68331Z"
              fill="#2E181A"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { useAlertStore } from "@/store";

export default {
  components: {},
  setup() {
    const alertStore = useAlertStore();

    return {
      alertStore,
    };
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.alerts {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 999999999;
  font-family: $font-family;

  &__wrap {
  }

  &__block {
    padding: 10px;
    border-radius: 6px;
    min-width: 260px;
    min-height: 50px;
    max-width: 300px;
    position: relative;
    // opacity: 0.5;
    transition: opacity 0.3s;

    &:hover {
      // opacity: 1;
    }

    &--success {
      border-color: #4fb96e;
      background: #4fb96e;
      color: white;
    }

    &--danger {
      border-color: #c4262d;
      background: #c4262d;
      color: white;
    }

    &--warning {
      border-color: #f8c322;
      background: #f8c322;
      color: $color-blue;
    }

    margin-top: 10px;
  }

  &__content {
    margin-right: 30px;
    font-size: 13px;
  }

  &__subject {
    font-weight: bold;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 30px;
    padding: 10px;

    &:hover {
      background: #ddd;
    }
  }
}
</style>

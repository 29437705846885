<template>
  <div class="form-group">
    <label v-if="label" :for="name" @click="$refs.input.focus()"
      >{{ label }}
      <slot name="label-link"></slot>
    </label>
    <Field
      v-slot="{ field, errors }"
      :rules="rules"
      :name="name"
      :label="label.toLowerCase()"
      :model-value="inputValue"
    >
      <input
        v-bind="field"
        class="form-control"
        :name="name"
        :type="'date'"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="{ 'form-control--is-invalid': !!errors.length }"
        :max="max"
      />
    </Field>
    <ErrorMessage v-slot="{ message }" :name="name">
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>
<script>
import { ErrorMessage, Field } from "vee-validate";

export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    value: {
      type: null,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      innerValue: "",
    };
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    },
  },

  mounted() {
    if (this.inputValue !== null) {
      this.innerValue = this.inputValue;
    }
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-family: $font-family;
  font-size: $font-size-sm;
  margin-top: 5px;
}

input {
  border: 1px solid $color-primary;
  border-radius: 4px;
  padding: 15px 20px;
  display: block;

  width: 100%;
}
</style>

<template>
  <div class="home-slider__slide">
    <div
      class="home-slider__slide-image"
      :style="`background-image: url('${slide.image_original}')`"
    >
      <h3
        v-dompurify-html="slide.title"
        class="home-slider__slide-title"
        :class="{
          'home-slider--light': lightOrDark === 'light',
          'home-slider--dark': lightOrDark === 'dark',
        }"
      />
    </div>
    <div class="home-slider__slide-buttons">
      <SliderNewMobileYoutube
        v-if="slide.link2"
        :href="slide.link2"
        :label="slide.link_text2"
      />
      <a
        v-if="slide.link"
        :href="slide.link"
        class="home-slider__slide-primary"
      >
        {{ slide.link_text }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch } from "vue";
import SliderNewMobileYoutube from "./SliderNewMobileYoutube.vue";
import { useHeaderStore } from "@/store/modules/header.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  slide: Object,
});

const headerStore = useHeaderStore();
const { checkLightOrDark } = headerStore;
const { lightOrDark } = storeToRefs(headerStore);

watch(
  () => props.slide.image_original,
  async () => checkLightOrDark({ image: props.slide.image_original }),
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.home-slider {
  &__slide {
    font-family: $font-family;
    width: 100%;
    height: 652px;
    display: flex;
    flex-direction: column;
  }

  &__slide-buttons {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    width: 100%;
    height: $header-height;
  }

  &__slide-primary {
    color: $color-white;
    background-color: $color-primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-md;
    font-weight: 500;
    white-space: pre;
    overflow: hidden;
  }

  &__slide-image {
    flex-grow: 1;
    background-position: center right;
    background-size: cover;
    display: flex;
    align-items: flex-start;
  }

  &__slide-title {
    font-size: $font-size-xxxl;
    margin: 61px 49px 18px 49px;
    line-height: 52px;
    text-align: center;
    color: $color-primary;
    font-weight: 700;
    width: 100%;
  }

  &--light {
    color: $color-primary;
  }

  &--dark {
    color: $color-white;
  }
}
</style>

<template>
  <Loading v-if="loading" />
  <NotFound v-else />
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
import Loading from "@/components/Loading.vue";
import backService from "@/plugins/service";
import NotFound from "./NotFound.vue";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";

const router = useRouter();
const props = defineProps({
  id: { type: [String, Number], required: true },
});

const loading = ref(false);

watch(
  () => props.id,
  async (id) => {
    loading.value = true;
    try {
      const {
        data: { schedule },
      } = await backService.get(`/legacy-course/${id}`);
      await router.replace({
        name: ROUTE.Course.General,
        params: { id: schedule },
      });
    } catch (error) {
      console.error(error);
      loading.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-show="show" class="covid-alert">
    <div class="container">
      <div class="covid-alert__wrap">
        <div></div>
        <div v-dompurify-html="$t('covid_message', { to })" class="content" />
        <a href="#" @click="cancel">
          <img src="../assets/cancel/cancel.svg" alt="cancel" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { ROUTE } from "@/router/routenames";
import router from "@/router";

const covidWarning = ref(localStorage.getItem("covid_warning") === null);
const to = ref(router.resolve({ name: ROUTE.AppDownload }).href);

const show = computed(() => covidWarning.value);

const cancel = () => {
  localStorage.setItem("covid_warning", true);
  covidWarning.value = false;
};
onMounted(
  () => (covidWarning.value = localStorage.getItem("covid_warning") === null)
);
</script>

<style lang="scss">
.covid-alert {
  color: $color-blue;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 8px;
  text-align: center;
  background-color: #f3ac00;
  padding: 19px;
  @media screen and (max-width: 900px) {
    line-height: 20px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: $color-blue;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<template>
  <img
    v-if="image"
    class="avatar"
    :class="{
      avatar__large: size === 'large',
    }"
    :src="image"
    alt="avatar"
  />
  <span
    v-else
    class="initials"
    :class="{
      initials__blue: color === 'blue',
      initials__large: size === 'large',
    }"
    v-text="initials"
  />
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  image: { type: String, default: "" },
  account: { type: Object, required: true },
  color: { type: String, default: "grey" },
  size: { type: String, default: "default" },
});

const initials = computed(() =>
  getInitials(props.account.firstname, props.account.lastname),
);

const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";

  return firstInitial + lastInitial;
};
</script>

<style scoped lang="scss">
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 30px;

  &__large {
    width: 106px;
    height: 106px;
  }
}

.initials {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: $color-light-grey;
  border-radius: 100%;
  font-size: $font-size-sm;
  font-weight: 500;
  color: $color-primary;

  &__blue {
    background-color: $color-primary;
    color: $color-white;
  }

  &__large {
    width: 106px;
    height: 106px;
    font-size: $font-size-xxl;
  }
}
</style>

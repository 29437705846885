import { defineStore } from "pinia";
import { ref } from "vue";

export const useModalStore = defineStore("modal", () => {
  const modalEvents = {
    onOpen: () => {},
    onClose: () => {},
  };

  const visible = ref(false);
  const component = ref(null);
  const cssClass = ref("");

  const setComponent = (sfc) => {
    component.value = sfc;
  };

  const open = () => {
    visible.value = true;
    modalEvents.onOpen();
  };

  const close = () => {
    visible.value = false;
    component.value = null;
    modalEvents.onClose();
  };

  const create = (data) => {
    setComponent(data.component);

    cssClass.value = data.cssClass || "";

    modalEvents.onOpen = data.onOpen || (() => {});
    modalEvents.onClose = data.onClose || (() => {});
  };

  return {
    visible,
    component,
    cssClass,
    setComponent,
    open,
    close,
    create,
  };
});

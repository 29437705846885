<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :page-header="header"
    :show-navigation="true"
    :show-header-block="true"
    :title="$t('common.my_courses')"
  >
    <div>
      <div class="container">
        <CourseList
          :courses="purchasedStore.filtered"
          :title="!isMobileShop ? $t('common.my_courses') : ''"
          :route-name="ROUTE.Dashboard.Courses"
          :search-open="isMobileShop"
          @filter:courses="filterCourses"
        />
      </div>
    </div>
    <SecondDevice v-if="isMobile" class="second-device-wrapper" />
  </DashboardLayout>
</template>

<script setup>
import { getPurchasedStore } from "@/store/modules/purchased.store";
import CourseList from "@/components/CourseList.vue";
import { ROUTE } from "@/router/routenames";
import { usePageStore } from "@/store/modules/page.store";
import SecondDevice from "@/components/SecondDevice.vue";
import useIsMobile from "@/util/useIsMobile";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { isShoppingEnabled } from "@/util/helpers";
import i18n from "@/i18n";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { storeToRefs } from "pinia";
import { useGradesStore } from "@/store/modules/grades.store";

const purchasedStore = getPurchasedStore();
const pagesStore = usePageStore();
const gradesStore = useGradesStore();

const { getCourses } = purchasedStore;
const { courses } = storeToRefs(purchasedStore);
const { isMobile } = useIsMobile();
const { coursesGrades } = gradesStore;

const header = ref();

const isShopEnabled = computed(() => isShoppingEnabled());
const isMobileShop = computed(() => isMobile.value && isShopEnabled.value);

const filterCourses = (query = "") => getCourses(query);

onBeforeMount(() => filterCourses());
onMounted(() => coursesGrades(courses.value.map((course) => course.courseId)));

watch(
  () => courses.value.length,
  () => {
    coursesGrades(courses.value.map((course) => course.courseId));
  },
);
watch(
  () => i18n.global.locale,
  async () => {
    const { id } = await pagesStore.book("dashboard");
    header.value = await pagesStore.request(id);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.second-device-wrapper {
  padding-top: 50px;
  padding-bottom: 80px;
}
</style>

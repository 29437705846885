<template>
  <ul class="course-product__list">
    <li v-for="(product, index) in products" :key="product.product_id">
      <button
        class="course-product__item"
        :class="{
          'course-product__item--active': selected === product.product_id,
        }"
        type="button"
        @click="select(product)"
      >
        <span class="course-product__item-content">
          <span class="course-product__item-name">
            {{ product.location_name }}
          </span>
          <span v-if="product.city" class="course-product__item-address">
            {{ `${product.city}, ${product.location_address}` }}
          </span>
          <span v-else class="course-product__item-address">
            {{ product.location_address }}
          </span>
          <div class="price-block">
            <span v-if="product.special" class="course-product__item-special">
              {{ product.price_formated }}
            </span>
            <span class="course-product__item-price">
              <template v-if="product.special">{{
                product.special.price_formated
              }}</template>
              <template v-else>{{ product.price_formated }}</template>
            </span>
          </div>
        </span>
        <span class="course-product__item-start-label">
          {{ $t("course.start") }}
        </span>
        <span class="course-product__item-start-date">
          {{ getParsedDate(product.start_date) }}
        </span>
        <span class="course-product__item-end-label">
          {{ $t("course.end") }}
        </span>
        <span class="course-product__item-end-date">
          {{ getParsedDate(product.end_date) }}
        </span>
      </button>
      <hr v-if="index < products.length - 1" />
    </li>
  </ul>
</template>

<script setup>
import moment from "moment/moment";
import i18n from "@/i18n";

defineProps({
  products: Array,
  course: Object,
  selected: Number,
});
const emit = defineEmits(["select:product"]);

const getParsedDate = (date) => {
  return moment(date).locale(i18n.global.locale).format("LL");
};

const select = (product) => emit("select:product", product);
</script>

<style lang="scss" scoped>
.course-product {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      display: block;

      & > hr {
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid $color-grey-border;
        border-top: none;
      }
    }
  }

  &__item {
    display: grid;
    width: 100%;
    font-family: $font-family;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    padding: 20px;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "content content"
      "start-label end-label"
      "start-date end-date";

    &--active {
      background-color: $color-primary;
      color: $color-white;

      .course-product__item-content,
      .course-product__item-name,
      .course-product__item-address,
      .course-product__item-start-label,
      .course-product__item-start-date,
      .course-product__item-end-label,
      .course-product__item-price,
      .course-product__item-end-date {
        color: $color-white;
      }
    }

    &-content {
      grid-area: content;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "name special"
        "address price";
      align-items: center;
      margin-bottom: 4px;
      padding-bottom: 20px;
    }

    &-name {
      grid-area: name;
      font-weight: 500;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
    }

    &-special {
      grid-area: special;
      color: $color-light-coral;
      text-decoration: line-through;
      font-weight: 500;
      font-size: $font-size-md;
    }

    &-price {
      grid-area: price;
      font-size: $font-size-lg;
      color: $color-primary;
      font-weight: 500;
    }

    &-address {
      grid-area: address;
      color: $color-blue;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-start-label {
      grid-area: start-label;
      color: $color-grey;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-start-date {
      grid-area: start-date;
      color: $color-blue;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-end-label {
      grid-area: end-label;
      color: $color-grey;
      font-size: $font-size-md;
      font-weight: 400;
    }

    &-end-date {
      grid-area: end-date;
      color: $color-blue;
      font-size: $font-size-md;
      font-weight: 400;
    }
  }
}
.price-block {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>

import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";

export const COURSE_PURPOSE = "purpose";
export const COURSE_TASKS = "tasks";

export const getPurchasedStore = defineStore("purchased", () => {
  const loading = ref(false);
  const course = ref(null);
  const tests = ref(null);
  const surveys = ref(null);
  const courses = ref([]);
  const filtered = ref([]);

  const getCourses = async (search = "") => {
    loading.value = true;
    await backService
      .get("/courses/purchased", {
        params: { search },
      })
      .then((response) => {
        courses.value = response.data;
        filtered.value = response.data;
        loading.value = false;
      })
      .catch((error) => {
        console.error(error);
        loading.value = false;
      });
  };

  const setFilteredCourses = (completed) => {
    filtered.value = completed
      ? courses.value.filter(
          (course) => course.completed.total === course.completed.done,
        )
      : courses.value;
  };

  const request = async (id) => {
    try {
      loading.value = true;

      const { data } = await backService.get(`/courses/purchased/${id}`);

      loading.value = false;
      course.value = data;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const requestTests = async (id) => {
    try {
      loading.value = true;

      const { data } = await backService.get(`/courses/purchased/${id}/tests`);

      loading.value = false;
      tests.value = data;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const requestSurveys = async (id) => {
    try {
      loading.value = true;

      const { data } = await backService.get(
        `/courses/purchased/${id}/surveys`,
      );

      loading.value = false;
      surveys.value = data;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  return {
    loading,
    course,
    tests,
    surveys,
    courses,
    filtered,

    request,
    requestTests,
    requestSurveys,
    getCourses,
    setFilteredCourses,
  };
});

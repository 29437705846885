<template>
  <SettingsForm :initial-values="initialValues">
    <SettingsFormItem :title="$t('common.country')">
      <SelectInputField
        name="country"
        :placeholder="$t('common.country_placeholder')"
        :options="countriesStore.options"
        @input="(value) => setCountry(value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.preferred_language')">
      <SelectInputField
        name="language"
        :placeholder="$t('common.language_placeholder')"
        :options="languages"
        @input="(value) => setLanguage(value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.city')">
      <SelectInputField
        name="city"
        :placeholder="$t('common.city_placeholder')"
        :options="citiesStore.options"
        @input="(value) => setCity(value)"
      />
    </SettingsFormItem>
    <SettingsFormItem
      :title="$t('common.timezone')"
      :description="
        $t(
          'common.we_will_remind_you_about_the_courses_according_to_the_set_time_zone',
        )
      "
    >
      <SelectInputField
        name="timezone"
        :placeholder="$t('common.timezone_placeholder')"
        :options="timezonesStore.options"
        @input="(value) => setTimezone(value)"
      />
    </SettingsFormItem>
  </SettingsForm>
</template>

<script setup>
import { useCountriesStore } from "@/store/modules/countries.store";
import SelectInputField from "../SelectInputField.vue";
import { useTimezonesStore } from "@/store/modules/timezones.store";
import { useCitiesStore } from "@/store/modules/cities.store";
import { useLocaleStore } from "@/store/modules/locale.store";
import { computed, defineEmits, defineProps, watch } from "vue";
import SettingsFormItem from "@/components/form/dashboard/components/SettingsFormItem.vue";
import SettingsForm from "@/components/form/dashboard/components/SettingsForm.vue";
import i18n from "@/i18n";
import { useSettingsStore } from "@/store/modules/settings.store";

const countriesStore = useCountriesStore();
const timezonesStore = useTimezonesStore();
const citiesStore = useCitiesStore();
const languagesStore = useLocaleStore();
const settingsStore = useSettingsStore();

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

languagesStore.request();
countriesStore.request();
timezonesStore.request();
countriesStore.setCurrent(props.initialValues.country || null);

const languages = computed(() =>
  languagesStore.languages.map((language) => ({
    name: language.title_native,
    value: language.tag,
  })),
);

const setCountry = (value) => {
  if (value === props.initialValues["country"]) {
    return;
  }

  countriesStore.setCurrent(value);
  emit("submit", { country: value });
};
const setCity = (value) => {
  if (value === props.initialValues["city"]) {
    return;
  }

  emit("submit", { city: value });
};
const setTimezone = (value) => {
  if (value === props.initialValues["timezone"]) {
    return;
  }

  emit("submit", { timezone: value });
};
const setLanguage = (value) => {
  if (!value || props.initialValues.language === value) {
    return;
  }

  languagesStore.update(value);
};

watch(
  () => i18n.global.locale,
  () => settingsStore.request(),
);
watch(() => countriesStore.currentCountryId, citiesStore.request);
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="payment">
        <h1>{{ $t("payment.header_success") }}</h1>
        <div class="payment__center">
          <CustomButton :type="'grey-transparent'" @click="goHome()">
            <template #text> {{ $t("payment.go_home") }}</template>
          </CustomButton>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import CustomButton from "@/components/CustomButton.vue";
import Navigation from "@/components/Navigation.vue";
import { useRouter } from "vue-router";
import { useCartStore } from "@/store";
import { ROUTE } from "@/router/routenames";

export default {
  components: {
    Navigation,
    Footer,
    CustomButton,
  },
  setup() {
    const router = useRouter();
    const cartStore = useCartStore();
    return {
      router,
      cartStore,
    };
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    goHome() {
      this.router.push({
        name: ROUTE.Home,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.payment {
  margin-top: 50px;
  margin-bottom: 50px;

  &__center {
    margin-top: 20px;
    text-align: center;
  }

  h1 {
    text-align: center;
    color: $color-blue;
    font-family: $font-family;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
  }
}
</style>

import { defineStore } from "pinia";
import i18n from "@/i18n";

const SERVER_TIMEZONE = "99";

export const useTimezonesStore = defineStore({
  id: "timezones",
  state() {
    return {
      data: null,
    };
  },
  getters: {
    options() {
      if (!this.data) return [];

      const options = this.data.map((timezone) => ({
        name: timezone,
        value: timezone,
      }));

      options.push({
        name: i18n.global.t("common.server_timezone"),
        value: SERVER_TIMEZONE,
      });

      return options;
    },
  },
  actions: {
    async request() {
      this.data = Intl.supportedValuesOf("timeZone");

      return true;
    },
  },
});

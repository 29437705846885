<template>
  <div class="container">
    <CourseListHorizontal v-if="courses.length !== 0">
      <template #header>
        {{ $t("common.recommended_courses") }}
      </template>
      <template #body>
        <swiper
          :loop="true"
          :breakpoints="swiperFeaturedCoursesOptions.breakpoints"
          :slides-per-view="4"
          :space-between="50"
          @slideChange="onSlideChange"
        >
          <Loading v-if="loading" />
          <swiper-slide v-for="course in courses" :key="course">
            <CoursePopular :course="course" :width="'100%'" />
          </swiper-slide>
        </swiper>
      </template>
    </CourseListHorizontal>
  </div>
</template>
<script>
import CourseListHorizontal from "@/components/course/CourseListHorizontal.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import CoursePopular from "@/components/course/CoursePopular.vue";
import axios from "axios";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    CourseListHorizontal,
    Swiper,
    SwiperSlide,
    CoursePopular,
    Loading,
  },
  setup() {
    let vm = this;
    const onSwiper = (swiper) => {};

    const onSlideChange = (value) => {};

    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation],
    };
  },
  data() {
    return {
      courses: [],
      swiperFeaturedCoursesOptions: {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    };
  },
  created() {
    this.getRecommendedCourses();
  },
  methods: {
    async getRecommendedCourses() {
      let vm = this;
      this.loading = true;
      await axios
        .get("/featured")
        .then((response) => {
          if (response.data.success) {
            if (response.data.data[0].products) {
              vm.courses = response.data.data[0].products;
            }
            vm.loading = false;
          }
        })
        .catch((error) => {
          vm.loading = false;
        });
    },
  },
};
</script>

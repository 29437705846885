<template>
  <div class="loading" :class="`loading--${color}`">
    <div class="loading__wrapper" :class="{ loading__flex: flex }">
      <svg
        id="loader-1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        :width="size"
        :height="size"
        viewBox="0 0 50 50"
        style="enable-background: new 0 0 50 50"
        xml:space="preserve"
      >
        <path
          fill="#000"
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      <div v-if="text">{{ $t("common.loading") }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    flex: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      type: [String, Number],
      default() {
        return "40px";
      },
    },
    color: {
      type: String,
      default() {
        return "blue";
      },
    },
    text: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__flex {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &--blue {
    svg path,
    svg rect {
      fill: $color-primary;
    }
  }

  &--white {
    svg path,
    svg rect {
      fill: $color-white;
    }
  }

  &__wrapper {
    text-align: center;
  }
}
</style>

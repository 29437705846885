<template>
  <Field
    v-slot="{ componentField, errors }"
    :name="name"
    :rules="rules"
    :label="label || placeholder"
  >
    <TextInput
      ref="input"
      v-bind="componentField"
      :type="type"
      :errors="errors"
      :label="label"
      :placeholder="placeholder"
      @blur="$emit('blur')"
      @input="(value) => $emit('input', value)"
    />
  </Field>
</template>

<script setup>
import { Field } from "vee-validate";
import TextInput from "@/components/inputs/TextInput.vue";
import { defineExpose, defineProps, ref } from "vue";

const input = ref(null);

defineProps({
  name: String,
  type: {
    type: String,
    default: "text",
  },
  label: String,
  placeholder: String,
  rules: {
    type: [Object, String],
    default: "",
  },
});

const focus = () => input.value.focus();

defineExpose({ focus });
</script>

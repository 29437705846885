import { fixCopyright } from "@/util/filters";
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useSlideshowStore = defineStore("slideshow", () => {
  const loading = ref(false);
  const list = ref([]);

  async function request(type) {
    try {
      loading.value = true;
      const { data } = await axios.get("/slideshows", { params: { type } });

      loading.value = false;
      list.value = [...((data.data[0] || {}).banners || [])].map((x) => {
        return {
          ...x,
          title: fixCopyright(x.title),
        };
      });

      return true;
    } catch (error) {
      loading.value = false;
      console.error(error);

      return false;
    }
  }

  return {
    list,
    loading,

    request,
  };
});

<template>
  <accordion-item :padding="0">
    <template v-slot:accordion-trigger="{ isOpen }">
      <div class="grade-section__accordion-trigger">
        <div class="grade-section__accordion-trigger__category-content">
          <img
            class="grade-section__accordion-trigger__category-content__activity-image"
            alt="activity"
            src="../../assets/icons/activity-blue.svg"
          />
          <h3 v-text="category.name" />
        </div>
        <div class="grade-section__accordion-trigger__category-content">
          <Progress
            :done="category.gradedItems"
            :total="category.totalItems"
            :percent="gradedItemsPercent"
            darkLayout
          />
          <span
            class="grade-section__accordion-trigger__grade-text"
            v-text="category.grade"
          />
          <img :src="isOpen ? MinusBlueIcon : PlusBlueIcon" alt="svg" />
        </div>
      </div>
    </template>
    <template v-slot:accordion-content>
      <div class="grades-category">
        <accordion :padding="34">
          <grade-category
            v-for="children in category.children"
            :key="children.id"
            :category="children"
          />
        </accordion>
        <grade-items-table :items="category.items" :base-category="category" />
      </div>
    </template>
  </accordion-item>
</template>

<script setup>
import GradeItemsTable from "@/components/grade/GradeItemsTable.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import { computed } from "vue";
import Progress from "@/components/Progress.vue";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import Accordion from "@/components/accordion/Accordion.vue";

const props = defineProps({
  category: { type: Object },
});

const gradedItemsPercent = computed(
  () => (props.category.gradedCourses / props.category.totalCourses) * 100,
);
</script>

<style lang="scss" scoped>
.grade-section {
  &__accordion-trigger {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__category-content {
      display: flex;
      gap: 25px;
      align-items: center;

      &__activity-image {
        transform: unset;
      }
    }

    &__title-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    h3 {
      color: $color-blue;
      font-weight: 400;
      font-family: $font-family;
      font-size: $font-size-md;
    }

    &__grade-text {
      font-weight: 500;
      font-family: $font-family;
      font-size: $font-size-md;
      color: $color-blue;
      width: 50px;
    }
  }
}
</style>

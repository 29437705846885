import { defineStore } from "pinia";
import { ref } from "vue";
import short from "short-uuid";

const STORAGE_KEY_SESSION = "guest_session";

export const useGuestStore = defineStore("guestStore", () => {
  const translator = short();
  const session = ref(sessionStorage.getItem(STORAGE_KEY_SESSION));

  const persist = (value) => {
    sessionStorage.setItem(STORAGE_KEY_SESSION, value);
    session.value = value;
  };

  const reset = () => {
    persist(translator.new());
  };

  if (!session.value) {
    persist(translator.new());
  }

  return { session, persist, reset };
});

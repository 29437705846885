import { defineStore } from "pinia";
import { computed, ref } from "vue";
import lightOrDarkImageCheck from "@check-light-or-dark/image";
import lightOrDarkColorCheck from "@check-light-or-dark/color";

export const COLOR_LIGHT = "light";
export const COLOR_DARK = "dark";

export const useHeaderStore = defineStore("header", () => {
  const lightOrDark = ref(COLOR_LIGHT);

  const checkLightOrDark = async ({ image, color }) => {
    let isImageDark = false;
    let isColorDark = false;
    if (image) {
      isImageDark = (await lightOrDarkImageCheck({ image })) === COLOR_DARK;
    }
    if (color) {
      isColorDark = (await lightOrDarkColorCheck(color)) === COLOR_DARK;
    }
    lightOrDark.value = isImageDark || isColorDark ? COLOR_DARK : COLOR_LIGHT;
  };

  const reset = () => (lightOrDark.value = COLOR_LIGHT);

  return {
    lightOrDark: computed(() => lightOrDark.value),
    checkLightOrDark,
    reset,
  };
});

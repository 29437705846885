<template>
  <li class="navbar__item">
    <a href="#" :class="{ 'navbar__item--active': show }" @click="toggle">
      <slot />
    </a>
    <ul v-show="show">
      <li v-for="(item, index) in items" :key="index">
        <router-link :to="item.to">{{ item.label }}</router-link>
      </li>
    </ul>
  </li>
</template>

<script setup>
import { defineProps, ref } from "vue";

const show = ref(false);

const toggle = () => (show.value = !show.value);
defineProps({
  items: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="scss" scoped>
.navbar {
  &__item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-light-grey;

    &--active {
      font-weight: 500;
    }

    a {
      display: block;
      padding: 16px 0px 16px;

      color: $color-blue;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;

      text-decoration: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
</style>

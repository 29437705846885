<template>
  <div class="second-device">
    <a href="" @click.prevent="toggleQr">
      <img src="../assets/icons/qrcode-grey.svg" />
      {{ $t("common.connect_other_device") }}</a
    >
    <QRCode v-if="openModal" @close="toggleQr" />
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import QRCode from "@/components/modals/QRCode.vue";

const emit = defineEmits(["click"]);

const openModal = ref(false);

const toggleQr = () => {
  openModal.value = !openModal.value;
};
</script>

<style lang="scss">
.second-device {
  a {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: none;

    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}
</style>

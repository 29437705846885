<template>
  <div class="navbar__profile-block">
    <a
      :href="settingsStore.values.learning_system_url"
      class="navbar__profile-block-target"
      target="_blank"
    >
      <span v-text="settingsStore.values.learning_system_url_text" />
      <img src="@/assets/arrow-long-right-gray.svg" alt="arrow" />
    </a>
  </div>
</template>

<script setup>
import { useSettingsStore } from "@/store/modules/settings.store";

const settingsStore = useSettingsStore();
</script>

<style lang="scss" scoped>
.navbar {
  &__profile-block {
    &:first-child,
    &:last-child {
      padding: 26px 20px;
    }

    a {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;

      color: $color-menu-item;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 15px;

      text-decoration: none;
      width: 100%;
    }

    box-shadow: inset 0 -1px 0 0 $color-light-grey;
  }
}
</style>

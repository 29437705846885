<template>
  <div class="container">
    <img alt="No results" :src="image" />
    <div class="content">
      <p class="title" v-text="title" />
      <p v-if="description" class="description" v-text="description" />
      <div class="action">
        <custom-button
          v-if="backButtonRoute"
          type="default"
          size="md"
          @click="redirect"
        >
          <template #text>
            <div class="caption">
              <img src="@/assets/arrow_long_left_blue.svg" alt="Back arrow" />
              <span v-text="backButtonText" />
            </div>
          </template>
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import CustomButton from "@/components/CustomButton.vue";
import { useRouter } from "vue-router";

const props = defineProps({
  image: String,
  title: String,
  description: { type: String, default: "" },
  backButtonText: { type: String, default: "" },
  backButtonRoute: { type: String, default: "" },
});

const router = useRouter();

const redirect = () =>
  router.push({
    name: props.backButtonRoute,
  });
</script>

<style lang="scss" scoped>
.container {
  padding-top: 30px;
  text-align: center;
  background: radial-gradient(
    49.88% 49.88% at 50% 50.12%,
    rgba(23, 31, 105, 0.08) 0%,
    rgba(98, 113, 221, 0) 100%
  );

  .content {
    padding-top: 16px;

    .title {
      font-family: $font-family;
      font-weight: 500;
      font-size: $font-size-lg;
      color: $color-primary;
      margin-bottom: 26px;
    }

    .description {
      font-family: $font-family;
      font-weight: 400;
      font-size: $font-size-md;
      color: $color-primary;
    }

    .action {
      padding-top: 40px;

      :deep(.btn--md) {
        width: 100%;
        max-width: 315px;
        padding: 12px;
      }

      .caption {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: center;
      }
    }
  }
}
</style>

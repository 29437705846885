import Home from "../views/pages/Home.vue";
import DiscoverCourses from "../views/pages/DiscoverCourses.vue";
import FaqCategories from "../views/pages/FaqCategories.vue";
import FaqCategory from "../views/pages/FaqCategory.vue";
import Contacts from "../views/pages/Contacts.vue";
import CourseGeneral from "../views/pages/CourseGeneral.vue";
import CourseTheory from "../views/pages/CourseTheory.vue";
import CourseTests from "../views/pages/CourseTests.vue";
import Login from "../views/pages/Login.vue";
import Register from "../views/pages/Register.vue";
import Cart from "../views/pages/Cart.vue";
import CartPayment from "../views/pages/CartPayment.vue";
import PasswordReset from "../views/pages/PasswordReset.vue";
import PasswordResetSuccess from "../views/pages/PasswordResetSuccess.vue";
import ForgotPassword from "../views/pages/ForgotPassword.vue";
import Dashboard from "../views/pages/dashboard/Dashboard.vue";
import DashboardCourses from "../views/pages/dashboard/DashboardCourses.vue";
import DashboardPurchaseHistory from "../views/pages/dashboard/DashboardPurchaseHistory.vue";
import DashboardPurchaseHistoryShow from "../views/pages/dashboard/DashboardPurchaseHistoryShow.vue";
import DashboardSettings from "../views/pages/dashboard/DashboardSettings.vue";
import DashboardCertificates from "../views/pages/dashboard/DashboardCertificates.vue";
import DashboardCertificatesShow from "../views/pages/dashboard/DashboardCertificatesShow.vue";
import DashboardFavourites from "../views/pages/dashboard/DashboardFavourites.vue";
import About from "../views/pages/About.vue";
import HowItWorks from "../views/pages/HowItWorks.vue";
import CoursesForCompaniesAndInstitutions from "../views/pages/CoursesForCompaniesAndInstitutions.vue";
import Help from "../views/pages/Help.vue";
import HelpOffer from "@/views/pages/HelpOffer.vue";
import HelpContacts from "@/views/pages/HelpContacts.vue";
import Messages from "@/views/pages/Messages.vue";
import PaymentSuccess from "@/views/pages/payment/Success.vue";
import PaymentCancelled from "@/views/pages/payment/Cancelled.vue";
import Page from "@/views/pages/Page.vue";
import NotFound from "@/views/pages/NotFound.vue";
import { ROUTE } from "@/router/routenames";
import CourseSurveysVue from "@/views/pages/CourseSurveys.vue";
import CourseSubjectsVue from "@/views/pages/course/CourseSubjects.vue";
import CourseReviewsVue from "@/views/pages/course/CourseReviews.vue";
import CourseFAQVue from "@/views/pages/course/CourseFAQ.vue";
import Course from "@/views/pages/Course.vue";
import CourseActivity from "@/views/pages/CourseActivity.vue";
import CourseAbout from "@/views/pages/course/CourseAbout.vue";
import AppDownload from "@/views/pages/AppDownload.vue";
import LegacyCourse from "@/views/pages/LegacyCourse.vue";
import Grades from "@/views/pages/Grades.vue";

export default ({ guestGuard, authGuard }) => [
  {
    path: "/page/:id",
    name: "page",
    component: Page,
  },
  {
    path: "/payment/success",
    name: "payment.success",
    component: PaymentSuccess,
  },
  {
    path: "/payment/cancelled",
    name: "payment.cancelled",
    component: PaymentCancelled,
  },
  {
    path: "/",
    name: ROUTE.Home,
    component: Home,
  },
  {
    path: "/app-download",
    name: ROUTE.AppDownload,
    component: AppDownload,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/help",
    name: "help",
    component: Help,
  },
  {
    path: "/help/offer",
    name: "help.offer",
    component: HelpOffer,
  },
  {
    path: "/help/contacts",
    name: "help.contacts",
    component: HelpContacts,
  },
  {
    path: "/business/about",
    name: "about-business",
    component: About,
  },
  {
    path: "/business/business-and-institutions",
    name: "business-and-institutions",
    component: CoursesForCompaniesAndInstitutions,
  },
  {
    path: "/how-it-works",
    name: "howitworks",
    component: HowItWorks,
  },
  {
    path: "/course/:course/grades",
    name: "grades",
    component: Grades,
  },
  {
    path: "/discover",
    name: ROUTE.Discover,
    component: DiscoverCourses,
  },
  {
    path: "/course-about/:id/:backRoute?",
    name: ROUTE.Course.About,
    component: CourseAbout,
    props: true,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqCategories,
  },
  {
    path: "/faq/:category",
    name: "faq-category",
    component: FaqCategory,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
    meta: {
      requiresShopping: true,
    },
  },
  ...guestGuard([
    {
      path: "/login",
      name: ROUTE.Login,
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/forgot-password",
      name: ROUTE.Password.Forgot,
      component: ForgotPassword,
    },
    {
      path: "/password/reset/:token",
      name: ROUTE.Password.Reset,
      component: PasswordReset,
      props: true,
    },
    {
      path: "/password/success",
      name: ROUTE.Password.Success,
      component: PasswordResetSuccess,
    },
  ]),
  {
    path: "/course/:id/subjects",
    name: "course.subjects",
    component: CourseSubjectsVue,
  },
  {
    path: "/course/:id/reviews",
    name: "course.reviews",
    component: CourseReviewsVue,
  },
  {
    path: "/course/:id/faq",
    name: "course.faq",
    component: CourseFAQVue,
  },
  ...authGuard([
    {
      path: "/cart/payment",
      name: "cart.payment",
      component: CartPayment,
      meta: {
        requiresShopping: true,
      },
    },
    {
      path: "/course/:id",
      component: Course,
      name: "course",
      redirect: { name: "course.theory" },
      children: [
        {
          path: "theory/:subject?",
          name: ROUTE.Course.Theory,
          component: CourseTheory,
          meta: {
            scrollRemain: true,
          },
        },
        {
          path: "general",
          name: ROUTE.Course.General,
          component: CourseGeneral,
        },
        {
          path: "tests",
          name: "course.tests",
          component: CourseTests,
        },
        {
          path: "surveys",
          name: "course.surveys",
          component: CourseSurveysVue,
        },
        {
          path: "activity",
          name: ROUTE.Course.Activity,
          component: CourseActivity,
        },
      ],
    },
    {
      path: "/dashboard",
      name: ROUTE.Dashboard.Dashboard,
      component: Dashboard,
    },
    {
      path: "/dashboard/purchases",
      name: ROUTE.Dashboard.Purchases,
      component: DashboardPurchaseHistory,
      meta: {
        requiresShopping: true,
      },
    },
    {
      path: "/dashboard/purchases/:id/show",
      name: ROUTE.Dashboard.PurchasesShow,
      component: DashboardPurchaseHistoryShow,
      meta: {
        requiresShopping: true,
      },
    },
    {
      path: "/dashboard/courses",
      name: ROUTE.Dashboard.Courses,
      component: DashboardCourses,
    },
    {
      path: "/dashboard/settings",
      name: ROUTE.Dashboard.Settings,
      component: DashboardSettings,
      props: (route) => ({
        openChangePassword: route.query.openChangePassword === "true",
      }),
    },
    {
      path: "/dashboard/certificates",
      name: ROUTE.Dashboard.Certificates,
      component: DashboardCertificates,
      meta: {
        requiresShopping: true,
      },
    },
    {
      path: "/dashboard/certificates/:id/show",
      name: ROUTE.Dashboard.CertificatesShow,
      component: DashboardCertificatesShow,
      meta: {
        requiresShopping: true,
      },
    },
    {
      path: "/dashboard/favourites",
      name: ROUTE.Dashboard.Favourites,
      component: DashboardFavourites,
    },
    {
      path: "/messages",
      name: "messages",
      component: Messages,
    },
    {
      path: "/course/view.php",
      name: "legacy-course",
      component: LegacyCourse,
      props: (route) => ({ id: route.query.id }),
    },
  ]),
  {
    name: "projects.in-progress",
    path: "/projects/in-progress",
    component: Page,
    meta: { id: process.env.VUE_APP_PAGE_PROJECTS_IN_PROGRESS || 0 },
  },
  {
    name: "projects.done",
    path: "/projects/done",
    component: Page,
    meta: { id: process.env.VUE_APP_PAGE_PROJECTS_DONE || 0 },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

<template>
  <div>
    <div
      v-if="megaMenuEnable"
      class="bg-hidden"
      @click="$emit('click-outside')"
    />

    <div
      ref="navbar"
      class="navbar"
      :class="{
        'navbar--fixed': megaMenuEnable || isSticky,
        'navbar--static': isStatic,
        'navbar--dark': isDarkColor,
        'navbar--disabled-transition': !showTransition,
      }"
    >
      <div class="navbar__wrap container">
        <Search
          v-if="isSearchEnabled"
          :search-results="searchResults"
          :search-value="route.query.q"
          @disable:search="$emit('disable-search')"
          @search="$emit('search', $event)"
          @submit:search="$emit('submit-search', $event)"
          @show:suggestions="$emit('show-suggestions', $event)"
          @open:course="$emit('open-course', $event)"
        />
        <template v-else>
          <NavigationBranding :is-shopping-enabled="isShoppingEnabled" />
          <NavigationMenu
            :is-shopping-enabled="isShoppingEnabled"
            @toggle-mega-menu="$emit('toggle-mega-menu')"
          />
          <NavigationControls
            :is-shopping-enabled="isShoppingEnabled"
            :change-language="changeLanguage"
            :is-dark-color="isDarkColor"
            @toggle-search="$emit('toggle-search', $event)"
          />
        </template>
      </div>

      <div v-show="megaMenuEnable" class="megamenu" tabindex="0">
        <div class="megamenu__content">
          <div class="container">
            <div class="megamenu__wrap">
              <div class="megamenu__block">
                <h3>{{ $t("megamenu.private.title") }}</h3>

                <ul class="megamenu__filters">
                  <li>
                    <router-link :to="{ name: ROUTE.Discover }">
                      {{ $t("megamenu.private.links.0.name") }}
                    </router-link>
                  </li>
                </ul>
              </div>

              <div class="megamenu__block">
                <h3>{{ $t("megamenu.business.title") }}</h3>

                <ul>
                  <li>
                    <router-link :to="{ name: 'business-and-institutions' }">
                      {{ $t("megamenu.business.links.0.name") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'about-business' }">
                      {{ $t("megamenu.business.links.1.name") }}
                    </router-link>
                  </li>
                </ul>
              </div>

              <div v-if="discountStore.discountBanner" class="megamenu__block">
                <div class="megamenu__banner">
                  <div class="megamenu__banner__left-section">
                    <img
                      :src="discountStore.discountBanner.image"
                      alt="Discount"
                    />
                  </div>
                  <div
                    class="megamenu__banner__right-section"
                    :style="{
                      backgroundColor: discountStore.discountBanner.color,
                    }"
                  >
                    <h1 v-text="discountStore.discountBanner.discount + '%'" />
                    <p v-text="discountStore.discountBanner.title" />
                    <div class="megamenu__banner__right-section__divider" />
                    <p
                      class="megamenu__banner__right-section__description"
                      v-text="discountStore.discountBanner.description"
                    />
                    <a
                      :href="discountStore.discountBanner.link"
                      :style="{
                        backgroundColor: discountStore.discountBanner.color,
                      }"
                    >
                      {{ discountStore.discountBanner.link_text }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ROUTE } from "@/router/routenames";
import Search from "@/components/Search.vue";
import { useStores } from "@/store/use.store";
import { ref } from "vue";
import NavigationBranding from "@/components/NavigationBranding.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import NavigationControls from "@/components/NavigationControls.vue";
import { useRoute } from "vue-router";

const props = defineProps({
  isSticky: { type: Boolean, required: true },
  isStatic: { type: Boolean, required: true },
  isDarkColor: { type: Boolean, required: true },
  showTransition: { type: Boolean, required: true },
  megaMenuEnable: { type: Boolean, required: true },
  isSearchEnabled: { type: Boolean, required: true },
  isShoppingEnabled: { type: Boolean, required: true },
  searchResults: { type: Array, default: () => [] },
  searchValue: { type: String, default: "" },
  changeLanguage: { type: Function, required: true },
});

const emit = defineEmits([
  "click-outside",
  "disable-search",
  "search",
  "submit-search",
  "show-suggestions",
  "open-course",
  "toggle-mega-menu",
  "toggle-search",
]);

const { discountStore } = useStores();
const route = useRoute();

const navbar = ref(null);

defineExpose({
  navbar,
});
</script>

<style scoped lang="scss">
.bg-hidden {
  background-color: rgba(27, 33, 74, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.navbar {
  transition: background-color 0.75s ease-in-out;
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 11;

  @media screen and (max-width: $breakpoint-xl) {
    display: none;
  }

  &--static {
    position: static;
  }

  &--fixed {
    position: fixed;
    top: 0;
    background-color: white;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: $header-height;
  }

  &--dark {
    background-color: transparent;

    .navbar__link,
    :deep() .navbar__link {
      color: $color-white;
      opacity: 0.6;

      &:hover,
      &--active {
        opacity: 1;
      }

      &:not(.navbar__link--round).dropdown-toggle:after {
        background: url("../assets/menu/arrow-down-white.svg");
      }
    }
  }

  &--disabled-transition {
    transition: none;
  }
}

.megamenu {
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: 20;

  &__content {
    background-color: $color-white;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
  }

  &__block {
    width: 285px;
    margin-bottom: 20px;

    h3 {
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      line-height: 15px;
      color: $color-primary;
      margin-bottom: 19px;
      margin-top: 16px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li a {
        font-family: $font-family;
        font-size: $font-size-md;
        line-height: 28px;
        text-decoration: none;
        color: $color-primary;
      }
    }
  }

  &__filters {
    &--count {
      opacity: 0.5;
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      line-height: 23px;
    }

    &--active {
      a {
        font-weight: 500;
      }

      ul {
        display: block !important;

        a {
          font-weight: 500;
        }
      }
    }

    li {
      ul {
        display: none;

        li {
          padding-left: 20px !important;
        }

        a {
          color: $color-primary;
          font-family: $font-family;
          font-size: $font-size-sm;
          line-height: 16px;
        }
      }
    }
  }

  &__banner {
    display: flex;
    width: 100%;
    min-width: 525px;
    max-height: 360px;
    margin: 10px auto 15px;

    &__left-section img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__right-section {
      background-color: var(--banner-background-color, $color-primary);
      color: $color-white;
      padding: 0 42px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60%;

      h1 {
        font-family: $font-family;
        font-size: 42px;
        font-weight: 700;
        line-height: 15px;
        margin-bottom: 16px;
        margin-top: 60px;
      }

      p {
        font-family: $font-family;
        font-size: 15px;
        line-height: 22px;
      }

      &__divider {
        width: 30px;
        height: 4px;
        margin: 22px 0;
        background-color: $color-white;
        border-radius: 2px;
      }

      &__description {
        margin-bottom: 45px;
      }

      a {
        font-family: $font-family;
        font-size: 15px;
        font-weight: 500;
        line-height: 15px;
        text-decoration: none;
        cursor: pointer;
        background-color: var(--banner-background-color, $color-primary);
        border-radius: 8px;
        color: $color-white;
        border: 2px solid white;
        padding: 10px 20px;
        min-width: 200px;
        margin-top: auto;
        margin-bottom: 82px;
        display: inline-block;
      }
    }
  }
}
</style>

import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useFavouriteCourses = defineStore("favouriteCourses", () => {
  const loading = ref(false);
  const favouriteCourseResources = ref([]);
  const favouriteList = ref([]);

  const favouriteCourses = computed(() => favouriteCourseResources.value);
  const list = computed(() =>
    favouriteList.value ? favouriteList.value.data : [],
  );

  const page = computed(() =>
    favouriteList.value ? favouriteList.value.current_page : 1,
  );
  const pageCount = computed(() =>
    favouriteList.value ? favouriteList.value.last_page : 1,
  );
  const total = computed(() =>
    favouriteList.value ? favouriteList.value.total : 0,
  );
  const from = computed(() =>
    favouriteList.value ? favouriteList.value.from : 0,
  );
  const to = computed(() => (favouriteList.value ? favouriteList.value.to : 0));

  const request = async () => {
    try {
      loading.value = true;
      const { data } = await backService.get("favourite-courses");

      loading.value = false;
      favouriteCourseResources.value = data;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const requestCourseList = async (page = 1, limit = 12) => {
    try {
      loading.value = true;
      const { data } = await backService.get("products", {
        params: { favourite: 1, page, limit },
      });

      loading.value = false;
      favouriteList.value = data;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const store = async (course) => {
    try {
      loading.value = true;
      const { data } = await backService.post("favourite-courses", {
        course: course,
      });

      favouriteCourseResources.value.push(data.data);

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const destroy = async (id) => {
    try {
      loading.value = true;
      await backService.delete(`favourite-courses/${id}`);

      loading.value = false;

      favouriteCourseResources.value = favouriteCourseResources.value.filter(
        (favouriteCourses) => favouriteCourses.id !== id,
      );

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return {
    favouriteCourses,
    list,
    loading,
    page,
    pageCount,
    total,
    from,
    to,
    request,
    store,
    destroy,
    requestCourseList,
  };
});

<template>
  <SettingsForm :initial-values="initialValues">
    <SettingsFormItem :title="$t('common.first_name')" required>
      <TextInputField
        v-model="form.firstname"
        name="firstname"
        rules="required"
        :placeholder="$t('common.your_firstname')"
        @input="(event) => setValue('firstname', event.target.value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.last_name')" required>
      <TextInputField
        v-model="form.lastname"
        name="lastname"
        rules="required"
        :placeholder="$t('common.your_lastname')"
        @input="(event) => setValue('lastname', event.target.value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.email')" required>
      <TextInputField
        v-model="form.email"
        name="email"
        rules="required"
        :placeholder="$t('common.your_email')"
        @input="(event) => setValue('email', event.target.value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.phone_number')" required>
      <TextInputField
        v-model="form.telephone"
        name="telephone"
        type="tel"
        placeholder="+370 (000) 00 000"
        @input="(event) => setTelNumber(event.target.value)"
      />
    </SettingsFormItem>
  </SettingsForm>
</template>

<script setup>
import TextInputField from "../TextInputField.vue";
import { defineEmits, defineProps, ref } from "vue";
import SettingsForm from "@/components/form/dashboard/components/SettingsForm.vue";
import SettingsFormItem from "@/components/form/dashboard/components/SettingsFormItem.vue";
import debounce from "lodash.debounce";

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

const form = ref({
  email: props.initialValues.email,
  firstname: props.initialValues.firstname,
  lastname: props.initialValues.lastname,
  telephone: props.initialValues.telephone,
});

const setValue = debounce((name, value) => {
  if (!value || value === props.initialValues[name]) {
    return;
  }

  if (name === "telephone" && !/^\+[0-9]{3}[0-9]{7,12}$/.test(value)) {
    return;
  }

  emit("submit", { [name]: value });
}, 1000);
const setTelNumber = (value) => {
  form.value.telephone = value.replace(/[^0-9+]/g, "");
  setValue("telephone", form.value.telephone);
};
</script>

export const ROUTE = {
  Home: "home",
  Discover: "discover",
  Password: {
    Forgot: "password",
    Reset: "password.reset",
    Success: "password.success",
  },
  Login: "login",
  Dashboard: {
    Dashboard: "dashboard",
    Purchases: "dashboard.purchases",
    PurchasesShow: "dashboard.purchases.show",
    Courses: "dashboard.courses",
    Settings: "dashboard.settings",
    Certificates: "dashboard.certificates",
    CertificatesShow: "dashboard.certificates.show",
    Favourites: "dashboard.favourites",
  },
  Course: {
    General: "course.general",
    About: "course-about",
    Course: "course",
    Activity: "course.activity",
    Theory: "course.theory",
  },
  AppDownload: "app-download",
};

<template>
  <div>
    <SimpleNavigation />
    <div class="auth-container">
      <div class="password-reset">
        <h2>{{ $t("navigation.forgot_password") }}</h2>
        <div class="password-reset__wrap">
          <div class="password-reset__block">
            <Form @submit="submit">
              <TextField
                name="username"
                rules="required"
                :label="$t('common.username')"
                :placeholder="$t('common.username')"
              />

              <div class="password-reset__center">
                <CustomButton
                  :type="'dark'"
                  :disabled="isLoading"
                  :full-width="true"
                >
                  <template #text>
                    {{ $t("common.remind_password") }}
                  </template>
                </CustomButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SimpleNavigation from "@/components/SimpleNavigation.vue";
import TextField from "@/components/form/TextField.vue";
import CustomButton from "@/components/CustomButton.vue";
import axios from "axios";
import { useAlertStore } from "@/store";
import { Form } from "vee-validate";
import { ref } from "vue";

const alertStore = useAlertStore();
const isLoading = ref(false);

const submit = async (values, actions) => {
  isLoading.value = true;

  await axios
    .post(process.env.VUE_APP_SERVICE_URL + "/auth/password/forgotten", {
      ...values,
    })
    .then((response) => {
      isLoading.value = false;
      alertStore.success(
        "Success",
        "Jūsų slaptažodžio priminimo užklausa išsiusta į el. paštą."
      );
    })
    .catch((error) => {
      isLoading.value = false;
      alertStore.error("Klaida!", "Something went wrong");
    });
};
</script>
<style lang="scss" scoped>
.auth-container {
  max-width: 399px;
  margin: 0 auto;
}

.password-reset {
  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__socials {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      text-align: center;

      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      text-align: center;

      a {
        color: $color-blue;
      }
    }

    &--divider {
      padding: 40px 0px;

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdee9;
        line-height: 1px;
        margin: 10px 0 20px;

        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        background-color: white;
        padding: 0 10px;
      }
    }
  }

  &__facebook {
    position: relative;
    border-radius: 4px;
    background-color: #1360c2;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }

  &__google {
    position: relative;
    border-radius: 4px;
    background-color: #c7392d;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;

    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }
}
</style>

import { configure, defineRule } from "vee-validate";
import * as rules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";

import i18n from "@/i18n";

import lt from "@vee-validate/i18n/dist/locale/lt.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import ru from "@vee-validate/i18n/dist/locale/ru.json";
import { watch } from "vue";
import PhoneNumber from "awesome-phonenumber";
import { now } from "@/util/helpers";

Object.keys(rules).forEach((rule) => {
  if (rule != "default" && typeof rules[rule] === "function") {
    defineRule(rule, rules[rule]);
  }
});

localize("lt", {
  fields: {
    email: {
      email: "Jūsų el. pašto adresas yra neteisingas.",
    },
    agree_cookies: {
      required:
        "Prašome sutikti, kad jūsų duomenys galėtų būti naudojamos tiesioginės rinkodaros tikslais.",
    },
    agree_terms: {
      required: "Prašome sutinkti su taisyklėmis ir privatumo politika.",
    },
    discount_code: {
      required: "Laukelis Nuolaidos kodas privalomas",
    },
    phone: {
      required: "Telefono numeris yra privalomas",
      hlphone: "Neteisingai įvestas telefono numeris",
    },
  },
  messages: {
    hlagree: "Prašome sutikti su sąlygomis",
  },
});

localize("en", {
  fields: {
    discount_code: {
      required: "Discount code field is required",
    },
    phone: {
      required: "Phone field is required",
      hlphone: "Phone field is invalid",
    },
  },
  messages: {
    hlagree: "Please accept the terms and conditions",
  },
});

localize("ru", {
  fields: {
    discount_code: {
      required: "Поле Код скидки обязателен",
    },
    phone: {
      required: "Номер телефона обязателен для ввода",
      hlphone: "Поле должно содержать правильный номер телефона",
    },
  },
  messages: {
    hlagree: "Пожалуйста, примите условия использования",
  },
});

configure({
  generateMessage: localize({
    lt,
    en,
    ru,
  }),
});

setLocale("lt");

watch(
  () => i18n.global.locale,
  () => {
    setLocale(i18n.global.locale);
  },
);

defineRule("hlphone", (value) => {
  const phone = new PhoneNumber(value);
  return !!phone.isValid();
});

defineRule("hlbirthdate", (value) => {
  return new Date(now()) > new Date(value);
});

defineRule("hlagree", (value) => !!value);

<template>
  <div class="profile-image">
    <div v-if="users.length > 1" class="profile-image__multiple">
      <img
        v-for="user in users.slice(0, 2)"
        :key="user"
        src="https://via.placeholder.com/24"
        alt="user"
      />
    </div>

    <div v-if="users.length == 1" class="profile-image__single">
      <img src="https://via.placeholder.com/44" alt="user" />
      <div
        v-if="users.length == 1"
        class="profile-image__status"
        :class="{
          'profile-image__status--active': users[0].status == 'online',
          'profile-image__status--unactive': users[0].status == 'offline',
        }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => {
        return [
          {
            name: "Suhaib Al Minhas",
            status: "online",
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.profile-image {
  width: 40px;
  &__single {
    position: relative;
  }

  &__multiple {
    position: relative;
    height: 100%;

    img {
      border: 1.5px solid #fbfbfc;
      &:first-child {
        position: absolute;
        right: 0px;
        top: 0px;
      }

      &:last-child {
        position: absolute;
        left: 0px;
        bottom: 0px;
      }
    }
  }

  img {
    border-radius: 30px;
  }

  &__status {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 14px;
    width: 14px;
    border: 1.5px solid #ffffff;
    background-color: #636363;
    border-radius: 30px;

    &--active {
      background-color: $color-green;
    }

    &--unactive {
      background-color: red;
    }
  }
}
</style>

<template>
  <a
    :href="youtubeHref"
    class="home-slider__youtube-toggle"
    @click.prevent="open"
  >
    <span class="home-slider__youtube-toggle-background">
      <span class="home-slider__youtube-toggle-background-play"></span>
    </span>
    <span v-dompurify-html="label" class="home-slider__youtube-toggle-title" />
  </a>
  <Teleport to="body">
    <Modal
      v-if="isOpen"
      :name="'video_modal'"
      :dark="true"
      :width="600"
      :toggle="true"
      @close="close"
    >
      <template #body>
        <div class="video">
          <iframe
            width="100%"
            height="315"
            :src="href"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div class="home-slider__youtube">
          {{ description }}
        </div>
      </template>
    </Modal>
  </Teleport>
</template>
<script setup>
import Modal from "@/components/Modal.vue";
import { computed, defineProps, ref } from "vue";

const props = defineProps({
  href: String,
  label: String,
});

const isOpen = ref(false);
const youtubeHref = computed(() => {
  const id = props.href.split("/").at(-1).split("?")[0];
  return `https://www.youtube.com/watch?v=${id}`;
});

function open() {
  isOpen.value = true;
}
function close() {
  isOpen.value = false;
}
</script>

<style lang="scss" scoped>
.home-slider__youtube {
  &-toggle {
    min-width: 150px;
    color: $color-primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $font-size-md;
    font-weight: 500;
    white-space: pre;
    overflow: hidden;
    border: 0;
    position: relative;
    background: $color-white;
    padding: 29px 70px;

    &-title {
      margin-left: 25%;
    }

    &-background {
      position: absolute;
      left: 0;
      top: 0;
      width: 25%;
      height: 100%;
      background: url("@/assets/video-button-background.png") no-repeat center
        center;
      background-size: cover;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(27, 33, 74, 0.6);
      }

      &-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 70%;
        background: url("@/assets/video-play.svg") no-repeat center center;
        background-size: contain;
        z-index: 1;
      }
    }
  }

  &-description {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 26px;
    padding: 20px;
  }
}
</style>

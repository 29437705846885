<template>
  <div class="cart__block">
    <img :src="course.thumb" />
    <div class="cart__content">
      <div class="cart__content-description">
        <div class="cart__content-description-title">{{ course.name }}</div>
        <div class="cart__content-description-subtitle">
          {{ limit(course.description) }}
        </div>
      </div>
      <div class="cart__content-price">
        <template v-if="course.price !== course.hl_original_price">
          <div
            class="cart__content-price-old"
            v-text="course.hl_original_price"
          />
          <div class="cart__content-price-new" v-text="course.price" />
        </template>
        <template v-else>
          {{ course.price }}
        </template>
      </div>
      <a
        href="#"
        class="cart__content-delete"
        @click.prevent="remove(course.key)"
      >
        <img src="../../assets/close_cart.svg" />
      </a>
    </div>
  </div>
</template>

<script>
import { useCartStore } from "@/store";

export default {
  props: {
    course: {},
  },
  setup() {
    const cartStore = useCartStore();
    return {
      cartStore,
    };
  },
  methods: {
    limit(text) {
      return text ? text.slice(0, 60) + "..." : "";
    },
    remove(key) {
      this.cartStore.remove(key);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  &__block {
    width: 100%;
    display: flex;
    border: 1px solid #dcdee9;

    img {
      max-width: 120px;
      object-fit: cover;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
  }

  &__content-description-title {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
  }

  &__content-description-subtitle {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__content-price {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    display: flex;
    flex-direction: column;

    &-old {
      color: $color-red;
      text-decoration: line-through;
    }
  }
}
</style>

<template>
  <li class="menu-mobile__dropdown">
    <button type="button" class="menu-mobile__dropdown-toggle" @click="toggle">
      {{ label }}
    </button>
    <ul v-show="isOpen" class="menu-mobile__dropdown-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="menu-mobile__dropdown-item"
      >
        <RouterLink :to="item.to">{{ item.label }}</RouterLink>
      </li>
    </ul>
  </li>
</template>

<script setup>
import { defineProps, ref } from "vue";

defineProps({
  label: String,
  items: Array,
});

const isOpen = ref(false);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<style lang="scss" scoped>
.menu-mobile__dropdown {
  display: block;
  font-family: $font-family;
  &-toggle {
    border: none;
    background: transparent;
    margin: 0;
    padding: 20px 30px;
    color: $color-menu-item;
    font-size: $font-size-lg;
    font-weight: 700;
    display: block;
    width: 100%;
    text-align: left;
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &-item > a {
    color: $color-menu-item;
    text-decoration: none;
    font-size: $font-size-lg;
    font-weight: 500;
    padding: 20px 30px;
    padding-left: 50px;
    display: block;
  }
}
</style>

<template>
  <div>
    <Navigation />
    <HelpHeader />

    <div class="contacts">
      <div class="container">
        <div class="contacts__wrap">
          <div class="contacts__block">
            <div class="contacts__header">{{ $t("common.contacts") }}</div>

            <div v-if="!toggle" class="contacts__content">
              <div class="contacts__content-email">info@myhybridlab.com</div>
              <div class="contacts__content-phone">+370 676 22222</div>
            </div>
          </div>
          <div class="contacts__block">
            <img
              class="contacts__image"
              src="../../assets/hybridlab.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import HelpHeader from "@/components/HelpHeader.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navigation,
    HelpHeader,
    Footer,
  },
  data() {
    return {
      toggle: false,
    };
  },
  methods: {
    toggleInformation() {
      this.toggle = !this.toggle;
    },
  },
};
</script>
<style lang="scss" scoped>
.contacts {
  &__toggle {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: $font-family;
    font-size: $font-size-sm;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;

    &--icon {
      background-color: transparentize($color: $color-primary, $amount: 0.9);
      border-radius: 30px;
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px !important;
        height: 10px !important;
      }
    }
  }

  &__image {
    height: 271px;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__block {
    flex-basis: 100%;

    img {
      width: 100%;
    }
  }

  &__header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $help-header-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;

    margin-bottom: 20px;
  }

  &__content {
    color: rgba(27, 33, 74, 0.5);
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;

    div {
      margin-bottom: 7px;
    }
  }

  &__content-email {
  }

  &__content-phone {
    span {
      color: $color-blue;
    }
  }

  &__content-address {
    color: $color-blue;
    margin-top: 13px;
  }
}
</style>

<template>
  <div class="contact-us">
    <hr class="contact-us__white-line" />
    <div class="container">
      <h3>{{ $t("home.contactus.header") }}</h3>
      <p>{{ $t("home.contactus.content") }}</p>
      <Form @submit="create">
        <div class="contact-us__form-group">
          <TextInputField
            rules="required"
            name="phone"
            :placeholder="$t('home.contactus.phone_number')"
          />
          <button class="contact-us__submit" type="submit" name="">
            {{ $t("home.contactus.call") }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import backService from "@/plugins/service.js";
import { useAlertStore } from "@/store";
import TextInputField from "./form/TextInputField.vue";

export default {
  components: {
    Form,
    TextInputField,
  },
  setup() {
    const alertstore = useAlertStore();

    return {
      alertstore,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    async create(values, actions) {
      // /contact/by-phone
      let vm = this;
      backService
        .post("/contact/by-phone", {
          ...values,
        })
        .then(() => {
          vm.alertstore.success("Pavyko!", "Sėkmingai išsiųsta.");
          actions.resetForm();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-us {
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: auto;
  flex-direction: column;

  &__white-line {
    display: block;
    margin-top: 80px;
    margin-bottom: 40px;
    width: 30px;
    border: 2px solid $color-white;
    border-radius: 2px;
  }

  h3 {
    color: #ffffff;
    font-family: $font-family;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    margin-top: 0px;
    font-size: $font-size-lg;
    line-height: 32px;
    margin-bottom: 16px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 32px;
    }
  }

  .container {
    padding: 0 30px;
    max-width: 700px;
  }

  & > .container > p {
    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-top: 5px;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: $font-size-md;
      font-weight: 400;
      margin-bottom: 80px;
    }
  }

  &__form-group {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    align-items: start;
    gap: 26px;
    margin-top: 40px;
    margin-bottom: 80px;

    @media screen and (max-width: $breakpoint-lg) {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      width: 100%;
      align-items: stretch;
      gap: 16px;
    }

    :deep(.form-group) {
      margin: 0;
    }

    :deep(input) {
      border: 1px solid #dcdee9;
      border-radius: 4px;
      background-color: $color-primary;
      color: #ffffff;
      padding: 0;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 21px;
      width: 100%;
      height: 50px !important;
      min-height: 50px;
      margin-top: 0;
      text-align: center;

      &::placeholder {
        color: #cbd5e1;
      }

      @media screen and (max-width: $breakpoint-lg) {
        max-width: inherit;
        font-size: $font-size-md;
        font-weight: 500;
      }
    }

    button {
      padding: 14px 26px;
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #ffffff;
      font-size: $font-size-md;
      color: $color-blue;
      font-family: $font-family;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      width: 100%;
      height: 50px;

      @media screen and (max-width: $breakpoint-lg) {
        font-size: $font-size-md;
      }
    }
  }
}
</style>

<template>
  <div class="form-group">
    <label v-if="label" :for="name" @click="$refs.input.focus()"
      >{{ label }}
      <slot name="label-link"></slot>
    </label>
    <Field
      v-slot="{ field, errors }"
      :rules="rules"
      :name="name"
      :label="(label || placeholder).toLowerCase()"
      :model-value="inputValue"
    >
      <input
        v-bind="field"
        ref="input"
        class="form-control"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :max="maxLimit"
        :min="minLimit"
        :disabled="disabled"
        :class="{ 'form-control--is-invalid': !!errors.length }"
      />
    </Field>
    <ErrorMessage v-slot="{ message }" :name="name">
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>
<script>
import { ErrorMessage, Field } from "vee-validate";

/** @deprecated */
export default {
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: [String, Number],
      default: "",
    },
    rows: Number,
    maxLength: Number,
    maxLimit: Number,
    minLimit: Number,
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    type: {
      type: String,
      default: "text",
      validator: (value) =>
        [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email",
          "submit",
        ].includes(value),
    },
    value: {
      type: null,
      default: "",
    },
  },
  data() {
    return {
      innerValue: "",
    };
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    },
  },

  mounted() {
    if (this.inputValue !== null) {
      this.innerValue = this.inputValue;
    }
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-family: $font-family;
  font-size: $font-size-sm;
  font-size: 13px;
  margin-top: 5px;
}

input {
  padding: 15px 20px;
  display: block;

  width: 100%;

  background-color: $color-light-grey;
  border: 1px solid transparent;
  border-radius: 8px;
  outline: none;

  &:focus {
    background-color: #fff;
    border: 1px solid $color-menu-item;
  }
}
</style>

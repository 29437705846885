<template>
  <div v-if="isShoppingEnabled()" class="courses-by-category">
    <div class="container">
      <h3>{{ $t("home.courses-by-category.header") }}</h3>
      <div class="courses-by-category__wrap">
        <a
          v-for="(category, index) in categories"
          :key="category.id"
          href="#"
          class="courses-by-category__block"
          @click.prevent="enterCategory(category.category_id)"
        >
          <img :src="category.original_image" alt="" />
          <div class="courses-by-category__content">
            <div class="courses-by-category__content-text">
              <span class="counter" v-text="indexNumber(index + 1)" />
              <h4>{{ category.name }}</h4>
              <img
                class="courses-by-category__content-text-arrow"
                src="@/assets/arrow-long-right-white.svg"
                alt="arrow"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useGlobalStore } from "@/store";
import axios from "axios";
import { useRouter } from "vue-router";
import { isShoppingEnabled } from "@/util/helpers";

export default {
  setup() {
    const globalStore = useGlobalStore();
    const router = useRouter();
    return {
      router,
      globalStore,
    };
  },
  data() {
    return {
      categories: [],
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.getCategories();
    },
  },
  async created() {
    await this.getCategories();
  },
  methods: {
    isShoppingEnabled,
    enterCategory(id) {
      this.router.push({
        name: "discover",
        query: {
          category_id: id,
        },
      });
    },
    getImage(key) {
      key++;

      return require("../../assets/categories/" + key + ".png");
    },
    async getCategories() {
      let vm = this;
      await axios.get("/categories/parent/59").then((response) => {
        vm.categories = response.data.data;
      });
    },
    indexNumber(index) {
      return index < 10 ? `0${index}` : index;
    },
  },
};
</script>

<style lang="scss">
.courses-by-category {
  .container {
    max-width: unset;
    padding: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    border-top: none;
  }

  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $header-h3-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 40px;
    padding-top: 49px;
    text-align: center;

    @media screen and (max-width: $breakpoint-lg) {
      padding-top: 0;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0;
    margin-top: 20px;
    @media screen and (max-width: $breakpoint-lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 9px;
      padding: 0;
    }
  }

  &__block {
    height: 560px;
    width: 100%;
    text-decoration: none;
    position: relative;
    display: block;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;

    @media screen and (max-width: $breakpoint-lg) {
      height: 280px;
    }

    img:not(.courses-by-category__content-text-arrow) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: -23px;
      position: absolute;
    }

    &:first-child:hover,
    &:last-child:hover,
    &:hover:not(:first-child):not(:last-child) {
      z-index: 1;
      width: 170%;

      .courses-by-category__content-text-arrow {
        height: 24px;
        visibility: visible;
        margin-top: 40px;
      }
    }

    &:first-child:hover {
      margin-left: 0;
    }

    &:last-child:hover {
      margin-left: -70%;
    }

    &:hover:not(:first-child):not(:last-child) {
      margin-left: -35%;
    }
  }

  &__content-text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 80px;
    align-items: center;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 80px;
    }
  }

  &__content {
    position: absolute;
    align-content: center;
    bottom: 23px;
    width: 100%;
    background: rgba(21, 31, 109, 0.3)
      radial-gradient(
        circle,
        rgba(21, 31, 109, 0.8) 0%,
        rgba(21, 31, 109, 0.2) 100%
      );
    height: 100%;

    h4 {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
    }

    .counter {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-xxl;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }

    &-text-arrow {
      visibility: hidden;

      @media screen and (max-width: $breakpoint-lg) {
        visibility: visible;
        position: absolute;
        bottom: 40px;
      }
    }
  }
}
</style>

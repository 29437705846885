<template>
  <div class="message-header">
    <div class="container">
      <div class="message-header__wrap">
        <div class="message-header__block message-header__block--title">
          <h3 class="message-header__title">Žinutės</h3>
        </div>
        <div class="message-header__block">
          <div class="recent-contacts">
            <div class="recent-contacts__wrap">
              <div v-for="c in 10" :key="c" class="recent-contacts__block">
                <MessagePicture />
                <div class="recent-contacts__block-name">Suhaib Al Minhas</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MessagePicture from "@/components/message/MessagePicture.vue";

export default {
  components: {
    MessagePicture,
  },
  props: {
    recent_contacts: {
      type: Object,
    },
  },
  setup() {},
};
</script>
<style lang="scss" scoped>
.recent-contacts {
  display: flex;
  align-items: center;
  overflow-x: auto;

  &__wrap {
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: 100%;
    gap: 10px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 50px;
  }

  &__block-image {
    display: flex;
    justify-content: center;

    img {
      border-radius: 30px;
    }
  }

  &__block-name {
    color: $color-blue;
    font-family: $font-family;
    font-size: 9px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
  }
}

.message-header {
  border-bottom: 1px solid $color-light-grey;
  min-height: 110px;
  display: flex;
  align-items: center;

  &__title {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__block {
    flex-basis: 0;
    flex-grow: 999;

    &--title {
      flex-grow: 0;
      flex-basis: 350px;
    }
  }
}
</style>

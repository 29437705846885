let __ID__ = 0;

export const uniqid = (prefix) => `${prefix}${++__ID__}`;
export const readFileDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const once = { once: true };
    fileReader.addEventListener("abort", reject, once);
    fileReader.addEventListener("error", reject, once);
    fileReader.addEventListener("load", (e) => resolve(e.target.result), once);

    fileReader.readAsDataURL(file);
  });
};
export const restoreLocalStorageJSON = (key) => {
  const value = localStorage.getItem(key);

  return value ? JSON.parse(value) : null;
};

<template>
  <a class="go-back" href="#" @click.prevent="goBack">
    <img src="@/assets/arrow_long_left.svg" alt="back" />
    <span v-text="$t('common.back')" />
  </a>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["go:back"]);

const goBack = () => emit("go:back");
</script>
<style lang="scss" scoped>
.go-back {
  margin: 20px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  span {
    color: $color-grey;
    font-family: $font-family;
  }
}
</style>

<template>
  <div class="course-subscription">
    <div class="container">
      <div class="course-subscription__wrap">
        <div class="course-subscription__block">
          <div>
            <div
              class="course-subscription__label"
              v-text="$t('course.subscription.employee_count')"
            />
            <TextInput
              v-model="employees"
              type="number"
              :min="100"
              :max="2000"
              :step="10"
              class="course-subscription__input"
            />
            <Slider v-model="employeesProxy" :min="100" :max="2500" />
            <div
              class="course-subscription__label"
              v-text="$t('course.subscription.preliminary_price')"
            />
            <h3>{{ priceFormatted }} EUR</h3>
          </div>
        </div>
        <div class="course-subscription__block">
          <table class="course-subscription__table">
            <thead>
              <th>{{ $t("course.subscription.employees") }}</th>
              <th>{{ $t("course.subscription.price") }}</th>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in numbers"
                :key="index"
                :class="{
                  'course-subscription--active': index === activeIndex,
                }"
              >
                <td>{{ row.range }}</td>
                <td>
                  {{ row.price }}
                  {{ $t("course.subscription.price_per_person") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="course-subscription__footer">
        <div>
          <p>{{ $t("common.subscription.text") }}</p>
          <RouterLink v-slot="{ navigate }" custom :to="{ name: 'contacts' }">
            <CustomButton
              :type="'dark'"
              :text="$t('common.send_request')"
              @click="navigate"
            />
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import CustomButton from "./CustomButton.vue";
import TextInput from "./inputs/TextInput.vue";
import Slider from "./form/Slider.vue";
import { SUBSCRIPTION_PRICING } from "@/plugins/subscription";

const employees = ref(100);
const employeesProxy = computed({
  get: () => {
    const options = SUBSCRIPTION_PRICING;
    if (employees.value < options[0].value) {
      return options[0].value;
    }

    if (employees.value > options[options.length - 1].value) {
      return options[options.length - 1].value;
    }

    return employees.value;
  },
  set: (value) => (employees.value = value),
});

const numbers = computed(() => {
  const result = [];
  if (SUBSCRIPTION_PRICING.length < 2) return result;

  return SUBSCRIPTION_PRICING.map((option, index) => {
    if (index < SUBSCRIPTION_PRICING.length - 1) {
      return {
        range: `${option.value} - ${SUBSCRIPTION_PRICING[index + 1].value}`,
        price: `${new Intl.NumberFormat("lt-LT", {
          minimumFractionDigits: 2,
        }).format(option.price / 100)}`,
      };
    } else {
      return {
        range: `nuo ${option.value}`,
        price: `${new Intl.NumberFormat("lt-LT", {
          minimumFractionDigits: 2,
        }).format(option.price / 100)}`,
      };
    }
  });
});

const price = computed(() => {
  let total = 0;
  const number = employees.value;

  SUBSCRIPTION_PRICING.every((item, index, pricing) => {
    if (index === 0) {
      if (number > item.value) {
        total += item.value * item.price;

        return true;
      } else {
        total = number * item.price;

        return false;
      }
    } else {
      if (number > item.value) {
        total +=
          (item.value - pricing[index - 1].value) * pricing[index - 1].price;

        return true;
      } else {
        total += (number - pricing[index - 1].value) * pricing[index - 1].price;

        return false;
      }
    }
  });

  if (number > SUBSCRIPTION_PRICING.at(-1).value) {
    total +=
      (number - SUBSCRIPTION_PRICING.at(-1).value) *
      SUBSCRIPTION_PRICING.at(-1).price;
  }

  return total;
});

const activeIndex = computed(() => {
  const number = employees.value;
  const pricing = [...SUBSCRIPTION_PRICING].reverse();
  let index = SUBSCRIPTION_PRICING.length - 1;
  for (const option of pricing) {
    if (number > option.value) {
      return index;
    } else {
      index--;
    }
  }

  return 0;
});

const priceFormatted = computed(() => {
  return new Intl.NumberFormat("lt-LT").format(price.value / 100);
});
</script>
<style lang="scss" scoped>
h3 {
  text-align: left !important;
}

.course-subscription {
  border-bottom: 1px solid $color-light-grey;
  min-height: 789px;
  @media screen and (max-width: 900px) {
    margin-top: 36px;
  }

  &__input {
    margin-bottom: -7px;
  }

  &__footer {
    text-align: center;

    p {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      text-align: center;

      margin-bottom: 30px;
      margin-top: 87px;
    }
  }

  &__table {
    width: 100%;

    &--bold {
      font-weight: 500;
    }

    thead {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      text-align: left;
    }

    tbody {
      tr {
        &:hover {
          border-radius: 4px;
          background-color: $color-light-blue;
        }

        &.course-subscription--active {
          border-radius: 4px;
          background-color: $color-light-blue;
        }

        td {
          padding: 6px 7px;
          color: $color-blue;
          font-family: $font-family;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;

          &__bold {
            color: $color-blue;
            font-family: $font-family;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 26px;
          }
        }
      }
    }
  }

  &__label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
  }

  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
  }

  p {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 80px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 110px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      gap: 0;
    }
  }

  &__block {
    flex-basis: 100%;
  }
}
</style>

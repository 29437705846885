<template>
  <accordion v-if="pages.length" :padding="34">
    <accordion-item v-for="page in pages" :key="page.id">
      <template #accordion-trigger="{ isOpen }">
        <div class="subject__accordion-trigger">
          <div class="subject__accordion-trigger__title-wrapper">
            <div
              v-if="page.completion === MANUAL_COMPLETION"
              class="subject__completion-status"
              :class="{
                'subject__completion-status--done': page.completed,
              }"
            >
              <img
                :src="CorrectWhiteIcon"
                alt="check"
                @click="setPageCompletion(page)"
              />
            </div>
            <h3 v-text="page.title" />
          </div>
          <img :src="isOpen ? MinusBlueIcon : PlusBlueIcon" alt="svg" />
        </div>
      </template>
      <template #accordion-content>
        <div v-dompurify-html="page.content" />
      </template>
    </accordion-item>
  </accordion>
</template>

<script setup>
import { defineProps } from "vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import CorrectWhiteIcon from "@/assets/correct_white.svg";
import { useRoute } from "vue-router";
import {
  MANUAL_COMPLETION,
  useCompletionsStore,
} from "@/store/modules/completions.store";

defineProps({
  pages: {},
});

const completionsStore = useCompletionsStore();

const { setCompleted } = completionsStore;

const route = useRoute();

const setPageCompletion = async (page) => {
  if (page.completed) {
    return;
  }
  await setCompleted(
    page.module,
    page.course_module_id,
    !page.completed,
    route.params.id,
  );
};
</script>

<style lang="scss" scoped>
.subject {
  margin-bottom: $header-height;
  font-family: $font-family;

  &__completion-status {
    height: 24px;
    width: 32px;
    background-color: rgba($color-black, 0.4);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 24px;
    min-width: 32px;

    img {
      transform: unset;
    }

    &--done {
      background: $color-green;
    }
  }

  .group-items {
    padding: 0 10px;
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
      box-shadow: none;
    }
  }

  .accordion {
    margin-bottom: 0;

    .group-items {
      padding: 0 12px 0 0;
    }
  }

  &__accordion-trigger {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    h3 {
      color: $color-blue !important;
      font-weight: 500;
      font-family: $font-family;
      font-size: $font-size-slg;
    }
  }
}
</style>

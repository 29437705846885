<template>
  <div class="error-state-error">
    <div class="error-state-error__content">
      <img src="../../assets/exlm_mark.svg" />

      <h3>Įvyko klaida</h3>

      <p>
        Nepavyko rasti jūsų ieškomo puslapio. Pabandykite naršyti mūsų kursų
        katalogą arba ieškoti atsakymo mūsų pagalbos skiltyje.
      </p>

      <p v-dompurify-html="$t('covid_message', { to })" />

      <CustomButton
        :type="'grey-transparent'"
        :text="'Grįžti į pagrindinį puslapį'"
        @click="goHome()"
      />
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import CustomButton from "@/components/CustomButton";
import { ref } from "vue";
import { ROUTE } from "@/router/routenames";

const to = ref(router.resolve({ name: ROUTE.AppDownload }).href);

const goHome = () => {
  router.push({
    name: ROUTE.Home,
  });
};
</script>

<style lang="scss">
.error-state-error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(8px);
  z-index: 9;

  &__content {
    text-align: center;

    img {
      margin-bottom: 60px;
    }

    h3 {
      margin-bottom: 20px;
      text-align: center;

      color: $color-blue;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
    }

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      max-width: 600px;

      margin-bottom: 80px;
    }
  }
}
</style>

<template>
  <div class="filter-modal-wrapper" @click="open">
    <span>{{ $t("common.filter_by") }}</span>
    <img src="../../assets/icons/filter-blue.svg" alt="Filter" />
  </div>
  <FullPageModal
    v-if="isOpen"
    :name="'filters'"
    :toggle="true"
    @close="close()"
  >
    <template #body>
      <filter-content />
    </template>
  </FullPageModal>
</template>
<script>
import { defineComponent } from "vue";
import FilterContent from "@/components/filter/FilterContent.vue";
import FullPageModal from "@/components/FullPageModal.vue";

export default defineComponent({
  components: { FullPageModal, FilterContent },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
    },
  },
});
</script>
<style lang="scss" scoped>
.filter-modal-wrapper {
  border-top: 1px solid #e7e8ef;
  border-bottom: 1px solid #e7e8ef;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;

  span {
    font-size: $font-size-md;
    color: $color-primary;
    font-weight: 500;
    font-family: $font-family;
  }
}
</style>

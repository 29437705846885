export default {
  "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithunia"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "payment": {
    "montonio_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "pp_express": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal Express Checkout"])},
    "free_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free order"])},
    "header_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successfully completed"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to home page"])}
  },
  "covid_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download the mobile app <a href=\"", _interpolate(_named("to")), "\" target=\"_blank\">here</a>"])},
  "navigation": {
    "search_certificates_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for certificates"])},
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for courses"])},
    "how-it-works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About HybridLab"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "eshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Shop"])},
    "opportunities_for_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities for institutions"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changing"])},
    "projects": {
      "dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU Projects"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])}
    }
  },
  "megamenu": {
    "private": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All courses"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by field"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by specialty"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by workplace"])}
        }
      ],
      "course_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses according to"])}
    },
    "business": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
      "links": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses for companies and institutions"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab business opportunities"])}
        }
      ]
    }
  },
  "home": {
    "courses-by-filter": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by filters"])}
    },
    "courses-by-category": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by category"])},
      "show-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View courses"])}
    },
    "courses-by-popularity": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by popularity"])}
    },
    "advantages": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making the world of learning better"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create innovative learning solutions that are easier to master and more convenient to achieve."])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses according to the approved training methodology"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows learning remotely through virtual simulations that provide an authentic and interactive learning experience."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a certificate"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides the opportunity to obtain certificates and demonstrate your skills and knowledge.."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance learning"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allows learning remotely through virtual simulations that provide an authentic and interactive learning experience."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve the qualifications of the organization's employees"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® helps organizations improve their employees' knowledge and skills, ensuring high qualifications and successful results."])}
        }
      ],
      "types[0]": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® allows you to learn and practice anywhere, anytime, according to your schedule and needs."])}
      }
    },
    "clients": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cooperate with more than 20 famous companies."])}
    },
    "contactus": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about opportunities for agencies or businesses"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave your phone number and our team will get back to you shortly and tell you all about the possibilities."])},
      "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
      "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    },
    "types": {
      "find-out-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out more"])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities for treatment and public institutions"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses and individual pricing apply to large groups."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business opportunities"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply HybridLab tools and learning methodologies to your business."])}
        }
      ]
    }
  },
  "discover-courses": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover courses"])},
    "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "filter-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searched by:"])}
  },
  "course": {
    "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "sidebar_types": [
      
    ],
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the course"])},
    "methodology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the course"])},
    "abilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The skills you will acquire"])},
    "subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course topics"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "reviews_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no reviews."])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aim of the Course"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectives of the Course"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Overview"])},
    "how_to_study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to study"])},
    "surveys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaires"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "events": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("n")), " events"])},
    "subscription": {
      "employee_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees in the company or institution"])},
      "preliminary_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preliminary subscription price"])},
      "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "price_per_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/per person"])}
    },
    "results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " results"])},
    "activity": {
      "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry, but activity was not found"])},
      "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your remote session has expired"])},
      "session_refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh session"])},
      "back_to_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to course"])}
    }
  },
  "footer": {
    "hybridlab": {
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
      "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])}
    },
    "private": {
      "discover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-shop"])},
      "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])}
    },
    "business": {
      "business_and_institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities for organisations"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])}
    },
    "rights_reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved. "])}
  },
  "common": {
    "back_to_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home page"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "company_types": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordering internal training"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to order specialized training for your company's employees, please provide the following information: the number of employees, the desired training topic and the scope or duration of the training. \nThis will help us get in touch and provide you with the best offer to suit your needs."])}
    },
    "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["find out more"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For business"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My courses"])},
    "purchases_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase history"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "popular_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most popular courses"])},
    "certificate_validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity of the certificate"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "my_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My certificates"])},
    "connect_other_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect a second device"])},
    "course_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course duration"])},
    "course_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of training"])},
    "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "in_the_sim_room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the simulation room"])},
    "recieve_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upon successful completion of the course you will receive a certificate"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h."])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "payment_checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete payment"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty."])},
    "send_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a request"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently asked questions"])},
    "faq_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no frequently asked questions."])},
    "old_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old version"])},
    "looking_course_for_bigger_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in courses for larger groups?"])},
    "courses_for_bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses for companies and institutions"])},
    "courses_for_business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses for companies and institutions"])},
    "courses_by_business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses by institution type"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "or_copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or copy the link"])},
    "share_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share via"])},
    "share_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share the course"])},
    "general_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, please try again."])},
    "icons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["icons"])},
    "cart_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The shopping cart is empty"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "total_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payment"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "add_to_cart_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product added to cart"])},
    "add_to_cart_modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The course has been successfully added to the cart"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to continue shopping or go to cart?"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue shopping"])},
      "go_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Cart"])}
    },
    "by_completing_the_payment_you_agree_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By completing the payment you agree to"])},
    "terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "get_in_touch_easily_by_filling_out_the_contact_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch easily by filling out the contact form"])},
    "request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request form"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "faq_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "hybridlab_contacts_and_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab contacts and details"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])},
    "left_attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are still attempts"])},
    "mark_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark the question"])},
    "edit_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit question"])},
    "no_subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no topics."])},
    "remind_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember password"])},
    "how_it_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
    "login_to_learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the training"])},
    "or_login_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or sign in with your username"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "incorrect_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect username or password"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "dont_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account yet?"])},
    "create_new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new account."])},
    "login_with_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "your_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate you have obtained"])},
    "certificate_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate is valid"])},
    "certificate_not_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate is not valid"])},
    "course_end_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course completion result"])},
    "certificate_author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate was issued"])},
    "course_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course material"])},
    "show_end_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only completed courses"])},
    "purchases_history_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no purchases"])},
    "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ID:"])},
    "create_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an invoice"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of payment"])},
    "change_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change e-mail \npost office"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "preferred_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred language"])},
    "city_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the city"])},
    "country_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the country"])},
    "language_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the preferred language"])},
    "timezone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the time zone"])},
    "organisation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter organisation (min. 3 symbols)"])},
    "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter position (min. 3 symbols)"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the password"])},
    "organizational_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizational settings"])},
    "workplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workplace"])},
    "responsibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duties"])},
    "specify_the_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the position"])},
    "enter_the_name_of_the_workplace_or_organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of the workplace or organization"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "birthday_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "select_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a country"])},
    "select_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the city where you live"])},
    "we_will_remind_you_about_the_courses_according_to_the_set_time_zone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will remind you about the courses according to the set time zone"])},
    "show_valid_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only valid certificates"])},
    "interested_in_courses_for_larger_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in courses for larger groups?"])},
    "fill_out_the_inquiry_form_and_we_will_contact_you_shortly_with_an_individual_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the inquiry form and we will contact you shortly with an individual offer."])},
    "fill_out_the_request_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the request form"])},
    "all_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All courses"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "general_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General part"])},
    "theoretical_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theoretical material"])},
    "tests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests"])},
    "select_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one"])},
    "about_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the course"])},
    "courses_for_business_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raise the qualifications of your company's or organization's employees according to HybridLab's approved training methodology."])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
    "register_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "register_error": {
      "username_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username has already been taken."])},
      "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email has already been taken."])},
      "password_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long"])}
    },
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "create_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create password"])},
    "repeat_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "you_already_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "i_have_met_and_agree_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have met and agree with"])},
    "user_register_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully created"])},
    "upcoming_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming courses"])},
    "all_my_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All my courses"])},
    "lastest_solve_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last courses to solve"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To share"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "link_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "video_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "document_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "extlms_room_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "algorithm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithm"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
    "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
    "hvp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive content"])},
    "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "subscription_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordering internal training"])},
    "subscription_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to order specialized training for your company's employees, please provide the following information: the number of employees, the desired training topic and the scope or duration of the training. \nThis will help us get in touch and provide you with the best offer to suit your needs."])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "upload_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change photo"])},
    "paypal_redirection_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be redirected to the PayPal payment system where you will complete the payment."])},
    "change_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change information"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "login_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or sign in with your username"])},
    "login_with_facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect with Facebook"])},
    "are_you_have_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
    "subscription": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are interested in a course subscription or if it does not meet your needs, please contact us."])}
    },
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "cookie_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie policy"])},
    "upcoming_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no upcoming courses."])},
    "lastest_solve_courses_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no recent sprint courses."])},
    "watch_the_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the presentation"])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a person"])},
    "apply_a_discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply discount code"])},
    "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "your_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your firstname"])},
    "your_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your lastname"])},
    "your_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your telephone"])},
    "your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail"])},
    "your_birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your birthdate"])},
    "your_gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your gender"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "select_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a topic"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A message"])},
    "get_in_touch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "in_this_subject_you_learn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this topic you will learn"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten your username or password?"])},
    "reminder_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder sent"])},
    "try_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "recommended_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended courses for you"])},
    "change_profile_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change profile information"])},
    "password_settings_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will remind you about the courses according to the set time zone"])},
    "change_account_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit profile"])},
    "change_account_information_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "no_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one course could not be found according to these criteria."])},
    "no_enrolled_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any courses to solve"])},
    "save_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "repeat_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the password"])},
    "create_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password"])},
    "email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email \npostal address"])},
    "enter_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new password"])},
    "repeat_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the new password"])},
    "situation_material": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scenario"])},
    "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourite"])},
    "buy_as_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Entity"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company name"])},
    "company_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Code"])},
    "company_code_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company code"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])},
    "company_address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company address"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company VAT number"])},
    "company_vat_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company VAT number (optional)"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum"])},
    "courses_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " courses"])},
    "go_course_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to course list"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "cancel_upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "edit_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit photo"])},
    "topics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topics"])},
    "server_timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server timezone (UTC)"])},
    "welcome_user": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello, ", _interpolate(_named("name"))])},
    "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])}
  },
  "reviews": {
    "leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave review"])},
    "add_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add review"])},
    "incorrect_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect data submitted"])},
    "error_while_trying_to_save_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while trying to save review"])}
  },
  "based_on_AHA_recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on AHA recommendations"])},
  "qr": {
    "0": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a meeting in the mobile application"])}
    },
    "1": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Connect external devices\" or search"])}
    },
    "2": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code below"])}
    }
  },
  "about": {
    "about_hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About HybridLab"])},
    "header": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthesis of distance learning and hands-on simulation."])}
    },
    "map": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To make the world of learning better"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create better learning solutions that are more accessible and convenient."])},
      "items": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travels"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["While traveling, we have always looked for what is missing and what still needs to be done, created and improved in the learning world."])}
        },
        "1": {
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today, our main focus is to enable 24/7 hands-on simulation and remote imparting of technical and non-technical skills, especially in the field of medical education."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global thought"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That is why we develop and offer innovative learning, teaching, training methods and technological solutions."])}
        }
      }
    },
    "applies_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used for deployment in remote, resource-constrained areas."])}
  },
  "activity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® activity"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® training and simulations do not require direct instructor involvement and can be set up anywhere. \nBefore coming to HybridLab®, students prepare using pre-prepared emails. \nLearning systems. \nOn the spot, they receive orders and instructions on what to do and how to do it using specially designed algorithms with any training equipment. \nThe instructor monitors and assesses student performance remotely at any time while students are training in the HybridLab® classroom."])},
    "items": {
      "0": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic learning"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® allows you to learn without connecting to a live instructor. \nLearning can take place anywhere you want."])}
      },
      "1": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hands on simulation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® provides an opportunity to learn through virtual simulations, where the direct involvement of an instructor is not required. \nThis allows you to gain hands-on skills with any training equipment."])}
      },
      "2": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peer-to-peer training"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® enables the instructor to monitor and assess student achievement remotely while they are working in the HybridLab® classroom."])}
      },
      "3": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on successful learning algorithms"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® uses specially designed algorithms that allow students to receive commands and instructions on what to do and how to do it, tailored to their learning."])}
      }
    }
  },
  "how-it-works": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
    "available_on_mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on mobile"])},
    "sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sine causa? quae fuerit causa, nollem me ab illo."])},
    "advantages": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning to make the world a better place"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create innovative learning solutions that are easier to master and more convenient to access."])},
      "types": [
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn where it's convenient for you"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HybridLab method allows you to access all the information on your phone or computer, and to study remotely, adapting to your schedule and needs."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get certified"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab offers an opportunity to obtain an official medical profile certificate upon completion of training."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International practice"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In HybridLab, advanced simulation classes are used for practice, with all the necessary equipment for medical practice."])}
        },
        {
          "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raise the qualifications of the organization's employees"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab is a great opportunity to improve the qualifications of the organization's employees. Stay competitive with continuous improvement."])}
        }
      ]
    },
    "application": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid learning without leaving home"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HybridLab® training can take place anywhere or, depending on the course, remotely. Hybrid learning requires:"])},
      "steps": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the mobile application"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the course you want to study"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the course material, check your knowledge - solve the test"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for practical training"])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete practical training, receive a teacher's evaluation and a certificate of completion"])}
        }
      ],
      "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about hybrid payment"])}
    },
    "laboratories": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid training in HybridLab laboratories"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid training in simulation/hybrid training classes Hybridlab® is an excellent solution for optimizing the learning process."])},
      "items": [
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexibility"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training gives more freedom and flexibility. The theoretical part of the training can be viewed remotely, at a time convenient for students. Choose the most convenient time and classmates for practical sessions."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual learning pace"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical training takes place in small groups of three, each learner learns at his own pace. This makes it easier to absorb the material, repeat more difficult subjects or review simpler ones faster."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new learning method"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students use a variety of learning tools and methods. Effective and scientifically proven flipped class and peer-to-peer learning methodology is combined."])}
        },
        {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practical learning"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using interactive algorithms, students practically solve situations, acquire knowledge and skills that can be immediately applied in practice."])}
        }
      ]
    }
  },
  "contacts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "desc_part": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a question? \nTry to find it among"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", and if you can't find it, then we'd love to hear from you."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with the contacts below or send us an inquiry by filling out the form and we will get back to you as soon as possible."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch by filling out the form"])}
  },
  "howitworks": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create innovative learning solutions that are easier to master and more convenient to access."])}
  },
  "commoon": {
    "forgot_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reminder sent to email. \naddress, please check your mailbox and follow the instructions."])}
  },
  "banner": {
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "our_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our app"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the course! We believe that the training will meet your expectations."])}
  },
  "course_sidebar": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "location_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location address"])},
    "location_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location name"])}
  },
  "change_password": {
    "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "password_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully changed"])},
    "wrong_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
    "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])}
  },
  "certificates": {
    "empty_result_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have any сertificates"])},
    "empty_result_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to the Courses list and register for a course\nwhich is useful for you."])},
    "empty_result_redirect_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Courses list"])}
  },
  "grades": {
    "grade_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grade: ", _interpolate(_named("grade"))])},
    "topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "calculated_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated weight"])},
    "contribution_to_course_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution to course total"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "course_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course total"])},
    "category_total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("category")), " total"])},
    "you_are_not_allowed_to_see_grades_for_this_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're not allowed to see grades for this course"])}
  }
}
<template>
  <span
    v-text="
      business ? $t('megamenu.business.title') : $t('megamenu.private.title')
    "
  />
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";

const route = useRoute();
const business = computed(() => {
  return route.path.startsWith("/business/");
});
</script>

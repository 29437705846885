<template>
  <div v-if="recaptchaSiteKey" class="form-group">
    <div class="recaptcha">
      <Field rules="required" name="recaptcha" :model-value="recaptchaValue">
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="recaptchaSiteKey"
          @verify="onSuccess"
          @error="onError"
          @expired="reset"
        />
      </Field>
    </div>
    <ErrorMessage v-slot="{ message }" name="recaptcha">
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { VueRecaptcha } from "vue-recaptcha";

const recaptcha = ref();
const recaptchaValue = ref(null);
const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITEKEY;

const onError = () => (recaptchaValue.value = false);
const onSuccess = () => (recaptchaValue.value = true);
const reset = () => (recaptchaValue.value = null);
</script>

<style lang="scss" scoped>
.form-group {
  margin: 30px 0;
}

.error {
  color: red;
  font-family: $font-family;
  font-size: 13px;
  margin-top: 5px;
}
</style>

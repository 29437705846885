<template>
  <div class="form-group">
    <label v-if="label" :for="id">
      {{ label }}
      <slot name="label" />
    </label>
    <Multiselect
      :id="id"
      v-model="innerValue"
      class="form-control"
      :class="{ multiselect__empty: !innerValue }"
      :options="options"
      track-by="value"
      label="name"
      :placeholder="placeholder"
      @search-change="handleSearchChange"
    />
    <ErrorMessage
      v-if="errors.length && name"
      v-slot="{ message }"
      :name="name"
    >
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineOptions, defineProps } from "vue";
import Multiselect from "vue-multiselect";
import { uniqid } from "@/plugins/misc";
import { ErrorMessage } from "vee-validate";
import debounce from "lodash.debounce";

const id = uniqid("select-input-");
defineOptions({ inheritAttrs: false });
const emit = defineEmits(["update:modelValue", "input"]);
const props = defineProps({
  modelValue: String,
  options: Array,
  label: String,
  placeholder: String,
  name: String,
  errors: {
    type: Array,
    default: () => [],
  },
  fetchOptions: Function,
});

const innerValue = computed({
  get: () => {
    if (!props.modelValue) return null;
    if (!props.options) return null;

    return props.options.find((x) => x.value === props.modelValue) || null;
  },
  set: (value) => {
    if (value) {
      emit("update:modelValue", value.value);
    } else {
      emit("update:modelValue", undefined);
    }
  },
});

const handleSearchChange = debounce((search) => {
  if (
    props.fetchOptions &&
    typeof props.fetchOptions === "function" &&
    search &&
    search.length > 2
  ) {
    props.fetchOptions(search);
  }
}, 1000);
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.multiselect__select::before {
  background-image: url("../../assets/icons/arrow-down-grey.svg");
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  top: 30%;
  color: unset;
  margin-top: unset;
  border: unset;
}

.error {
  color: $color-red;
  font-family: $font-family;
  font-size: $font-size-sm;
  margin-top: 5px;
}

.form-control {
  margin-top: 5px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: $color-blue;
  font-family: $font-family;
  font-size: $font-size-sm;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 50px;
  min-width: 230px;

  &--is-invalid {
    border-color: $color-red;
  }
}

.multiselect__tags {
  background: $color-white;
  border: 1px solid $color-light-grey;
  border-radius: 8px;
  min-width: 360px;
  min-height: 50px;
  padding: 14.5px 15px;
  font-size: $font-size-md;

  @media screen and (max-width: $breakpoint-sm) {
    min-width: unset;
  }
}

.multiselect__placeholder {
  font-size: $font-size-md;
  font-weight: 400;
  line-height: 14px;
  color: $color-menu-item;
  margin: 0;
}

.multiselect__select {
  min-height: 50px;
}

.multiselect__single {
  background: $color-white;
  margin-bottom: 0;
  width: auto;
  font-size: $font-size-md;
  padding: 0;
}

.multiselect__option--highlight {
  background: $color-blue;

  &:after {
    background: $color-blue;
  }
}

.multiselect__input {
  background: $color-light-grey;
  font-family: $font-family;
  font-size: $font-size-md;
  padding-left: 0;
}

.multiselect__empty .multiselect__tags {
  background: $color-light-grey;
}

.multiselect--active .multiselect__select {
  z-index: 1;
}
</style>

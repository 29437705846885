import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useDiscountStore = defineStore("discount", () => {
  const loading = ref(false);
  const discount = ref(null);

  const discountBanner = computed(() => {
    if (discount.value === null) {
      return null;
    }

    const image = discount.value?.images?.[0]?.[0];
    if (!image) {
      return null;
    }

    return {
      discount: discount.value?.percentage || "",
      color: discount.value?.color || "",
      image: discount.value?.image_base + (image?.image || ""),
      description: image?.description || "",
      link: image?.link || "",
      link_text: image?.link_text || "",
      title: image?.title || "",
    };
  });

  async function request() {
    try {
      loading.value = true;
      const { data } = await axios.get("/dynamic_banners", {
        params: { type: "Discount" },
      });

      loading.value = false;
      discount.value = data.data[0];

      return true;
    } catch (error) {
      loading.value = false;
      console.error(error);

      return false;
    }
  }

  return {
    discount,
    discountBanner,
    loading,
    request,
  };
});

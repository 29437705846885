<template>
  <div v-if="open">
    <Modal
      :name="'video_modal'"
      :dark="true"
      :width="600"
      :toggle="true"
      @close="close()"
    >
      <template #body>
        <div class="video">
          <iframe
            width="100%"
            height="315"
            :src="video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div class="description">
          {{ description }}
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      description: "",
      video: "https://www.youtube.com/embed/jgm58cbu0kw",
      open: false,
    };
  },
  created() {
    let vm = this;
    this.emitter.on("video", (evt) => {
      vm.description = evt.description;
      vm.video = evt.video;
      vm.open = true;
    });
  },
  methods: {
    close() {
      this.open = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.description {
  color: #ffffff;
  font-family: $font-family;
  font-size: $font-size-sm;
  letter-spacing: 0;
  line-height: 26px;
  padding: 20px;
}
</style>

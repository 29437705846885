<template>
  <div>
    <Navigation :is-static="false" />
    <PageHeader
      :image="header?.image"
      :title="header?.title"
      :description="header?.description"
    />
    <div v-if="chapters.length === 1" class="advantages">
      <div class="container">
        <h3 v-dompurify-html="chapters[0].title"></h3>
      </div>
    </div>

    <div v-if="chapters.length > 1" class="advantages-right">
      <div class="container">
        <div class="advantages-right__wrap">
          <div class="advantages-right__block advantages-right__block--content">
            <div class="advantages-right__content">
              <h3 v-dompurify-html="chapters[1].content"></h3>

              <div v-if="chapters[1].children?.length" class="list">
                <div
                  v-for="(item, index) in chapters[1].children"
                  :key="index"
                  class="list__item"
                >
                  <div class="list__number">{{ index + 1 }}</div>
                  <div v-dompurify-html="item.title" class="list__text"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="advantages-right__block advantages-right__block--image"
            @click="downloadApp"
          >
            <HLAppDownloadBanner class="advantages-right__block--image--svg" />
            <div class="advantages-right__block--image--text">
              {{ $t("how-it-works.available_on_mobile") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script setup>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import i18n from "@/i18n";
import { usePageStore } from "@/store/modules/page.store";
import { ref, watch } from "vue";
import HLAppDownloadBanner from "@/components/HLAppDownloadBanner.vue";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";

const router = useRouter();
const pagesStore = usePageStore();
const header = ref(null);
const chapters = ref([]);

const downloadApp = () => {
  const route = router.resolve({ name: ROUTE.AppDownload });
  window.open(route.href, "_blank");
};

watch(
  () => i18n.global.locale,
  async () => {
    const { id, chapters: book } = await pagesStore.book("how-it-works");
    header.value = await pagesStore.request(id);
    chapters.value = book;
  },
  { immediate: true },
);
</script>
<style lang="scss" scoped>
.list-icon {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    h4 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-slg;
      letter-spacing: 0;
      line-height: 22px;
      font-weight: 100;

      margin-bottom: 5px;
    }
  }
}

.list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 20px;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 26px;
  }

  &__number {
    height: 46px;
    width: 46px;
    min-width: 46px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    border-radius: 30px;
    border: 2px solid $color-primary;
  }

  &__text {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-slg;
    letter-spacing: 0;
    line-height: 26px;

    :deep() a {
      color: $color-primary;
      text-decoration: underline;
      text-underline-offset: 8px;
    }
  }
}

.advantages-right {
  &__wrap {
    display: flex;
    gap: 100px;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
      gap: 40px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-wrap: wrap-reverse;
      margin-top: 30px;
      gap: 46px;
      margin-bottom: 60px;
    }
  }

  &__block {
    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-basis: 100%;
      cursor: pointer;
      position: relative;

      &--svg {
        &:hover {
          fill: $color-light-grey;

          :deep(.download-icon) {
            fill: $color-primary;
          }
        }

        @media screen and (max-width: $breakpoint-sm) {
          width: 100%;
          height: 100%;
          max-width: 354px;
          max-height: 354px;
        }
      }

      &--text {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-primary;
        font-size: $font-size-lg;
        font-weight: 500;
        text-align: center;

        @media screen and (max-width: $breakpoint-lg) {
          width: 90%;
        }
      }
    }

    &--content {
      flex-basis: 100%;
    }
  }

  &__content {
    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 20px;

      :deep() strong {
        font-weight: 500 !important;
      }
    }

    P {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 26px;

      margin-bottom: 50px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;

      text-decoration: none;
    }

    &--about {
      margin-top: 30px;
    }
  }
}

.advantages {
  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-top: 60px;
    font-weight: bold;
  }

  &__description {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 70px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 57px 0px;
    margin-bottom: 93px;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    width: 469px;
    gap: 26px;
  }

  &__text {
    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: left;
    }

    p {
      opacity: 0.5;
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 26px;
      text-align: left;
    }
  }
}
</style>

<template>
  <div class="courses-list">
    <div class="courses-list__wrap">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import "swiper/css";

export default {
  setup() {
    const onSwiper = () => {};

    const onSlideChange = () => {};

    return {
      onSwiper,
      onSlideChange,
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>

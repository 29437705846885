import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useAuth2Store } from "./auth2.store";
import { useGuestStore } from "./guest.store";
import { useSettingsStore } from "@/store/modules/settings.store";

export const useSocialStore = defineStore("social", () => {
  const settingsStore = useSettingsStore();

  const loading = ref(false);
  const data = ref(null);
  const forcePasswordChange = ref(false);

  const google = computed(() => {
    if (data.value === null) return null;
    if (!("google" in data.value)) return null;

    return data.value.google;
  });

  const request = async () => {
    if (data.value !== null) return;

    try {
      loading.value = true;

      const { data: response } = await backService.get("auth/social");

      loading.value = false;
      data.value = response;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const passwordChangePreference = async () => {
    try {
      const userId = settingsStore.values?.contact_info?.user_id;
      if (!userId) {
        forcePasswordChange.value = false;
        return;
      }

      const { data } = await backService.get(
        "auth/password/change/preference",
        {
          params: { user_id: userId },
        },
      );

      forcePasswordChange.value = data.result;
    } catch (error) {
      console.error(error);
    }
  };

  const googleCallback = async (response) => {
    const { data } = await backService.post("auth/social/google", {
      ...response,
      oc_session: useGuestStore().session,
    });

    await useAuth2Store().callback(data);
  };

  return {
    google,
    request,
    googleCallback,
    passwordChangePreference,
    forcePasswordChange,
  };
});

<template>
  <div class="thread__block">
    <MessagePicture :users="thread.users" />

    <div class="thread__content">
      <div class="thread__author">
        {{ getName(thread) }}
      </div>
      <div class="thread__message">
        <div class="thread__message-content">{{ thread.lastMessage }}</div>
        <div class="thread__message-time">• 09:12</div>
      </div>
    </div>
  </div>
</template>
<script>
import MessagePicture from "@/components/message/MessagePicture.vue";

export default {
  components: {
    MessagePicture,
  },
  props: {
    thread: {},
  },
  data() {
    return {};
  },
  methods: {
    getName(thread) {
      let name = "";

      thread.users.forEach((user) => {
        name += user.name + ", ";
      });

      return name;
    },
  },
};
</script>
<style lang="scss" scoped>
.thread {
  &__block {
    display: flex;
    gap: 15px;
    max-width: 320px;
    cursor: pointer;
  }

  &__author {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 240px;
    white-space: nowrap;
  }

  &__message {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 256px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__message-content {
  }

  &__message-time {
  }
}
</style>

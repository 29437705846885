<template>
  <div>
    <Navigation />

    <div>
      <div class="container">
        <div class="certificate">
          <div class="certificate__back">
            <router-link :to="{ name: 'dashboard.certificates' }">
              <img alt="back" src="../../../assets/arrow_long_left.svg" />
              <span v-text="$t('common.back')" />
            </router-link>
          </div>
          <div class="certificate__row">
            <div class="certificate__column">
              <div class="certificate__content">
                <div class="certificate__image">
                  <div v-if="certificateImage" class="certificate__image">
                    <img :src="certificateImage" alt="certificate" />
                  </div>
                </div>
                <div>
                  <h2>{{ $t("common.your_certificate") }}</h2>
                  <div class="certificate__description">
                    <p v-dompurify-html="certificate.description" />
                  </div>
                </div>
              </div>
            </div>
            <div class="certificate__column certificate__column--sidebar">
              <div class="certificate__block-wrap">
                <div class="certificate__details">
                  <div class="certificate__details-wrap">
                    <div class="certificate__details-item">
                      <div class="certificate__details-image">
                        <img
                          src="../../../assets/icons/calendar-lightblue.svg"
                        />
                      </div>
                      <div class="certificate__details-content">
                        <div class="certificate__details-content-name">
                          {{ $t("common.certificate_valid") }}
                        </div>
                        <div class="certificate__details-content-value">
                          <span
                            v-if="certificate.valid?.from"
                            v-text="
                              getParsedDate(certificate.valid.from) +
                              ' - ' +
                              getParsedDate(certificate.valid.to)
                            "
                          />
                          <span
                            v-else
                            class="text-danger"
                            v-text="$t('common.certificate_not_valid')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="certificate__details-item">
                      <div class="certificate__details-image">
                        <img
                          src="../../../assets/icons/progress-lightblue.svg"
                        />
                      </div>
                      <div class="certificate__details-content">
                        <div class="certificate__details-content-name">
                          {{ $t("common.course_end_result") }}
                        </div>
                        <div class="certificate__details-content-value">
                          {{ certificate.endResult }} %
                        </div>
                      </div>
                    </div>
                    <div class="certificate__details-item">
                      <div class="certificate__details-image">
                        <img
                          src="../../../assets/icons/certificate-lightblue.svg"
                        />
                      </div>
                      <div class="certificate__details-content">
                        <div class="certificate__details-content-name">
                          {{ $t("common.certificate_author") }}
                        </div>
                        <div class="certificate__details-content-value">
                          {{ certificate.organisation }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="actions">
                  <CustomButton type="transparent" @click="downloadPDF()">
                    <template #text>
                      <img
                        src="../../../assets/icons/download-blue.svg"
                        alt="Download"
                      />
                    </template>
                  </CustomButton>
                  <CustomButton
                    class="course-material-button"
                    type="transparent"
                    :text="$t('common.course_material')"
                    @click="courseMaterial()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RecommendedCourseList />
    </div>

    <Footer />
  </div>
</template>

<script>
import moment from "moment";
import SimpleNavigation from "@/components/SimpleNavigation";
import HeaderBlock from "@/components/dashboard/HeaderBlock";
import CourseListHorizontal from "@/components/course/CourseListHorizontal";
import Footer from "@/components/Footer";
import CustomButton from "@/components/CustomButton";
import Accordion from "@/components/accordion/Accordion";
import AccordionItem from "@/components/accordion/AccordionItem";
import ProgressLine from "@/components/ProgressLine";
import Navigation from "@/components/Navigation";
import { useRoute, useRouter } from "vue-router";
import backService from "@/plugins/service";
import RecommendedCourseList from "@/components/course/RecommendedCourseList";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import { getParsedDate } from "../../../util/helpers";
import { computed } from "vue";
import ExtlmsCertificate from "@/assets/certificates/extlms.png";
import PMPCertificate from "@/assets/certificates/pmp.png";

export default {
  components: {
    SimpleNavigation,
    HeaderBlock,
    Footer,
    CustomButton,
    CourseListHorizontal,
    Accordion,
    AccordionItem,
    ProgressLine,
    Navigation,
    RecommendedCourseList,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      router,
      route,
    };
  },
  data() {
    return {
      loading: false,
      certificate: {
        valid: {
          from: "",
          to: "",
        },
      },
    };
  },
  computed: {
    certificateImage() {
      switch (this.certificate.type) {
        case "extlms": {
          return ExtlmsCertificate;
        }
        case "pmp": {
          return PMPCertificate;
        }
        default: {
          return null;
        }
      }
    },
    PlusBlueIcon() {
      return PlusBlueIcon;
    },
    MinusBlueIcon() {
      return MinusBlueIcon;
    },
    percent() {
      if (this.certificate) {
        var startOfDate = moment(this.certificate.valid.from),
          endDate = moment(this.certificate.valid.to),
          todayDate = moment();

        var daysDifference = moment(endDate).diff(startOfDate, "days");
        var difference = todayDate.diff(startOfDate, "days");

        var result = Math.round((difference / daysDifference) * 100);

        return 100 - result + "%";
      } else {
        return "0%";
      }
    },
  },
  async mounted() {},
  async created() {
    await this.getCertificate(this.route.params.id);
  },
  methods: {
    getParsedDate,
    courseMaterial() {
      this.router.push({
        name: "course.theory",
        params: {
          id: this.certificate.courseId,
        },
      });
    },
    downloadPDF() {
      location.href = this.certificate.pdf;
    },
    async getCertificate(id) {
      let vm = this;
      this.loading = true;
      await backService
        .get(`/certificates/${id}`)
        .then((response) => {
          vm.certificate = response.data;
          vm.loading = false;
        })
        .catch(() => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate {
  padding-top: 60px;

  &__warnings {
    font-family: $font-family;
    color: white;
    background: $color-red;
    border-radius: 0.5rem;
    padding: 1rem;
    width: 100%;

    :deep(a) {
      color: white;
    }

    :deep(ul) {
      padding: 0;
      list-style-type: none;
    }
  }

  &__back {
    margin-bottom: 15px;

    a {
      display: flex;
      align-items: center;
      gap: 12px;
      color: $color-blue;
      font-family: $font-family;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 21px;
      text-decoration: none;
    }
  }

  &__validity {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
  }

  &__validity-image {
    height: 48px;
    width: 54px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__validity-content {
    width: 100%;
  }

  &__validity-content-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__validity-content-name {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__validity-content-start-date,
  &__validity-content-end-date {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__header {
    h2 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;

      margin-bottom: 30px;
    }
  }

  &__faq {
    margin-top: 60px;

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__faq-header {
    padding: 28px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__faq-content {
    margin-bottom: 20px;
  }

  &__block-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__details {
    background-color: $color-white;
    display: flex;
    align-items: center;
    padding: 0 20px 30px 0;
  }

  &__details-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__details-item {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__details-image {
    height: 46px;
    width: 46px;
    border-radius: 30px;
    background-color: $color-light-grey;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__details-content {
  }

  &__details-content-name {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 3px;
  }

  &__details-content-value {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__info-block {
    background-color: $color-green;

    &--not-valid {
      background-color: gray;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 43px;
    @media screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      margin-bottom: 10px;
      max-width: 393px;
      @media screen and (max-width: $breakpoint-lg) {
        max-width: initial;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    @media screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__image {
    img {
      width: 246px;
      height: 172px;
      object-fit: contain;
    }
  }

  h2 {
    line-height: 32px;
    @media screen and (max-width: $breakpoint-lg) {
      text-align: center;
    }
  }
  &__description {
    p {
      margin-top: 26px;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 28px;

      margin-bottom: 20px;

      @media screen and (max-width: $breakpoint-lg) {
        text-align: center;
      }
    }
  }

  .accordion__trigger {
    img {
      transition: all 0.5s;
    }
  }

  .accordion__trigger_active {
    img {
      transform: rotate(181deg);
    }

    h3 {
      opacity: 1;
      color: $color-blue !important;
    }
  }
}
.actions {
  display: flex;
  gap: 20px;

  button {
    color: $color-blue;
    font-weight: 500;
    font-size: $font-size-md;
    font-family: $font-family;
    padding: 10px;
    min-width: unset;
  }
  .course-material-button {
    flex: 1;
  }
}
</style>

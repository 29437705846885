<template>
  <div class="subject-list">
    <div class="subject-list__wrap">
      <div
        v-for="topic in topics"
        :key="topic"
        class="subject-list__item"
        :class="{
          'subject-list__item--active': topic.id === currentSubject?.id,
        }"
        @click.stop="selectSubject(topic)"
      >
        <div class="subject-list__item-name">{{ topic.title }}</div>
        <div
          class="subject-list__item-description"
          :class="{
            'subject-list__item-description--space-between':
              showCourseTime(topic),
          }"
        >
          <div v-if="showCourseTime(topic)" class="subject-list__item-time">
            <CourseTime
              :duration="topic.duration"
              :active="currentSubject && topic.id !== currentSubject?.id"
            />
          </div>
          <div v-if="!isMobile" class="subject-list__item-progress">
            <CourseProgress
              :active="currentSubject && topic.id !== currentSubject?.id"
              :current="getCompletedCount(topic)"
              :max="getTotalCount(topic)"
            />
          </div>
          <div v-else class="subject-list__item-progress-mobile">
            <div class="progress__inner-text">
              <span
                class="progress__inner-text-done"
                v-text="getCompletedCount(topic)"
              />
              / {{ getTotalCount(topic) }}
            </div>
            <PercentOval :percent="getProcent(topic)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PercentOval from "@/components/PercentOval.vue";
import CourseTime from "@/components/course/CourseTime.vue";
import CourseProgress from "@/components/course/CourseProgress.vue";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import useIsMobile from "@/util/useIsMobile";
import { useRouter } from "vue-router";
import { computed } from "vue";

const props = defineProps({
  topics: { type: Array, required: true },
  currentSubject: { type: Object, required: true },
  course: { type: Object, required: true },
});

const { isMobile } = useIsMobile();
const router = useRouter();

const isShopEnabled = computed(() => isShoppingEnabled());

const getTotalCount = (t) => {
  let total = 0;
  t.sections.forEach((element) => {
    total += element.parts.length;
  });

  return total;
};
const getCompletedCount = (t) => {
  let total = 0;
  t.sections.forEach((element) => {
    let a = element.parts.filter((part) => part.completed == true);
    total += a.length;
  });

  return total;
};
const selectSubject = (subject) => {
  router.replace({
    name: ROUTE.Course.topic,
    params: { id: props.course.id, subject: subject.id },
    hash: "#course-subject",
  });
};
const showCourseTime = (topic) =>
  isShopEnabled.value
    ? topic.sections.some(
        (section) =>
          !section.parts.some((part) => part.module === "extlmsroom"),
      )
    : false;
const getProcent = (topic) => {
  const completed = getCompletedCount(topic);
  if (completed === 0) {
    return 0;
  }

  return Math.round((completed / getTotalCount(topic)) * 100);
};
</script>

<style scoped lang="scss">
.subject-list {
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    cursor: pointer;

    padding: 10px;
    gap: 15px;
    width: 100%;
    justify-content: space-around;
    color: $color-blue;
    border: 1px solid #dcdee9;

    @media screen and (max-width: $breakpoint-lg) {
      justify-content: flex-end;
      flex-wrap: wrap;
      background-color: $color-light-grey;
      border-radius: 8px;
      border: unset;
    }
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: unset;
    }

    &-progress-mobile {
      display: flex;
      align-self: center;
      align-items: center;
      gap: 6px;

      .progress__inner-text {
        align-self: center;
        font-family: $font-family;
        font-size: $font-size-md;
        font-weight: 500;
        color: $color-menu-item;
        width: 50px;

        &-done {
          color: $color-light-green;
        }
      }

      :deep(.percent-block .percent-block__svg circle:first-child) {
        stroke: $color-progress;
      }
    }

    .subject-list__info {
      display: flex;
      gap: 10px;

      @media screen and (max-width: $breakpoint-lg) {
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }

    .subject-list__item-time {
      color: $color-blue;

      .course-time {
        @media screen and (max-width: $breakpoint-sm) {
          padding: 0;
        }
      }
    }

    &--active {
      background-color: $color-primary;
      border: solid 1px $color-primary;
      color: white;

      .progress__inner-text {
        color: $color-white;
      }

      :deep(.percent-block .percent-block__svg circle:first-child) {
        stroke: $color-white;
      }
    }
  }

  &__item-name {
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 16px;
    min-width: 160px;
    margin-right: auto;
  }

  &__item-description {
    display: flex;
    gap: 15px;
    align-items: center;

    @media screen and (max-width: $breakpoint-sm) {
      justify-content: flex-end;

      &--space-between {
        justify-content: space-between;
      }
    }
  }
}
</style>

<template>
  <div v-if="content" v-dompurify-html="content" class="page-block"></div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    id: [String, Number],
  },
  data() {
    return { content: null };
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      async handler() {
        const response = await axios.get(`/information/${this.id}`);
        this.content = response.data.data.description;
      },
    },
  },
};
</script>

<style lang="scss">
.page-block * {
  font-family: $font-family !important;
}
</style>

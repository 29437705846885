<template>
  <div class="course-product__container">
    <div class="course-product">
      <p class="course-product__label">
        {{ $t("course.events", { n: course.products.length }) }}
      </p>
      <ProductCartList
        :products="visibileProducts"
        :course="course"
        :selected="selected"
        @select:product="select"
      />
      <hr v-if="showProducts" />
      <button
        v-if="leftProducts.length && !showProducts"
        class="show-more"
        @click="showAllProducts"
      >
        <img alt="Show more" src="../../assets/menu/arrow-down-grey.svg" />
      </button>
      <ProductCartList
        v-if="showProducts"
        :products="leftProducts"
        :course="course"
        :selected="selected"
        @select:product="select"
      />
    </div>
    <div class="course-product__add-to-cart">
      <div class="course-product__events-button">
        <CourseAddToFavourites
          :data="course"
          :dark="true"
          class="course-product__add-to-favorites"
          :current-course-route="true"
        />
        <CustomButton
          full-width
          type="default"
          :disabled="!selected"
          :text="$t('common.add_to_cart')"
          class=""
          @click="addToCart"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import CustomButton from "../CustomButton.vue";
import { useCartStore } from "@/store";
import CourseAddToFavourites from "@/components/course/CourseAddToFavourites.vue";
import ProductCartList from "@/components/course/ProductCartList.vue";

const VISIBLE_PRODUCTS_IN_CART = 3;

const cartStore = useCartStore();

const selected = ref(null);
const showProducts = ref(false);

const props = defineProps({
  course: Object,
});

const sortedProducts = computed(() => {
  const sortedProducts = props?.course.products?.slice();
  sortedProducts?.sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date),
  );
  return sortedProducts;
});
const visibileProducts = computed(() =>
  sortedProducts.value.slice(0, VISIBLE_PRODUCTS_IN_CART),
);
const leftProducts = computed(() =>
  sortedProducts.value.slice(
    VISIBLE_PRODUCTS_IN_CART,
    sortedProducts.value.length,
  ),
);

const showAllProducts = () => (showProducts.value = true);
const select = (product) => {
  if (selected.value === product.product_id) {
    selected.value = null;
  } else {
    selected.value = product.product_id;
  }
};

const addToCart = () => {
  if (!selected.value) return;
  cartStore.add(selected.value);
};
</script>
<style lang="scss">
.course-product {
  margin-bottom: 20px;
  border: 1px solid $color-grey-border;

  @media only screen and (max-width: $breakpoint-lg) {
    border: unset;
  }

  &__container {
    margin-bottom: 20px;
  }

  &__label {
    font-family: $font-family;
    color: $color-cadet-blue-crayola;
    font-size: $font-size-sm;
    font-weight: 400;
    padding-top: 23px;
    padding-left: 20px;
    padding-bottom: 10px;
  }

  &__events-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 11px;

    button {
      width: 100%;
      display: block;
      border: 2px solid $color-primary;
      font-size: $font-size-md;
      font-weight: 500;
      color: $color-white;
      border-radius: 5px;
      padding: 13px 0;
      background-color: $color-primary;
      opacity: 1;
    }

    button.btn--disabled {
      background-color: $color-white;
      color: $color-primary;
    }
  }

  &__add-to-cart {
    &.selected {
      @media screen and (max-width: 900px) {
        position: fixed;
        bottom: 0;
        z-index: 20;
        display: flex;
        width: 100%;
        background-color: #fff;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.show-more {
  background: unset;
  border: unset;
  margin: 0 auto;
  display: block;

  img {
    width: 12px;
    height: 12px;
  }
}
hr {
  margin: 0;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid $color-grey-border;
  border-top: none;
}
</style>

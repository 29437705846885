import { defineStore } from "pinia";
import { ref } from "vue";
import backService from "@/plugins/service";
import i18n from "@/i18n";
import { useAuth2Store } from "@/store/modules/auth2.store";

export const useLocaleStore = defineStore("locale", () => {
  const auth2Store = useAuth2Store();

  const loading = ref(false);
  const languages = ref([]);

  const request = async () => {
    try {
      loading.value = true;

      const { data } = await backService.get("languages");

      loading.value = false;
      languages.value = data.languages;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  const update = async (language) => {
    try {
      loading.value = true;

      if (auth2Store.isLoggedIn) {
        await backService.post("languages", { language });
      }
      i18n.global.locale = language;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return {
    loading,
    languages,

    request,
    update,
  };
});

import axios from "axios";
import { defineStore } from "pinia";

export const useCountriesStore = defineStore({
  id: "countries",
  state: () => {
    return {
      current: null,
      loading: false,
      error: null,
      data: null,
    };
  },
  getters: {
    currentCountry() {
      if (!this.current || !this.data) return null;

      return this.data.data.find((x) => x.iso_code_2 === this.current);
    },
    currentCountryId() {
      if (!this.currentCountry) return null;

      return this.currentCountry.country_id;
    },
    options() {
      if (!this.data) return [];

      return (this.data.data || []).map((x) => ({
        name: x.name,
        value: x.iso_code_2,
      }));
    },
  },
  actions: {
    setCurrent(value) {
      this.current = value;

      return true;
    },
    async request() {
      if (this.data || this.loading) return false;

      this.loading = true;

      try {
        const { data } = await axios.get("/countries");

        this.loading = false;
        this.error = null;
        this.data = data;

        return true;
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
  },
});

<template>
  <div class="form-group">
    <div class="checkbox">
      <label class="checkbox__label">
        <Field
          :id="name"
          :label="label.toLowerCase()"
          :rules="rules"
          :name="name"
          :checked="currentValue"
          :value="value"
          class="checkbox__input"
          :class="{ 'checkbox__input--checked': currentValue }"
          type="checkbox"
          @change="onInput"
        />
        <div
          class="checkbox__icon"
          :class="{ 'checkbox__icon--checked': currentValue }"
        />
        <slot>
          <span v-text="label" />
        </slot>
      </label>
    </div>
    <ErrorMessage v-slot="{ message }" :name="name">
      <p class="error" v-text="message" />
    </ErrorMessage>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import { ErrorMessage, Field } from "vee-validate";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
  },
  modelValue: {
    type: Boolean,
    default: undefined,
  },
  value: {
    default: false,
  },
  rules: {
    type: [Object, String],
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const innerValue = ref(false);

const currentValue = computed({
  get: () =>
    props.modelValue === undefined ? innerValue.value : props.modelValue,
  set: (value) => {
    if (props.modelValue === undefined) {
      innerValue.value = !!value;
    } else {
      emit("update:modelValue", value);
    }
  },
});

const onInput = () => {
  currentValue.value = !currentValue.value;
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin-bottom: 15px;
}

.checkbox {
  &__icon {
    appearance: none;
    -webkit-appearance: none;
    height: 32px;
    width: 32px;
    background-color: $color-light-grey;
    cursor: pointer;
    position: relative;
  }

  &__input--checked ~ &__icon--checked {
    background-image: url("@/assets/icons/check.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
  }

  &__input:checked ~ &__icon:after {
    display: block;
  }

  &__label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 23px;
    cursor: pointer;
    display: flex;
    position: relative;
    user-select: none;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0 !important;
    font-weight: 400;
  }

  &__input {
    display: none;
  }
}

.error {
  color: red;
  font-family: $font-family;
  font-size: 13px;
  margin-top: 5px;
}
</style>

<template>
  <div class="range-slider">
    <vue-slider v-model="innerValue" :tooltip="tooltip" :min="min" :max="max" />
  </div>
</template>
<script setup>
import { computed, defineEmits, defineProps } from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: Number,
  min: Number,
  max: Number,
  tooltip: {
    type: String,
    default: "none",
  },
});

const innerValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
<style lang="scss" scoped>
.vue-slider .vue-slider-rail {
  background-color: #dcdee9;
}

.vue-slider-dot {
  top: -129% !important;
}

.vue-slider-dot-handle {
  height: 26px;
  width: 26px;
  border-radius: 18px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(45, 54, 113, 0.35);
}

.vue-slider-process {
  border-radius: 1.5px;
  background-color: $color-primary !important;
}
</style>

import axios from "axios";
import { defineStore } from "pinia";
import { useAlertStore } from "./alert.store";
import { restoreLocalStorageJSON } from "@/plugins/misc";
import backService from "@/plugins/service";

const LOCAL_STORAGE_KEY = "ACCOUNT_DATA";

export const useAccountStore = defineStore({
  id: "account",
  state: () => {
    return {
      loading: false,
      error: null,
      data: restoreLocalStorageJSON(LOCAL_STORAGE_KEY),
      update: null,
      isNotSetup: null,
    };
  },
  getters: {
    customer_id() {
      if (!this.data) return null;
      if (!this.data.data) return null;

      return this.data.data.customer_id || null;
    },
    values() {
      return {
        email: "",
        firstname: "",
        lastname: "",
        telephone: "",
        ...((this.data || {}).data || {}),
      };
    },
  },
  actions: {
    reset() {
      localStorage.removeItem(LOCAL_STORAGE_KEY);

      this.data = null;
    },
    async request() {
      this.loading = true;

      try {
        const { data } = await axios.get("/account");

        this.loading = false;
        this.error = null;
        this.data = data;

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));

        return true;
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async updateData(values) {
      const alertStore = useAlertStore();

      this.loading = true;

      try {
        const { data } = await axios.put("/account", values);
        await backService.post("/settings", values);
        this.update = data;
        this.isNotSetup = null;

        alertStore.success("Pavyko", "Informacija sėkmingai atnaujinta.");

        return this.request();
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async updatePassword(values) {
      this.loading = true;

      try {
        const { data } = await axios.put("/account/password", values);

        this.update = data;

        return this.request();
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    async notSetup(force = false) {
      this.loading = true;

      if (this.isNotSetup !== null && !force) {
        return this.isNotSetup;
      }

      try {
        const { data } = await backService.get("/user/not-setup");
        this.isNotSetup = data.data;

        return data.data;
      } catch (error) {
        this.loading = false;
        this.error = error;

        return false;
      }
    },
    setNotSetup(value) {
      this.isNotSetup = value;
    },
  },
});

<template>
  <Transition>
    <div v-if="show" class="banner">
      <div class="banner__content">
        <div class="banner__content-image">
          <img :src="image" alt="Banner image" />
        </div>
        <div class="banner__content-details">
          <header>
            <h2 v-text="$t('banner.download')" />
            <h3 v-text="$t('banner.our_app')" />
          </header>
          <p v-text="$t('banner.welcome')" />
          <div class="banner__content-details-buttons">
            <div class="col play">
              <a
                href="https://play.google.com/store/apps/details?id=com.myhybridlab.glocare&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  class="qr"
                  src="@/assets/banner/qr_google_play.png"
                  alt="Google play QR code"
                />
                <img
                  class="badge"
                  src="@/assets/banner/google-store.svg"
                  alt="Get it on Google Play"
                />
              </a>
            </div>

            <div class="col appstore">
              <a
                href="https://apps.apple.com/us/app/hybridlab/id1667249333?itsct=apps_box_badge&amp;itscg=30200"
              >
                <img
                  class="qr"
                  src="@/assets/banner/qr_apple_store.png"
                  alt="Apple store QR code"
                />
                <img
                  class="badge"
                  src="@/assets/banner/apple-store.svg"
                  alt="Download on the App Store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="banner__close" @click="close">
        <img src="@/assets/close.svg" alt="close" />
      </div>

      <div class="banner__hide">
        <input
          id="hide_banner"
          type="checkbox"
          name="hide_banner"
          @change="updateHideBanner"
        />
        <label for="hide_banner" v-text="'Do not show again'" />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { onBeforeMount, onUnmounted, ref } from "vue";

const show = ref(false);
const image = ref("");
const close = () => {
  show.value = false;
};

const updateHideBanner = (e) => {
  localStorage.setItem("glocare_banner_hide", +e.target.checked);
};

const onResize = () => {
  image.value =
    window.innerWidth >= 900
      ? require("@/assets/banner/wide.png")
      : require("@/assets/banner/narrow.svg");
};

onBeforeMount(() => {
  if (
    !localStorage.getItem("glocare_banner_hide") ||
    +localStorage.getItem("glocare_banner_hide") !== 1
  ) {
    show.value = true;
  }
  window.addEventListener("resize", onResize);
  onResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>

<style scoped lang="scss">
.banner {
  position: relative;
  background: $color-light-grey;
  font-family: $font-family;
}

.banner__content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0;

  @media (max-width: $breakpoint-xlg) {
    flex-flow: column-reverse;
  }

  .banner__content-image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .banner__content-details {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
    padding: 24px 0;

    header {
      margin-bottom: 22px;

      h2,
      h3 {
        color: $color-primary;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        text-transform: uppercase;
      }

      h2 {
        font-size: $font-size-xxl;
        margin-bottom: 15px;
      }

      h3 {
        font-size: $font-size-xlg;
      }
    }

    p {
      font-size: $font-size-md;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      padding: 0 10px;
    }

    .banner__content-details-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 2rem;
      margin-top: 20px;

      .col {
        display: flex;
        flex-direction: column;

        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.banner__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 3;
}

.banner__hide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;

  label {
    font-size: 10px;
  }
}

@media (max-width: 900px) {
  .banner {
    padding-bottom: 0;
  }

  .banner__content {
    flex-direction: column-reverse;

    .banner__content-image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .banner__content-details {
      padding-top: 36px;

      .banner__content-details-buttons {
        gap: 20px 71px;
      }
    }
  }
}

a img.qr {
  width: 140px;
  border: 1px solid #cbd5e1;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 5px;
  background-color: $color-white;

  @media screen and (max-width: $breakpoint-sm) {
    width: 86px;
  }
}

a img.badge {
  height: 45px;
  margin-top: 13px;
  margin-bottom: 10px;

  @media screen and (max-width: $breakpoint-sm) {
    height: 27px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: all 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

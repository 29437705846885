<template>
  <div class="advantages">
    <div class="container">
      <h3
        v-dompurify-html="content.title"
        :class="{ 'has-slideshow': hasSlideshow }"
      />
      <p class="advantages__description" />

      <div v-if="content.children?.length" class="advantages__wrap">
        <div
          v-for="(chapter, index) in content.children"
          :key="index"
          class="advantages__block"
        >
          <div v-if="isShoppingEnabled()">
            <img v-if="index % 4 === 0" src="../../assets/advantages/1/1.svg" />
            <img
              v-else-if="index % 4 === 1"
              src="../../assets/advantages/2/2.svg"
            />
            <img
              v-else-if="index % 4 === 2"
              src="../../assets/advantages/3/3.svg"
            />
            <img
              v-else-if="index % 4 === 3"
              src="../../assets/advantages/4/4.svg"
            />
          </div>
          <div v-else>
            <img v-if="index % 2 === 0" src="../../assets/advantages/1/1.svg" />
            <img
              v-else-if="index % 2 === 1"
              src="../../assets/advantages/3/3.svg"
            />
          </div>
          <div class="advantages__text">
            <h4 v-dompurify-html="chapter.title" />
            <p v-dompurify-html="chapter.content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { isShoppingEnabled } from "@/util/helpers";
import { useSlideshowStore } from "@/store/modules/slideshow.store";

defineProps({
  content: Object,
});

const slideshowStore = useSlideshowStore();

const hasSlideshow = computed(() => slideshowStore.list.length);
</script>

<style lang="scss">
.advantages {
  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: calc(60px + #{$header-height});

    &.has-slideshow {
      margin-top: 60px;
    }

    @media screen and (max-width: 900px) {
      font-size: $font-size-lg;
      font-weight: 500;
      margin-bottom: 40px;
    }
  }

  &__wrap {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 42px 80px;
    margin-bottom: 70px;

    @media screen and (max-width: $breakpoint-lg) {
      gap: 50px;
    }
  }

  &__block {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: start;
    width: 469px;
    gap: 26px;

    img {
      width: 80px;
      height: 80px;
    }

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;
    }
  }

  &__text {
    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      letter-spacing: 0;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      margin-bottom: 26px;
    }

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
    }
  }
}
</style>

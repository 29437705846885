<template>
  <Transition>
    <div
      v-if="isOpened && data"
      :class="['modal-container', cssClass]"
      @click="modalStore.close()"
    >
      <component :is="data" @click.stop />
    </div>
  </Transition>
</template>

<script setup>
import { computed } from "vue";
import { useModalStore } from "@/store/modules/modal.store";

const modalStore = useModalStore();

const data = computed(() => modalStore.component);
const isOpened = computed(() => modalStore.visible);
const cssClass = computed(() => modalStore.cssClass);
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background-color: rgb(59 65 112 / 58%);

  div {
    background-color: #fff;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-active {
  animation: bounce-in 0.25s;
}

.v-leave-active {
  animation: bounce-out 0.5s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>

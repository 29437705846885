<template>
  <a
    v-for="lang in localeStore.languages"
    :key="lang"
    :data-lang="lang"
    class="navbar__dropdown-item"
    @click.prevent="changeLanguage(lang.tag)"
  >
    <span class="lang-sm" :lang="lang.url_code" />
    <span v-text="lang.url_code.toUpperCase()" />
  </a>
</template>

<script setup>
import { useLocaleStore } from "@/store/modules/locale.store";
import { defineProps } from "vue";

const localeStore = useLocaleStore();

const props = defineProps({
  callback: {
    type: Function,
    required: false,
  },
});

const changeLanguage = (lang) => {
  localeStore.update(lang);

  if (props.callback && typeof props.callback === "function") {
    props.callback();
  }
};

if (!localeStore.languages.length) {
  localeStore.request();
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/languages.min.scss";

.navbar__dropdown-item {
  cursor: pointer;
  padding: 3px 34px 3px 0;
  color: $color-menu-item;
  font-size: $font-size-xs;
  font-family: $font-family;
  font-weight: 500;
  gap: 10px;

  img {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  }
}
</style>

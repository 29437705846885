import axios from "axios";
import { defineStore } from "pinia";

export const useGlobalStore = defineStore({
  id: "global",
  state: () => ({
    filters: [],
    current_filters: [],
  }),
  getters: {
    query(state) {
      const result = [];
      state.current_filters.forEach((filter) => {
        if (Array.isArray(filter.value)) {
          result.push(...filter.value);
        } else if (filter.value) {
          result.push(filter.value);
        }
      });
      return result.join(",");
    },
  },
  actions: {
    async addFilterById(filter_id, group_id) {
      let group = this.filters.find(
        (group) => group.filter_group_id === group_id,
      );
      let filter = group.filter.find(
        (filter) => filter.filter_id === filter_id,
      );

      this.current_filters.push({
        name: filter.name,
        value: filter.filter_id,
      });
    },
    resetSearchData() {
      this.filters = [];
      this.search = "";
      this.category = "";
    },
    resetLanguageFilters(availableLocales) {
      this.current_filters = this.current_filters.filter(
        (f) => !availableLocales.includes(f.name.toLowerCase()),
      );
    },
    updateFilters(filter) {
      const hasFilter = this.current_filters.some(
        (f) => f.name === filter.name && f.value === filter.filter_id,
      );

      if (filter && !hasFilter) {
        this.current_filters.push({
          name: filter.name,
          value: filter.filter_id,
        });
      }
    },
    listFilters() {
      return this.filters.length === 0 ? this.getFilters() : this.filters;
    },
    async getFilters() {
      let vm = this;
      await axios
        .get("/filters/id/59")
        .then((response) => {
          vm.filters = response.data.data.filter_groups;
        })
        .catch(console.error);
    },
  },
});

<template>
  <div v-if="showDiscountForm" class="3">
    <Form :initial-values="initialValues" @submit="handleSubmit">
      <TextInputField
        ref="discountCodeEl"
        v-model="discountCode"
        name="discount_code"
        :placeholder="$t('common.discount_code')"
        @blur="onDiscountCodeBlur"
      />
      <CustomButton v-if="showApplyDiscount" :type="'dark'" :full-width="true">
        <template #text>
          {{ $t("common.apply_a_discount_code") }}
        </template>
      </CustomButton>
    </Form>
  </div>
  <div v-else class="cart__discount-block">
    <CustomButton
      flex
      :type="'grey-transparent'"
      :full-width="true"
      @click="addDiscountCode()"
    >
      <template #text>
        {{ $t("common.apply_a_discount_code") }}
      </template>
    </CustomButton>
  </div>
  <CustomButton
    v-if="!showApplyDiscount"
    :type="'dark'"
    :full-width="true"
    @click="confirm()"
  >
    <template #text>
      {{ $t("common.pay") }}
    </template>
  </CustomButton>
</template>

<script setup>
import { Form } from "vee-validate";
import CustomButton from "../CustomButton.vue";
import { computed, defineEmits, nextTick, ref, watch } from "vue";
import axios from "axios";
import { useAlertStore, useCartStore } from "@/store";
import TextInputField from "../form/TextInputField.vue";

const alertStore = useAlertStore();
const cartStore = useCartStore();

const emit = defineEmits(["submit"]);

const discountCodeEl = ref(null);
const discountCode = ref(cartStore.discount || "");
const showDiscountForm = ref(false);
const showApplyDiscount = ref(false);

const initialValues = computed(() => ({ discount_code: cartStore.coupon }));

const confirm = () => {
  emit("submit");
};
const addDiscountCode = () => {
  showDiscountForm.value = true;
  showApplyDiscount.value = true;
  nextTick(() => {
    discountCodeEl.value?.focus();
  });
};
const handleSubmit = (values) => {
  showApplyDiscount.value ? addDiscount(values) : confirm();
};
const addDiscount = async (values) => {
  const code = values.discount_code;
  if (code === "") {
    return;
  }
  try {
    const { data: voucher } = await axios.post(
      "/voucher",
      { voucher: code },
      { skipError: true },
    );
    showApplyDiscount.value = false;
    alertStore.success("Pavyko", voucher.message[0]);
    cartStore.getItems();
  } catch (error) {
    console.error(error);
    try {
      const { data: coupon } = await axios.post("/coupon", { coupon: code });
      showApplyDiscount.value = false;
      alertStore.success("Pavyko", coupon.message[0]);
      cartStore.getItems();
    } catch (error) {
      console.error(error);
    }
  }
};
const onDiscountCodeBlur = () => {
  if (discountCode.value === "") {
    showApplyDiscount.value = false;
  }
};

watch(
  () => cartStore.discount,
  (newValue) => {
    if (newValue && !showDiscountForm.value) {
      showDiscountForm.value = true;
      discountCode.value = cartStore.discount;
    }
  },
  { immediate: true },
);

watch(
  () => cartStore.totalsIsEmpty,
  (newValue) => {
    if (newValue) {
      discountCode.value = "";
    }
  },
);

watch(
  () => discountCode.value,
  (newValue, prevValue) => {
    if (newValue === "" || (prevValue === "" && cartStore.discount)) {
      showApplyDiscount.value = false;
      if (newValue === "" && cartStore.discount) {
        cartStore.removeDiscount();
      }
    } else {
      addDiscountCode();
    }
  },
);
</script>

<style lang="scss" scoped>
.cart {
  &__discount-block {
    margin-bottom: 30px;
  }
}

:deep(.form-control) {
  padding: 16px 30px;
  line-height: 23px;
  height: inherit !important;
}
</style>

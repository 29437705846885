<template>
  <ul class="menu-mobile__locale">
    <li
      v-for="locale in locales"
      :key="locale.id"
      class="menu-mobile__locale-item"
      :class="{
        'menu-mobile__locale-item--active': i18n.global.locale === locale.value,
      }"
    >
      <button type="button" @click="changeLanguage(locale.value)">
        {{ locale.value }}
      </button>
    </li>
  </ul>
</template>

<script setup>
import { useLocaleStore } from "@/store/modules/locale.store";
import i18n from "@/i18n";
import { computed } from "vue";

const localeStore = useLocaleStore();

const locales = computed(() => {
  return localeStore.languages.map((language) => ({
    name: language.title_native,
    value: language.url_code,
  }));
});

const changeLanguage = (code) => localeStore.update(code);
</script>

<style lang="scss" scoped>
.menu-mobile__locale {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  list-style: none;
  margin: 75px 0 40px;
  padding: 0 30px;

  &-item {
    & > button {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-md;
      text-wrap: nowrap;
      border: none;
      background-color: $color-light-grey;
      color: $color-blue;
      font-weight: 500;
    }

    &--active > button {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}
</style>

<template>
  <div>
    <Navigation :is-static="false" />

    <PageHeader
      :image="PageImage"
      :title="$t('common.courses_for_business')"
      :description="$t('common.courses_for_business_desc')"
    />

    <PageBlock :id="page" class="container" />

    <CourseSubscription />
    <ContactUs />
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import CourseSubscription from "@/components/CourseSubscription.vue";
import ContactUs from "@/components/ContactUs.vue";
import PageBlock from "@/components/PageBlock.vue";
import PageImage from "@/assets/bussiness-and-institusion@2x.png";

export default {
  components: {
    Footer,
    Navigation,
    PageHeader,
    CourseSubscription,
    ContactUs,
    PageBlock,
  },
  setup() {
    return { PageImage };
  },
  data() {
    return {
      categories: [
        {
          name: "Gydymo įstaigoms",
          items: [
            {
              name: "Chirurgija",
              count: 24,
            },
            {
              name: "Traumatologija",
              count: 24,
            },
            {
              name: "Neonatologija",
              count: 24,
            },
            {
              name: "Pediatrija",
              count: 24,
            },
            {
              name: "Kardiologija",
              count: 24,
            },
            {
              name: "Pulmonologija",
              count: 24,
            },
            {
              name: "Reabilitacija",
              count: 24,
            },
            {
              name: "Skubi medicina",
              count: 24,
            },
            {
              name: "Gastroenterologija",
              count: 24,
            },
            {
              name: "Endokrinologija",
              count: 24,
            },
            {
              name: "Urologija",
              count: 24,
            },
            {
              name: "Neurologija",
              count: 24,
            },
            {
              name: "Reumatologija",
              count: 24,
            },
            {
              name: "Slauga",
              count: 24,
            },
            {
              name: "Specializuota pagalba",
              count: 24,
            },
            {
              name: "Ne techniniai įgūdžiai",
              count: 24,
            },
          ],
        },
        {
          name: "Ne gydymo įstaigoms",
          items: [
            {
              name: "Medicinos studentas",
              count: 24,
            },
            {
              name: "Gydytojas",
              count: 24,
            },
            {
              name: "Parmedikas",
              count: 24,
            },
            {
              name: "Ne medicinos darbuotojas",
              count: 24,
            },
            {
              name: "Gydytojas",
              count: 24,
            },
            {
              name: "Parmedikas",
              count: 24,
            },
            {
              name: "Ne medicinos darbuotojas",
              count: 24,
            },
          ],
        },
      ],
    };
  },
  computed: {
    page() {
      return process.env.VUE_APP_ORG_PAGE || 12;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 36px;
}
</style>

<template>
  <div class="rating">
    <img v-for="val in maxRating" :src="getImage(val)" alt="star" />
  </div>
</template>

<script setup>
import ImgStarEmpty from "@/assets/course/star_empty.svg";
import ImgStarFull from "@/assets/course/star_full.svg";

const RATING_MAX = 5;

const props = defineProps({
  rating: {
    type: Number,
    default: 0,
  },
  maxRating: {
    type: Number,
    default: RATING_MAX,
  },
});

const getImage = (currentId) =>
  props.rating >= currentId ? ImgStarFull : ImgStarEmpty;
</script>

<style scoped lang="scss">
.rating {
  display: flex;
  column-gap: 10px;
}
</style>

<template>
  <div v-if="text" class="course-page__column--sidebar__info-block">
    <div class="course-page__column--sidebar__info-block__image-wrapper">
      <img alt="Calendar" src="../../assets/icons/calendar-blue.svg" />
    </div>
    <div>
      <p
        class="course-page__column--sidebar__info-block__info-label"
        v-text="label"
      />
      <p
        class="course-page__column--sidebar__info-block__info-text"
        v-text="text"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.course-page {
  &__column {
    &--sidebar {
      &__info-block {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 15px;

        &__image-wrapper {
          width: 46px;
          height: 46px;
          background-color: $color-light-grey;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &__info-label {
          color: $color-menu-item;
          font-family: $font-family;
          font-size: $font-size-xs;
          line-height: 12px;
          margin-bottom: 6px;
        }

        &__info-text {
          color: $color-blue;
          font-family: $font-family;
          font-size: $font-size-md;
          line-height: 15px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      hideOthers: this.hideOthers,
      padding: this.padding,
      marginBottom: this.marginBottom,
      Accordion: this.Accordion,
      contentLineHeight: this.contentLineHeight,
      contentFontSize: this.contentFontSize,
    };
  },
  props: {
    marginBottom: {
      type: Number,
      default: () => {
        return 27;
      },
    },
    hideOthers: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    contentLineHeight: {
      type: Number,
      default: () => {
        return 30;
      },
    },
    contentFontSize: {
      type: Number,
      default: () => {
        return 14;
      },
    },
  },
  data() {
    return {
      Accordion: {
        list: [],
        count: 0,
        active: null,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  padding: 0 !important;
  margin-bottom: 50px;
}
</style>

import { useGlobalStore } from "@/store";
import i18n from "@/i18n";

export function fixCopyright(value) {
  return `${value}`.replace("®", "<sup>®</sup>");
}

export function getCurrentLanguageFilter() {
  const filterGroup = useGlobalStore().filters.find((f) => {
    return (
      parseInt(f.filter_group_id) ===
      parseInt(process.env.VUE_APP_FILTER_GROUP_LANG || 7)
    );
  });

  let filter = null;
  if (filterGroup && i18n.global.locale) {
    filter = filterGroup.filter.find((f) => {
      return f.name.toLowerCase() === i18n.global.locale;
    });
  }

  return { filterGroup, filter };
}

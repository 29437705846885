<template>
  <a
    :href="youtubeHref"
    class="home-slider__youtube-toggle"
    @click.prevent="open"
  >
    <img src="../../assets/video.svg" />
    <span v-dompurify-html="label" />
  </a>
  <Teleport to="body">
    <div v-if="isOpen" class="home-slider__youtube">
      <button type="button" class="home-slider__youtube-close" @click="close">
        Close
      </button>
      <div class="home-slider__youtube-video">
        <iframe
          :src="href"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
      <a
        :href="youtubeHref"
        target="_blank"
        class="home-slider__youtube-content"
      >
        To watch on <img src="../../assets/youtube.svg" />
      </a>
    </div>
  </Teleport>
</template>

<script setup>
import { computed, defineProps, onBeforeUnmount, ref, watch } from "vue";

const props = defineProps({
  href: String,
  label: String,
});

const isOpen = ref(false);
const youtubeHref = computed(() => {
  const id = props.href.split("/").at(-1).split("?")[0];
  return `https://www.youtube.com/watch?v=${id}`;
});

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

watch(
  () => isOpen.value,
  (value) => {
    const classList = document.querySelector("#app").classList;
    if (value) {
      classList.add("home-slider--body-hidden");
    } else {
      classList.remove("home-slider--body-hidden");
    }
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  document.querySelector("#app").classList.remove("home-slider--body-hidden");
});
</script>

<style lang="scss">
.home-slider--body-hidden {
  overflow: hidden;
  height: 1px !important;
}
</style>

<style lang="scss" scoped>
.home-slider__youtube {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $color-white;
  z-index: $z-index-modal;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $font-family;
  padding-left: 10px;
  padding-right: 10px;

  &-toggle {
    color: $color-primary;
    background-color: $color-white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-md;
    font-weight: 500;
    white-space: pre;
    overflow: hidden;
    box-shadow: inset 0 -1px 0px #e7e8ef;

    & > img {
      padding-right: 16px;
    }
  }

  &-close {
    align-self: flex-end;
    border: none;
    background: transparent;
    padding: 17px 20px;
    margin-bottom: -50px;
    margin-right: -10px;
  }

  &-video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin-top: auto;
    margin-bottom: 60px;

    & > iframe {
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    margin-bottom: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    text-decoration: none;
    font-size: $font-size-lg;
    color: $color-blue;
  }
}
</style>

<template>
  <div class="courses-list">
    <div class="courses-list__header">
      <h3 class="courses-list__header-title">
        <slot name="header"></slot>
      </h3>
    </div>

    <div class="courses-list__wrap">
      <slot name="body"></slot>
      <div class="courses-list__wrap-show-all">
        <router-link
          :to="{ name: 'discover', query: { sort: 'popular' } }"
          href="#"
        >
          {{ $t("course.show-more") }}
          <img src="@/assets/arrow-long-right-blue.svg" alt="arrow" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const onSwiper = () => {};

    const onSlideChange = () => {};

    return {
      onSwiper,
      onSlideChange,
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.courses-list {
  margin-bottom: 66px;
  margin-top: 49px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__header-title {
    color: $color-blue;
    font-family: $font-family;
    font-size: $header-h3-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    width: 100%;
  }

  &__wrap {
    // display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 11px;

    &-show-all {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-top: 40px;

      @media screen and (max-width: $breakpoint-lg) {
        justify-content: center;
      }

      a {
        color: $color-primary;
        font-family: $font-family;
        font-size: $font-size-sm;
        letter-spacing: 0;
        line-height: 23px;
        text-decoration: none;
        display: flex;
        align-items: center;
        column-gap: 18px;
      }
    }
  }
}
</style>

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import backService from "@/plugins/service";

export const useOrganisationStore = defineStore("organisation", () => {
  const organisations = ref([]);
  const positions = ref([]);

  const getOrganisations = async (search) => {
    try {
      const { data } = await backService.get("/organisations", {
        params: { search },
      });

      organisations.value = data.data;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const getPositions = async (search) => {
    try {
      const { data } = await backService.get("/positions", {
        params: { search },
      });

      positions.value = data.data;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  return {
    organisations: computed(() => organisations.value),
    positions: computed(() => positions.value),
    getOrganisations,
    getPositions,
  };
});

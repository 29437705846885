<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :show-navigation="true"
    :show-header-block="true"
    :title="$t('common.purchases_history')"
  >
    <div class="container">
      <div class="courses" :class="{ courses_mobile: isMobileShop }">
        <Loading v-if="loading" />
        <div>
          <div v-if="orders.length !== 0" class="courses__wrap">
            <router-link
              v-for="order in orders"
              :key="order.order_id"
              :to="{
                name: 'dashboard.purchases.show',
                params: { id: order.order_id },
              }"
            >
              <div class="course">
                <div class="course__content">
                  <div class="course__content-left">
                    <div class="course__id">ID: {{ order.order_id }}</div>
                    <div class="course__name">
                      <div
                        v-for="product in order.products"
                        :key="product.product_id"
                        class="course__item"
                      >
                        {{ product.name }} {{ product.quantity }} x
                        {{ product.price }} €
                      </div>
                    </div>
                  </div>
                  <div class="course__content-right">
                    <div class="course__price">{{ order.total }}</div>
                    <div class="course__date">{{ order.date_added }}</div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div v-else class="course__wrap">
            <div class="course__error">
              {{ $t("common.purchases_history_empty") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import Loading from "@/components/Loading.vue";
import axios from "axios";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { computed, ref } from "vue";
import { isShoppingEnabled } from "@/util/helpers";
import useIsMobile from "@/util/useIsMobile";

const { isMobile } = useIsMobile();
const loading = ref(false);
const orders = ref([]);

const isMobileShop = computed(() => isMobile.value && isShoppingEnabled());

const getPurchasedCourses = async () => {
  try {
    loading.value = true;
    const response = await axios.get("/customerorders");
    orders.value = response.data.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
  } finally {
    loading.value = false;
  }
};

getPurchasedCourses();
</script>

<style lang="scss" scoped>
.courses {
  padding-top: 60px;
  padding-bottom: 80px;

  &_mobile {
    padding-top: 10px;
  }

  &__wrap {
    overflow-x: auto;

    a {
      text-decoration: none;
    }
  }
}

.course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-light-grey;
  text-decoration: none;
  min-width: 800px;
  max-width: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    min-width: unset;
  }

  &:last-child {
    border-bottom: 1px solid $color-light-grey;
  }

  &__error {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    line-height: 12px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    padding: 28px 20px;
    gap: 15px;

    &-left,
    &-right {
      display: flex;
      align-items: center;

      @media screen and (max-width: $breakpoint-lg) {
        flex-direction: column;
      }
    }

    &-left {
      @media screen and (max-width: $breakpoint-lg) {
        align-items: start;
      }
    }

    &-right {
      @media screen and (max-width: $breakpoint-lg) {
        flex-direction: column-reverse;
        align-items: end;
      }
    }

    &-left {
      gap: 16px 56px;
    }

    &-right {
      gap: 16px 64px;
    }
  }

  &__id {
    color: $color-primary;
    font: 400 $font-size-md $font-family;
    line-height: 21px;
  }

  &__name {
    color: $color-primary;
    font: 400 $font-size-md $font-family;
    line-height: 18px;
  }

  &__date {
    color: $color-menu-item;
    font: 400 $font-size-md $font-family;
    line-height: 12px;
  }

  &__price {
    color: $color-primary;
    font: 500 $font-size-md $font-family;
    line-height: 12px;
  }
}
</style>

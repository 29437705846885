<template>
  <div class="threads">
    <div class="threads__search">
      <img
        class="threads__search-image"
        src="../../assets/menu/search-black.svg"
      />
      <input
        v-model="search"
        class="threads__search-input"
        name=""
        placeholder="Paieška"
        @input="onSearch"
      />
    </div>
    <div class="threads__wrap">
      <MessageThreadItem
        v-for="thread in threads"
        :key="thread"
        :thread="thread"
        @click.prevent="selectThread(thread)"
      />
    </div>
  </div>
</template>
<script>
import MessageThreadItem from "@/components/message/MessageThreadItem.vue";

export default {
  components: {
    MessageThreadItem,
  },
  data() {
    return {
      search: "",
      threads: [
        {
          lastMessage: "Tu: Certe, inquam, pertinax non fuisse.",
          users: [
            {
              name: "Robis Kanyinda",
            },
          ],
        },
        {
          lastMessage: "Tu: Certe, inquam, pertinax non fuisse.",
          users: [
            {
              name: "Gladys Kanyinda",
            },
            {
              name: "Robertas Kanyinda",
            },
            {
              name: "Petras Kanyinda",
            },
          ],
        },
        {
          lastMessage: "Tu: Certe, inquam, pertinax non fuisse.",
          users: [
            {
              name: "Tomas Kanyinda",
            },
          ],
        },
      ],
    };
  },
  methods: {
    selectThread(thread) {
      this.$emit("thread", thread);
    },
    onSearch(val) {
      let vm = this;
    },
  },
};
</script>
<style lang="scss" scoped>
$header-height: 90px;
$messenger-header-height: 110px;
$messenger-thread-search-height: 100px;

$thread-height: $header-height + $messenger-header-height +
  $messenger-thread-search-height;

.threads {
  // max-height: 100vh;

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 0px 0px 0px;

    overflow-y: scroll;
    height: calc(100vh - $thread-height);
  }

  &__search {
    padding: 20px 20px 20px 0px;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__search-image {
    position: absolute;
    left: 19px;
    z-index: 9999;
  }

  &__search-input {
    padding: 20px 50px;
    width: 100%;
    border: 1px solid #dcdee9;
    border-radius: 4px;
    background-color: #ffffff;
    line-height: 18px;
  }
}
</style>

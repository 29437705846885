<template>
  <iframe v-if="logoutLink" v-show="false" :src="logoutLink" />
</template>
<script setup>
import { useAuth2Store } from "@/store/modules/auth2.store";
import { ref, watch } from "vue";

const auth2Store = useAuth2Store();

const logoutLink = ref(null);

watch(
  () => auth2Store.logoutUrl,
  (logoutUrl) => {
    if (!logoutUrl) {
      return;
    }
    logoutLink.value = logoutUrl;
    auth2Store.unsetLogoutUrl();
  },
);
</script>

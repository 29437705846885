<template>
  <DashboardLayout
    :is-mobile-shop="isMobileShop"
    :show-navigation="true"
    :show-header-block="true"
    :title="$t('common.my_certificates')"
  >
    <div>
      <div class="container">
        <div class="certificates">
          <div v-show="!isSearchEnabled" class="certificates__header">
            <div class="certificates__header-column">
              <div class="certificates__settings">
                <div v-if="!isMobile" class="certificates__settings-label">
                  <SwitchField
                    :input-value="valid"
                    @change="validCertificates()"
                  />
                  <span
                    class="certificates__settings__valid-certificates"
                    v-text="$t('common.show_valid_certificates')"
                  />
                </div>
                <div class="certificates__settings-label">
                  <button @click="enableSearch">
                    <img
                      src="../../../assets/menu/search-grey.svg"
                      alt="search"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SearchForm
            v-show="isSearchEnabled"
            :placeholder="$t('navigation.search_certificates_placeholder')"
            class="certificates__header"
            @search="search"
            @disable:search="cancel"
          />
          <div v-if="isMobile" class="valid-certificates-wrapper">
            <span
              class="certificates__settings__valid-certificates"
              v-text="$t('common.show_valid_certificates')"
            />
            <SwitchField @change="validCertificates()" />
          </div>
          <div v-if="filtered.length" class="certificates__wrap">
            <Loading v-if="loading" />
            <CertificateItem
              v-for="certificate in filtered"
              :key="certificate.id"
              :certificate="certificate"
            />
          </div>
          <NoResults
            v-else
            :image="CertificateSvg"
            :title="$t('certificates.empty_result_title')"
            :description="$t('certificates.empty_result_description')"
            :back-button-text="$t('certificates.empty_result_redirect_text')"
            :back-button-route="ROUTE.Dashboard.Courses"
          />
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import CertificateItem from "@/components/dashboard/CertificateItem";
import SwitchField from "@/components/form/SwitchField";
import Loading from "@/components/Loading";
import backService from "@/plugins/service";
import moment from "moment";
import { computed, onMounted, ref, watch } from "vue";
import SearchForm from "@/components/search/SearchForm.vue";
import useIsMobile from "@/util/useIsMobile";
import NoResults from "@/components/NoResults.vue";
import CertificateSvg from "@/assets/no-results/certificate.svg";
import { ROUTE } from "@/router/routenames";
import DashboardLayout from "@/views/pages/dashboard/DashboardLayout.vue";
import { isShoppingEnabled } from "@/util/helpers";

const STORAGE_SHOW_JUST_VALID_CERTIFICATES = "SHOW_JUST_VALID_CERTIFICATES";

const isSearchEnabled = ref(false);
const { isMobile } = useIsMobile();
const valid = ref(
  localStorage.getItem(STORAGE_SHOW_JUST_VALID_CERTIFICATES) === "true",
);
const loading = ref(false);
const certificates = ref([]);
const filtered = ref([]);
const searchString = ref("");

const isMobileShop = computed(() => isMobile.value && isShoppingEnabled());

const enableSearch = () => {
  isSearchEnabled.value = true;
};
const search = (value) => {
  searchString.value = value;
};
const cancel = () => {
  searchString.value = "";
  isSearchEnabled.value = false;
};
const validCertificates = () => {
  valid.value = !valid.value;
  localStorage.setItem(STORAGE_SHOW_JUST_VALID_CERTIFICATES, valid.value);

  if (valid.value) {
    filterValidCertificates();
  } else {
    filtered.value = certificates.value;
  }
};
const filterValidCertificates = () => {
  filtered.value = filtered.value.filter((certificate) => {
    const endDate = moment(certificate.valid.to),
      todayDate = moment();

    const difference = todayDate.diff(endDate, "days");
    return difference <= 0;
  });
};
const getSertificates = async () => {
  loading.value = true;
  try {
    const response = await backService.get("/certificates");
    loading.value = false;
    certificates.value = response.data;
    filtered.value = response.data;
  } catch (error) {
    loading.value = false;
  }
};

onMounted(() => getSertificates());

watch(
  () => searchString.value,
  async (newSearchString) => {
    filtered.value = certificates.value.filter((certificate) => {
      return certificate.title
        .toLowerCase()
        .includes(newSearchString.toLowerCase());
    });
    if (valid.value) {
      filterValidCertificates();
    }
  },
  { immediate: true },
);
watch(
  () => filtered.value.length,
  () => {
    if (valid.value) {
      filterValidCertificates();
    }
  },
);
watch(
  () => isMobileShop.value,
  (newValue) => {
    isSearchEnabled.value = newValue;
  },
);
</script>

<style lang="scss" scoped>
.certificates {
  padding-top: 37px;
  padding-bottom: 80px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    width: 100%;

    @media only screen and (max-width: $breakpoint-lg) {
      margin-bottom: 20px;
    }

    :deep(.search-wrap) {
      width: 100%;
    }

    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 33px;
  }

  &__settings-label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    display: flex;
    align-items: center;

    button {
      background: unset;
      border: unset;
    }
  }

  &__settings__valid-certificates {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xxxs;
    letter-spacing: 0;
    line-height: 23px;
    padding-left: 15px;
  }

  &__column {
    display: flex;
    align-items: center;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &--switch {
      gap: 40px;
      @media screen and (max-width: 900px) {
        gap: 10px;
      }
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 18px;
    row-gap: 50px;

    @media screen and (max-width: $breakpoint-xl) {
      grid-template-columns: auto auto auto;
    }

    @media screen and (max-width: $breakpoint-lg) {
      grid-template-columns: auto auto;
    }
  }
}

.valid-certificates-wrapper {
  text-align: right;
  padding-bottom: 20px;

  span {
    padding-right: 20px;
  }
}
</style>

<template>
  <a
    class="navbar__link navbar__link--active dropdown-toggle"
    href="#"
    @click.prevent
  >
    {{ getCurrentLanguageCode() }}
  </a>
</template>

<script setup>
import i18n from "@/i18n";

const getCurrentLanguageCode = () => i18n.global.locale;
</script>

<style scoped lang="scss">
.dropdown-toggle {
  text-transform: uppercase;
  display: inline-flex;
  justify-items: center;
  align-items: center;
  column-gap: 10px;
  margin-right: 10px;

  &:after {
    background: url("@/assets/menu/arrow-down-blue.svg");
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px;
  }
}
</style>

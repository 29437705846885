<template>
  <div class="home-slider__slide">
    <div
      class="home-slider__slide-image"
      :style="`background-image: url('${slide.image_original}')`"
    >
      <div class="home-slider__slide-content container">
        <h3
          v-dompurify-html="slide.title"
          class="home-slider__slide-title"
          :class="{
            'home-slider--light': lightOrDark === 'light',
            'home-slider--dark': lightOrDark === 'dark',
          }"
        />
        <div class="home-slider__slide-buttons">
          <a
            v-if="slide.link"
            :href="slide.link"
            class="home-slider__slide-primary"
          >
            {{ slide.link_text }}
          </a>
          <SlidernewDesktopYoutube
            v-if="slide.link2"
            :href="slide.link2"
            :label="slide.link_text2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch } from "vue";
import SlidernewDesktopYoutube from "./SlidernewDesktopYoutube.vue";
import { useHeaderStore } from "@/store/modules/header.store";
import { storeToRefs } from "pinia";

const props = defineProps({
  slide: Object,
});

const headerStore = useHeaderStore();
const { checkLightOrDark } = headerStore;
const { lightOrDark } = storeToRefs(headerStore);

watch(
  () => props.slide.image_original,
  async () => checkLightOrDark({ image: props.slide.image_original }),
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.home-slider {
  &__slide {
    font-family: $font-family;
    width: 100%;
    height: 576px;
    display: flex;
    flex-direction: column;
  }

  &__slide-buttons {
    display: flex;
    gap: 40px;
    margin-bottom: 35px;
  }

  &__slide-primary {
    min-width: 150px;
    color: $color-white;
    background-color: $color-primary;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-md;
    font-weight: 500;
    white-space: pre;
    overflow: hidden;
    padding: 28px 50px;
    border: 1px solid $color-primary;
  }

  &__slide-image {
    flex-grow: 1;
    background-position: center right;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
  }

  &__slide-title {
    font-size: $font-size-xxxl;
    line-height: 52px;
    text-align: left;
    color: $color-primary;
    font-weight: 700;
    width: 100%;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  &--light {
    color: $color-primary;
  }

  &--dark {
    color: $color-white;
  }
}
</style>

<template>
  <div :class="['info-block', { mobile: isMobile }]">
    <div class="info-block__content">
      <div
        v-if="
          course.products &&
          course.products.length === 1 &&
          course.products[0].course_duration &&
          !isMobile
        "
        class="info-block__block"
      >
        <div class="info-block__image">
          <img src="../../assets/course/clock.svg" />
        </div>
        <div class="info-block__block-content">
          <div class="info-block__block-content-header">
            {{ course.products[0].course_duration }} {{ $t("common.hour") }}
          </div>
          <div class="info-block__block-desc">
            {{ $t("common.course_duration") }}
          </div>
        </div>
      </div>
      <div
        v-if="course.products && course.products.length === 1"
        class="info-block__block"
      >
        <div class="info-block__image">
          <img src="../../assets/course/calendar-day.svg" />
        </div>
        <div class="info-block__block-content">
          <div class="info-block__block-content-header">
            {{ $t("common.course_start") }}
          </div>
          <div class="info-block__block-desc">
            {{ getParsedDate(course.products[0].start_date) }}
          </div>
        </div>
      </div>
      <div class="info-block__block">
        <div class="info-block__image">
          <img src="../../assets/course/globe-americas.svg" />
        </div>
        <div class="info-block__block-content">
          <div class="info-block__block-content-header">
            {{ $t("common.practice") }}
          </div>
          <div class="info-block__block-desc">
            {{ $t("common.in_the_sim_room") }}
          </div>
        </div>
      </div>
      <div
        v-if="course.certificate && isShoppingEnabled()"
        class="info-block__block"
      >
        <div class="info-block__image">
          <img src="../../assets/course/file-certificate.svg" />
        </div>
        <div class="info-block__block-content">
          <div class="info-block__block-content-header">
            {{ $t("common.certificate") }}
          </div>
          <div class="info-block__block-desc">
            {{ $t("common.recieve_certificate") }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="award in course.awards"
      :key="award.title"
      class="info-block__footer"
    >
      <div class="info-block__block">
        <img :src="award.image" />
        <div class="info-block__block-content">
          <div class="info-block__block-content-header">
            {{ award.title }}
          </div>
          <div class="info-block__block-desc">
            {{ award.subtitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import useIsMobile from "@/util/useIsMobile";
import { isShoppingEnabled } from "@/util/helpers";

defineProps({
  course: {
    type: Object,
    default: () => {
      return {
        certificate: true,
      };
    },
  },
});

const { isMobile } = useIsMobile();

const getParsedDate = (date) =>
  moment(date).locale(this.$root.$i18n.locale).format("LL");
</script>

<style lang="scss" scoped>
.info-block {
  border: 1px solid $color-grey-border;
  display: flex;
  flex-direction: column;

  &.mobile {
    border-color: transparent;
  }

  &__image {
    height: 48px;
    width: 48px;
    background-color: $color-light-blue;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 25px 20px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__block-desc {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__block-content-header {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  }

  &__footer {
    padding: 25px;
    background-color: $color-light-blue;
  }
}
</style>

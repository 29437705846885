import backService from "@/plugins/service";
import { defineStore } from "pinia";
import defaultImage from "@/assets/picture.png";
import { readFileDataURL, restoreLocalStorageJSON } from "@/plugins/misc";
import { useAlertStore } from "./alert.store";
import { useAccountStore } from "@/store/modules/account.store";
import { computed, reactive, watch } from "vue";
import { useLocaleStore } from "@/store/modules/locale.store";

export const useSettingsStore = defineStore("settings", () => {
  const LOCAL_STORAGE_KEY = "settings";

  const alertStore = useAlertStore();
  const accountStore = useAccountStore();
  const localeStore = useLocaleStore();

  const state = reactive({
    loading: false,
    error: null,
    data: restoreLocalStorageJSON(LOCAL_STORAGE_KEY),
    update: null,
  });

  const values = computed(() => ({
    image: defaultImage,
    organisation_id: null,
    organisation: null,
    position_id: null,
    position: null,
    timezone: "",
    country: "",
    city: "",
    gender: "male",
    date_of_birth: "",
    iframe_url: null,
    logout_iframe_url: null,
    learning_system_url: "",
    learning_system_url_text: "",
    language: "",
    ...(state.data || {}),
  }));

  const reset = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    state.data = null;
  };

  const request = async () => {
    state.loading = true;
    try {
      const { data } = await backService.get("/settings");
      state.loading = false;
      state.error = null;
      state.data = data;
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));

      return true;
    } catch (error) {
      state.loading = false;
      state.error = error;
      return false;
    }
  };

  const updateSettings = async (values) => {
    state.loading = true;

    try {
      const { data } = await backService.post("/settings", values);
      state.update = data;
      accountStore.setNotSetup(null);
      alertStore.success("Pavyko", data.message);

      return request();
    } catch (error) {
      state.loading = false;
      state.error = error;
      return false;
    }
  };

  const loadImage = async (image) => {
    state.loading = true;

    try {
      const data = await readFileDataURL(image);
      state.loading = false;
      state.error = null;
      state.data = { ...(state.data || values.value), image: data };

      return true;
    } catch (error) {
      state.loading = false;
      state.error = error;
      return false;
    }
  };

  const updateImage = async (image) => {
    state.loading = true;

    try {
      const formData = new FormData();
      formData.append("image", image);
      const { data } = await backService.post("/settings/image", formData);
      state.update = data;
      alertStore.success("Pavyko", data.message);

      return loadImage(image);
    } catch (error) {
      state.loading = false;
      state.error = error;
      return false;
    }
  };

  watch(
    () => values.value.language,
    async (lang) => await localeStore.update(lang),
  );

  return {
    ...state,
    values,
    reset,
    request,
    updateSettings,
    loadImage,
    updateImage,
  };
});

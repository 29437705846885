<template>
  <div class="company-types">
    <div class="container">
      <h3 v-dompurify-html="content.title"></h3>
      <p v-dompurify-html="content.content"></p>
      <div class="company-types__wrap">
        <swiper
          :slides-per-view="4"
          :space-between="50"
          :loop="true"
          :breakpoints="swiperOptions.breakpoints"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="page in informations" :key="page.id">
            <div class="company-types__block">
              <img :src="page.image" alt="" />

              <div class="company-types__content">
                <h4 v-dompurify-html="page.title"></h4>
                <p v-dompurify-html="page.description"></p>
                <router-link :to="{ name: 'page', params: { id: page.id } }">
                  {{ $t("common.read_more") }}
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    content: Object,
  },
  setup() {
    const onSwiper = (swiper) => {
      //
    };

    const onSlideChange = () => {};

    return {
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      loading: false,
      informations: [],
      swiperOptions: {
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    };
  },
  created() {
    this.getInformationPages();
  },
  methods: {
    async getInformationPages() {
      let vm = this;
      this.loading = true;
      await axios
        .get("/information?bottom=true")
        .then((response) => {
          vm.informations = response.data.data;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-types {
  margin-top: 20px;
  margin-bottom: 50px;

  h3 {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-slg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 5px;
  }

  p {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }

  &__wrap {
    margin-top: 60px;

    display: flex;
    align-items: center;
    gap: 11px;
  }

  &__block {
    flex-basis: 100%;

    img {
      width: 100%;
    }

    border: 1px solid #dcdee9;
    background-color: #ffffff;

    text-align: center;

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
    }

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      font-weight: 100;

      margin-bottom: 10px;
    }

    p {
      opacity: 0.5;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;

      margin-bottom: 30px;
    }
  }

  &__content {
    padding: 17px;
  }
}
</style>

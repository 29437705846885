import { useCartStore, useGlobalStore } from "@/store/index";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useAccountStore } from "@/store/modules/account.store";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { usePopupStore } from "@/store/modules/popup.store";
import { useDiscountStore } from "@/store/modules/discount.store";
import { useHeaderStore } from "@/store/modules/header.store";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { useProductsStore } from "@/store/modules/products.store";
import { usePageStore } from "@/store/modules/page.store";
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";

export function useStores() {
  return {
    auth2Store: useAuth2Store(),
    cartStore: useCartStore(),
    globalStore: useGlobalStore(),
    settingsStore: useSettingsStore(),
    accountStore: useAccountStore(),
    popupStore: usePopupStore(),
    discountStore: useDiscountStore(),
    headerStore: useHeaderStore(),
    purchasedStore: getPurchasedStore(),
    productsStore: useProductsStore(),
    pagesStore: usePageStore(),
    favouriteCoursesStore: useFavouriteCourses(),
  };
}

import backService from "@/plugins/service";
import { fixCopyright } from "@/util/filters";
import axios from "axios";
import { defineStore } from "pinia";

export const usePageStore = defineStore("pageStore", () => {
  const request = async (id) => {
    const { data: response } = await axios.get(`/information/${id}`);
    const data = response.data;
    data.title = fixCopyright(data.title);

    return data;
  };

  const book = async (name) => {
    const { data } = await backService.get(`page/${name}`);

    return data;
  };

  return { request, book };
});

<template>
  <CoursePage :course="course">
    <template #content>
      <div id="subjects" class="course-page__section">
        <h3 class="course-page__section-header">
          {{ $t("course.subjects") }}
        </h3>

        <div class="course-page__section-content">
          <accordion :padding="34">
            <accordion-item
              v-for="subject in course.courseSubjects"
              :key="subject.id"
            >
              <template #accordion-trigger="{ isOpen }">
                <div class="course-page__section-accordion-trigger">
                  <h3 v-text="subject.title" />
                  <img :src="isOpen ? MinusBlueIcon : PlusBlueIcon" alt="svg" />
                </div>
              </template>
              <template #accordion-content>
                <div v-html="subject.description"></div>
              </template>
            </accordion-item>
          </accordion>
        </div>
      </div>
    </template>
  </CoursePage>
</template>
<script setup>
import CoursePage from "@/views/pages/CoursePage.vue";
import { computed, onMounted } from "vue";
import { useProductsStore } from "@/store/modules/products.store";
import Accordion from "@/components/accordion/Accordion.vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import { useRoute } from "vue-router";
import useIsMobile from "@/util/useIsMobile";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";

const route = useRoute();
const { checkWidth } = useIsMobile();

const productsStore = useProductsStore();

const course = computed(() => {
  return productsStore.current || {};
});

onMounted(() => {
  productsStore.requestCurrent(route.params.id);
  checkWidth();
});
</script>

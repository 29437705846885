import { defineStore } from "pinia";
import { ref } from "vue";

export const usePopupStore = defineStore("popup", () => {
  const menu = ref(false);
  const menuSettings = ref(false);

  const openMenu = () => {
    menuSettings.value = false;
    menu.value = true;
  };
  const openMenuSettings = () => {
    menu.value = false;
    menuSettings.value = true;
  };

  const closeMenu = () => {
    menu.value = false;
    menuSettings.value = false;
  };

  return {
    menu,
    menuSettings,

    closeMenu,
    openMenu,
    openMenuSettings,
  };
});

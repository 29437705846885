<template>
  <svg
    width="354"
    height="354"
    viewBox="0 0 354 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 42C0.5 19.0802 19.0802 0.5 42 0.5H312C334.92 0.5 353.5 19.0802 353.5 42V312C353.5 334.92 334.92 353.5 312 353.5H42C19.0802 353.5 0.5 334.92 0.5 312V42Z"
      stroke="#171F69"
      stroke-opacity="0.1"
    />
    <path
      class="download-icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M177 305.273C177.603 305.273 178.091 305.761 178.091 306.364L178.091 316.821L182.047 312.865C182.473 312.439 183.164 312.439 183.59 312.865C184.016 313.291 184.016 313.982 183.59 314.408L177.772 320.226C177.346 320.652 176.655 320.652 176.229 320.226L170.411 314.408C169.985 313.982 169.984 313.291 170.411 312.865C170.837 312.439 171.527 312.439 171.953 312.865L175.909 316.821L175.909 306.364C175.909 305.761 176.398 305.273 177 305.273Z"
      fill="#64748B"
    />
    <path
      class="download-icon"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M166.818 320.545C165.814 320.545 165 321.359 165 322.363V326.727C165 327.329 164.512 327.818 163.909 327.818C163.307 327.818 162.818 327.329 162.818 326.727V322.363C162.818 320.154 164.609 318.363 166.818 318.363H170.454C171.057 318.363 171.545 318.852 171.545 319.454C171.545 320.057 171.057 320.545 170.454 320.545H166.818ZM182.454 319.454C182.454 318.852 182.943 318.363 183.545 318.363H187.182C189.391 318.363 191.182 320.154 191.182 322.363V326.727C191.182 327.329 190.693 327.818 190.091 327.818C189.488 327.818 189 327.329 189 326.727V322.363C189 321.359 188.186 320.545 187.182 320.545H183.545C182.943 320.545 182.454 320.057 182.454 319.454Z"
      fill="#64748B"
    />
    <path
      class="download-icon"
      d="M169.727 325.273C169.727 326.076 169.076 326.727 168.273 326.727C167.469 326.727 166.818 326.076 166.818 325.273C166.818 324.47 167.469 323.818 168.273 323.818C169.076 323.818 169.727 324.47 169.727 325.273Z"
      fill="#64748B"
    />
    <path
      class="download-icon"
      d="M174.091 325.273C174.091 326.076 173.44 326.727 172.636 326.727C171.833 326.727 171.182 326.076 171.182 325.273C171.182 324.47 171.833 323.818 172.636 323.818C173.44 323.818 174.091 324.47 174.091 325.273Z"
      fill="#64748B"
    />
    <path
      class="download-icon"
      d="M178.455 325.273C178.455 326.076 177.803 326.727 177 326.727C176.197 326.727 175.545 326.076 175.545 325.273C175.545 324.47 176.197 323.818 177 323.818C177.803 323.818 178.455 324.47 178.455 325.273Z"
      fill="#64748B"
    />
    <path
      d="M177.5 62.7578C237.522 62.7578 286.242 111.478 286.242 171.5C286.242 231.521 237.522 280.242 177.5 280.242C117.479 280.242 68.7581 231.521 68.7581 171.5C68.7581 111.478 117.479 62.7578 177.5 62.7578Z"
      fill="white"
    />
    <path
      d="M177.5 272.708C233.252 272.708 278.708 227.503 278.708 171.5C278.708 115.497 233.252 70.292 177.5 70.292C121.748 70.292 76.292 115.748 76.292 171.5C76.292 227.252 121.748 272.708 177.5 272.708Z"
      fill="#171F69"
    />
    <path
      d="M255.603 249.603C235.512 269.694 207.888 282 177.5 282C147.113 282 119.488 269.694 99.3966 249.603C79.3057 229.512 67 201.888 67 171.5C67 141.113 79.3057 113.488 99.3966 93.3966C119.488 73.3057 147.113 61 177.5 61C207.888 61 235.512 73.3057 255.603 93.3966C275.694 113.488 288 141.113 288 171.5C288 201.888 275.694 229.512 255.603 249.603ZM177.5 278.735C207.134 278.735 234.006 266.681 253.343 247.343C272.681 228.006 284.986 201.134 284.986 171.5C284.986 141.866 272.932 114.994 253.594 95.6568C234.006 76.0682 207.134 64.2648 177.5 64.2648C147.866 64.2648 120.994 76.0682 101.657 95.6568C82.3193 115.245 70.0136 141.866 70.0136 171.5C70.0136 201.134 82.0682 228.006 101.406 247.343C120.994 266.932 147.866 278.735 177.5 278.735Z"
      fill="#171F69"
    />
    <path
      d="M196.838 196.111V136.341H179.007V210.426H244.553V196.111H196.838ZM155.902 164.719H125.766V136.341H107.935V210.426H125.766V179.034H155.651V210.426H173.482V136.341H155.651V164.719H155.902Z"
      fill="white"
    />
    <path
      d="M230.741 129.058H236.517C242.042 129.058 244.553 126.044 244.553 121.021C244.553 115.999 241.54 113.738 236.517 113.738H230.741V129.058ZM232.75 115.496H236.517C240.535 115.496 242.042 117.756 242.042 121.272C242.042 126.797 238.024 127.3 236.266 127.3H232.75V115.496ZM214.166 129.058H216.426V113.738H214.166V129.058ZM189.806 120.77V115.496H195.331C197.34 115.496 198.344 116.501 198.344 118.008C198.344 120.268 196.586 120.77 194.326 120.77H189.806ZM187.546 129.058H189.806V122.528H195.331C197.591 122.528 197.842 123.784 198.093 125.291C198.344 126.797 198.093 128.555 198.596 129.058H201.107C200.102 128.053 200.102 126.295 200.102 124.788C199.851 123.281 199.6 122.026 197.591 121.524C199.6 121.021 200.605 119.514 200.605 117.756C200.605 115.245 198.596 113.738 195.582 113.738H187.546V129.058ZM163.185 122.026H168.208C170.217 122.026 171.975 122.528 171.975 124.537C171.975 126.295 170.719 127.3 168.71 127.3H163.185V122.026ZM161.176 129.058H169.213C172.477 129.058 174.235 126.797 174.235 124.788C174.235 122.779 172.98 121.524 170.971 121.021C172.477 120.519 173.482 119.263 173.482 117.505C173.482 115.999 172.477 114.994 171.473 114.241C170.468 113.738 168.459 113.738 167.203 113.738H161.176V129.058ZM163.185 115.496H167.706C169.715 115.496 171.222 115.747 171.222 118.008C171.222 119.766 170.217 120.519 167.706 120.519H163.185V115.496ZM107.935 129.058H110.196V122.026H118.985V129.058H121.246V113.738H118.985V120.268H110.196V113.738H107.935V129.058Z"
      fill="white"
    />
    <path
      d="M232.75 225.745H238.275C240.284 225.745 242.293 226.248 242.293 228.257C242.293 229.764 241.037 230.768 238.777 230.768H232.75V225.745ZM230.239 232.526H239.028C242.544 232.526 244.553 230.517 244.553 228.257C244.553 226.499 243.298 224.992 241.037 224.741C242.795 224.239 243.8 222.983 243.8 221.476C243.8 219.969 242.795 218.965 241.54 218.462C240.535 217.96 238.275 217.96 236.768 217.96H230.239V232.526ZM232.75 219.467H237.522C239.531 219.467 241.289 219.718 241.289 221.727C241.289 223.234 240.284 223.987 237.522 223.987H232.75V219.467ZM201.609 232.526H204.12L206.129 228.006H213.664L215.673 232.526H218.435L211.403 217.96H208.641L201.609 232.526ZM209.897 219.718L212.91 226.499H206.883L209.897 219.718ZM179.509 232.526H191.564V230.768H182.02V217.709H179.509V232.526Z"
      fill="white"
    />
    <path
      d="M141.336 113.738C143.094 113.738 144.35 114.994 144.35 116.752C144.35 118.51 143.094 119.766 141.336 119.766C139.578 119.766 138.323 118.51 138.323 116.752C138.574 114.994 139.83 113.738 141.336 113.738Z"
      fill="#E63642"
    />
    <path
      d="M132.798 114.492H134.556C135.309 118.008 137.318 120.017 140.834 120.519H142.09C145.355 120.017 147.866 118.008 148.368 114.492H150.126C149.373 118.762 146.61 121.524 142.341 122.277V128.807C147.113 129.56 149.373 132.323 150.377 136.592H148.619C147.866 132.825 145.606 130.816 142.09 130.565H141.085C137.318 131.067 135.309 133.327 134.556 136.592H132.798C133.551 132.072 136.314 129.56 140.583 128.807V122.026C136.314 121.524 133.551 118.762 132.798 114.492Z"
      fill="white"
    />
    <g clip-path="url(#clip0_1612_10745)">
      <path
        d="M272.051 256C273.576 256 275 256.409 276.424 257.125C277.847 257.841 278.966 259.068 279.78 260.398C280.593 261.83 281 263.364 281 264.898C281 266.432 280.593 267.966 279.78 269.398C278.966 270.83 277.847 271.955 276.525 272.773C275.102 273.591 273.576 274 272.051 274C270.525 274 269 273.591 267.576 272.773C266.153 271.955 265.034 270.83 264.22 269.398C263.407 267.966 263 266.432 263 264.898C263 263.364 263.407 261.83 264.22 260.398C265.034 258.966 266.153 257.841 267.576 257.125C269.102 256.409 270.627 256 272.051 256ZM272.051 257.432C270.831 257.432 269.61 257.739 268.39 258.455C267.169 259.17 266.254 259.989 265.542 261.216C264.831 262.443 264.525 263.67 264.525 265C264.525 266.33 264.831 267.557 265.542 268.784C266.254 270.011 267.169 270.932 268.288 271.545C269.508 272.261 270.729 272.568 272.051 272.568C273.373 272.568 274.593 272.261 275.814 271.545C277.034 270.83 277.949 269.909 278.559 268.784C279.271 267.557 279.576 266.33 279.576 265C279.576 263.67 279.271 262.443 278.559 261.216C277.847 259.989 276.932 259.068 275.712 258.455C274.593 257.841 273.373 257.432 272.051 257.432ZM268.186 270.011V260.295H271.441C272.559 260.295 273.373 260.398 273.881 260.602C274.39 260.807 274.797 261.114 275.102 261.523C275.407 261.932 275.508 262.443 275.508 262.955C275.508 263.67 275.305 264.284 274.797 264.795C274.288 265.307 273.678 265.614 272.763 265.716C273.068 265.818 273.373 266.023 273.576 266.227C273.983 266.636 274.39 267.25 275 268.17L276.119 270.114H274.186L273.373 268.58C272.661 267.352 272.153 266.636 271.746 266.33C271.441 266.125 271.034 266.023 270.525 266.023H269.61V270.114H268.186V270.011ZM269.712 264.489H271.644C272.559 264.489 273.169 264.386 273.475 264.08C273.78 263.773 273.983 263.466 273.983 262.955C273.983 262.648 273.881 262.443 273.78 262.136C273.576 261.932 273.373 261.727 273.068 261.625C272.763 261.523 272.254 261.42 271.542 261.42H269.814L269.712 264.489Z"
        fill="#252E67"
      />
    </g>
    <defs>
      <clipPath id="clip0_1612_10745">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(263 256)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup></script>

<style scoped lang="scss"></style>

<template>
  <div class="home">
    <CovidAlert />
    <Navigation :is-static="false" />
    <SliderNew />
    <Advantages v-if="chapters.length" :content="chapters[0]" />
    <CoursesByCategories v-if="isShoppingEnabled()" />
    <PopularCourses v-if="isShoppingEnabled()" />
    <Clients v-if="chapters.length > 1" :content="chapters[1]" />
    <ContactUs v-if="isShoppingEnabled()" />
    <Footer />
  </div>
</template>

<script setup>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import ContactUs from "@/components/ContactUs.vue";
import Advantages from "@/components/home/Advantages.vue";
import CoursesByCategories from "@/components/home/CoursesByCategories.vue";
import Clients from "@/components/home/Clients.vue";
import CovidAlert from "@/components/CovidAlert.vue";
import PopularCourses from "@/components/course/PopularCourses.vue";
import { onBeforeMount, ref, watch } from "vue";
import { usePageStore } from "@/store/modules/page.store";
import i18n from "@/i18n";
import SliderNew from "@/components/home/SliderNew.vue";
import { isShoppingEnabled } from "@/util/helpers";
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";

const pagesStore = usePageStore();
const favouriteCoursesStore = useFavouriteCourses();

const chapters = ref([]);

onBeforeMount(() => favouriteCoursesStore.request());

watch(
  () => i18n.global.locale,
  async () => {
    const { chapters: book } = await pagesStore.book("home");
    chapters.value = book;
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.home {
  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
    flex-direction: column;
  }
}
</style>

import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import routes from "./routes";
import { useAlertStore, useGlobalStore } from "@/store";
import { useAuth2Store } from "@/store/modules/auth2.store";
import i18n from "@/i18n";
import { isShoppingEnabled } from "@/util/helpers";
import { ROUTE } from "@/router/routenames";
import { useHeaderStore } from "@/store/modules/header.store";
import { useAccountStore } from "@/store/modules/account.store";

/**
 * Create a new router instance.
 *
 * @param  {Array} routes
 * @return {Router}
 */
function make(routes) {
  const router = createRouter({
    history:
      process.env.VUE_APP_HISTORY === "hash"
        ? createWebHashHistory()
        : createWebHistory(),
    linkActiveClass: "navbar__item--active",
    linkExactActiveClass: "exact-active",
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        const fixedNavbarHeight =
          document.querySelector(".navbar--fixed")?.getBoundingClientRect()
            .height || 0;
        const stickyTabsHeight =
          document.querySelector(".tabs--sticky")?.getBoundingClientRect()
            .height || 0;
        const hashElement = document.querySelector(to.hash);
        const scrollToTop =
          hashElement.getBoundingClientRect().top +
          window.scrollY -
          fixedNavbarHeight -
          stickyTabsHeight;

        return {
          behavior: "smooth",
          top: scrollToTop > 0 ? scrollToTop : 0,
        };
      }

      if (to.meta?.scrollRemain) {
        return { x: window.scrollX, y: window.scrollY };
      }

      return { top: 0 };
    },
  });

  const showToastAndCleanUp = (to) => {
    useAlertStore().success(
      "",
      i18n.global.t(
        to.query.toast,
        localStorage.getItem("current_language") ||
          process.env.VUE_APP_I18N_FALLBACK_LOCALE,
      ),
    );

    setTimeout(() => {
      router.replace({
        name: to.name,
        params: to.params,
        query: to.query.exceptProps(["toast"]),
      });
    }, 4000);
  };

  // Register before guard.
  router.beforeEach(async (to, from, next) => {
    useHeaderStore().reset();

    if (
      useAuth2Store().isLoggedIn &&
      (await useAccountStore().notSetup()) &&
      to.name !== ROUTE.Dashboard.Settings
    ) {
      next({ name: ROUTE.Dashboard.Settings });
    }

    const globalStore = useGlobalStore();
    if (to.name != ROUTE.Discover) {
      globalStore.resetSearchData();
    }

    if (to.meta.requiresShopping && !isShoppingEnabled()) {
      next({ name: ROUTE.Home });
    }

    next();
  });

  // Register after hook.
  router.afterEach((to) => {
    useAlertStore().clear();

    if (to.query?.toast) {
      showToastAndCleanUp(to);
    }

    // const accountStore = useAccountStore();
    // if (accountStore.data) {
    // 	// Need to keep requesting opencart to preserve session
    // 	accountStore.request();
    // }
  });

  return router;
}

/**
 * Apply beforeEnter guard to the routes.
 *
 * @param  {Array} routes
 * @param  {Function} beforeEnter
 * @return {Array}
 */
function beforeEnter(routes, beforeEnter) {
  return routes.map((route) => {
    return { ...route, beforeEnter };
  });
}

function authGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    if (!useAuth2Store().isLoggedIn) {
      next({
        name: "login",
        query: { redirect: to.path },
      });
    } else {
      next();
    }
  });
}

/**
 * Redirect home if authenticated.
 *
 * @param  {Array} routes
 * @return {Array}
 */
function guestGuard(routes) {
  return beforeEnter(routes, (to, from, next) => {
    if (useAuth2Store().isLoggedIn) {
      next({ name: ROUTE.Home });
    } else {
      next();
    }
  });
}

const router = make(routes({ guestGuard, authGuard }));

export default router;

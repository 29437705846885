<template>
  <div class="navbar__collapse">
    <div v-if="isShoppingEnabled" class="navbar__navbar-collapse">
      <div class="navbar__megamenu-toggler">
        <a
          class="navbar__link dropdown-toggle"
          href="#"
          @click.prevent="$emit('toggle-mega-menu')"
        >
          <NavigationToggle />
        </a>
      </div>
    </div>
    <div class="navbar__navbar-collapse">
      <ul class="navbar__nav">
        <li class="navbar__item">
          <router-link
            active-class="navbar__link--active"
            class="navbar__link"
            :to="{ name: 'about' }"
          >
            {{ $t("navigation.about") }}
          </router-link>
        </li>
        <li class="navbar__item">
          <router-link
            active-class="navbar__link--active"
            class="navbar__link"
            :to="{ name: 'howitworks' }"
          >
            {{ $t("navigation.how-it-works") }}
          </router-link>
        </li>
        <li v-if="isShoppingEnabled" class="navbar__item">
          <router-link
            active-class="navbar__link--active"
            class="navbar__link"
            :to="{ name: 'page', params: { id: aboutPage } }"
          >
            {{ $t("footer.hybridlab.about") }}
          </router-link>
        </li>
        <li v-if="isShoppingEnabled" class="navbar__item">
          <router-link
            active-class="navbar__link--active"
            :to="{ name: 'contacts' }"
            class="navbar__link"
          >
            {{ $t("navigation.help") }}
          </router-link>
        </li>
        <DropdownItem v-if="isShoppingEnabled" :items="projectItems">
          {{ $t("navigation.projects.dropdown") }}
        </DropdownItem>
        <template v-if="!isShoppingEnabled">
          <li v-if="auth2Store.isLoggedIn" class="navbar__item">
            <router-link
              active-class="navbar__link--active"
              :to="{ name: ROUTE.Dashboard.Courses }"
              class="navbar__link"
            >
              {{ $t("navigation.courses") }}
            </router-link>
          </li>
        </template>
        <template v-else>
          <DropdownItem v-if="auth2Store.isLoggedIn" :items="courseItems">
            {{ $t("navigation.courses") }}
          </DropdownItem>
          <li v-else class="navbar__item">
            <router-link
              active-class="navbar__link--active"
              class="navbar__link navbar__link--icon"
              :to="{ name: ROUTE.Discover }"
            >
              {{ $t("navigation.courses") }}
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ROUTE } from "@/router/routenames";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { computed } from "vue";
import DropdownItem from "@/components/navbar/DropdownItem.vue";
import NavigationToggle from "@/components/NavigationToggle.vue";
import i18n from "@/i18n";

const aboutPage = process.env.VUE_APP_ABOUT_PAGE;

defineProps({
  isShoppingEnabled: { type: Boolean, required: true },
});
defineEmits(["toggle-mega-menu"]);

const auth2Store = useAuth2Store();

const projectItems = computed(() => [
  {
    to: { name: "projects.in-progress" },
    label: i18n.global.t("navigation.projects.in_progress"),
  },
  {
    to: { name: "projects.done" },
    label: i18n.global.t("navigation.projects.done"),
  },
]);
const courseItems = computed(() => [
  {
    to: { name: ROUTE.Dashboard.Courses },
    label: i18n.global.t("common.my_courses"),
  },
  {
    to: { name: "discover" },
    label: i18n.global.t("common.all_courses"),
  },
]);
</script>

<style scoped lang="scss">
.navbar__collapse {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navbar__navbar-collapse {
  display: flex;
  align-items: center;
  gap: 20px;

  ul {
    padding-left: 0;
    list-style-type: none;
    display: flex;
  }
}

.navbar__megamenu-toggler a {
  position: relative;
  letter-spacing: 0;
}

.navbar__megamenu-toggler a:after {
  background: url("../assets/menu/arrow-down-blue.svg");
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
}

.navbar__item {
  display: flex;
}

.navbar__item--active {
  font-weight: bold;
}

.navbar__link,
:deep(.navbar__link) {
  padding: 5px 20px;
  color: $color-primary;
  font-family: $font-family;
  font-size: $font-size-xs;
  text-transform: uppercase;
  line-height: 15px;
  font-weight: 500;

  display: flex;
  align-items: center;
  text-decoration: none;

  @media screen and (max-width: $breakpoint-lg) {
    opacity: 1;
  }
}

.navbar__link--icon {
  @media screen and (max-width: 900px) {
    padding: 5px 0;
  }

  img {
    margin-right: 10px;
  }
}
</style>

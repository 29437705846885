<template>
  <div class="percent-block">
    <div
      class="percent-block__text"
      :class="{ 'percent-block__text--full': textColor }"
    />
    <svg
      class="percent-block__svg"
      :class="{ 'percent-block__svg--full': textColor }"
      viewbox="0 0 100 100"
      width="100%"
      height="100%"
    >
      <circle
        v-if="!textColor"
        cx="50%"
        cy="50%"
        :r="r"
        fill="red"
        :style="{ 'stroke-dasharray': `100 999` }"
      />
      <circle
        cx="50%"
        cy="50%"
        :r="r"
        :style="{ 'stroke-dasharray': `${computePercent} 999` }"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 23,
    },
  },
  data() {
    return {
      innerPercent: 0,
      x: 11,
      y: 11,
      r: 9.5,
    };
  },
  computed: {
    textColor() {
      return this.innerPercent === 100;
    },
    computePercent() {
      let roundCircum = 2 * this.r * Math.PI;
      return (this.innerPercent * roundCircum) / 100;
    },
  },
  created() {
    this.innerPercent = this.percent;
  },
  methods: {
    update() {
      if (this.innerPercent === 100) {
        this.innerPercent = 0;
      } else {
        this.innerPercent++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 22px;
$height: 22px;
$stroke: 3px;

.percent-block {
  height: $width;
  width: $height;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;

  &__svg {
    circle {
      &:first-child {
        fill: none;
        stroke: $color-light-blue;
        stroke-width: $stroke;
        stroke-linecap: round;
        stroke-dasharray: 0 999;
      }

      &:last-child {
        fill: none;
        stroke: $color-light-green;
        stroke-width: $stroke;
        stroke-linecap: round;
        stroke-dasharray: 0 999;
      }
    }

    transform: rotate(-90deg);
    transition: all 1s ease-in-out;

    &--full {
      circle {
        &:last-child {
          stroke: #4f864d;
          fill: $color-green;
        }
      }
    }
  }

  &__text {
    position: absolute;
    color: $color-blue;
    font-family: $font-family;
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13px;
    text-align: center;
    z-index: 2;

    &--full {
      color: white;
    }
  }
}
</style>

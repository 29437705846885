<template>
  <div>
    <QRCode v-if="modal" @close="toggleQr()" />
    <div class="header-block">
      <div class="container">
        <div class="header-block__wrap">
          <div class="header-block__block">
            <div class="tabs">
              <ul class="tabs__wrap">
                <router-link
                  v-for="(link, i) in routerLinks"
                  :key="i"
                  router-link-active
                  tag="li"
                  class="tabs__tab"
                  exact-active-class="tabs__tab--active"
                  class-active="activae"
                  :to="link.to"
                >
                  {{ $t(link.text) }}
                </router-link>
              </ul>
            </div>
          </div>
          <SecondDevice v-if="!isMobile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from "@/components/modals/QRCode.vue";
import { computed, onMounted, ref } from "vue";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import useIsMobile from "@/util/useIsMobile";
import SecondDevice from "@/components/SecondDevice.vue";
import { useFavouriteCourses } from "@/store/modules/favouriteCourses";

const favouriteCoursesStore = useFavouriteCourses();

const { isMobile } = useIsMobile();

const modal = ref(false);

const routerLinks = computed(() => [
  {
    to: { name: ROUTE.Dashboard.Dashboard },
    text: "common.dashboard",
  },
  {
    to: { name: ROUTE.Dashboard.Courses },
    text: "common.my_courses",
  },
  ...shoppingEnabledRoute(
    ROUTE.Dashboard.Purchases,
    "common.purchases_history",
  ),
  ...shoppingEnabledRoute(
    ROUTE.Dashboard.Certificates,
    "common.my_certificates",
  ),
  ...(favouriteCoursesStore.favouriteCourses.length
    ? shoppingEnabledRoute(ROUTE.Dashboard.Favourites, "common.favourites")
    : []),
]);

const toggleQr = () => {
  modal.value = !modal.value;
};

const shoppingEnabledRoute = (name, text) =>
  isShoppingEnabled()
    ? [
        {
          to: { name },
          text: text,
        },
      ]
    : [];

onMounted(() => {
  favouriteCoursesStore.request();
});
</script>

<style lang="scss" scoped>
.tabs {
  &__wrap {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-left: 0;
    flex-direction: row;
    gap: 0 40px;
    margin: 0;

    @media screen and (max-width: $breakpoint-lg) {
      padding-top: 32px;
      overflow: auto;
      white-space: nowrap;
      align-items: stretch;
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__tab {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    white-space: nowrap;
    min-width: unset;
    width: auto;
    text-align: center;

    @media screen and (max-width: $breakpoint-lg) {
      width: 100%;
      display: inline-block;
      padding: 12px 15px;
    }

    padding: 18px 15px;
    border-bottom: 3px solid transparent;

    &:hover {
      border-bottom: solid 3px $color-primary;
    }

    &--active {
      color: $color-primary;
      border-bottom: solid 3px $color-primary;
    }
  }
}

.header-block {
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #f1f5f9;
  background: white;

  @media screen and (max-width: $breakpoint-lg) {
    box-shadow: unset;
  }

  .container {
    padding: 0;
  }

  &__welcome-text {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-bottom: 20px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__block {
    @media screen and (max-width: $breakpoint-lg) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}
</style>

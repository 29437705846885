<template>
  <div>
    <template v-if="isMobileShop">
      <div class="mobile-profile-navigation">
        <div>
          <img
            :src="isMain ? CloseIcon : ArrowLongLeftBlueIcon"
            :alt="isMain ? 'close' : 'back'"
            @click="back"
          />
        </div>
        <div class="mobile-profile-navigation__title" v-text="title" />
        <div class="mobile-profile-navigation__action">&nbsp;</div>
      </div>
      <MobileShopHeader v-if="showMobileNavigation" />
      <slot name="pageHeader">
        <PageHeader
          v-if="pageHeader"
          :image="pageHeader?.image"
          :title="pageHeader?.title"
          :description="pageHeader?.description"
        />
      </slot>
      <slot></slot>
    </template>
    <template v-else>
      <Navigation v-if="showNavigation" />
      <slot name="pageHeader">
        <PageHeader
          v-if="pageHeader"
          :image="pageHeader?.image"
          :title="pageHeader?.title"
          :description="pageHeader?.description"
        />
      </slot>
      <HeaderBlock v-if="showHeaderBlock" />
      <slot></slot>
    </template>
    <Footer />
  </div>
</template>

<script setup>
import HeaderBlock from "@/components/dashboard/HeaderBlock.vue";
import Navigation from "@/components/Navigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import ArrowLongLeftBlueIcon from "@/assets/arrow_long_left_blue.svg";
import CloseIcon from "@/assets/close.svg";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";
import Footer from "@/components/Footer.vue";
import MobileShopHeader from "@/components/dashboard/MobileShopHeader.vue";

const props = defineProps({
  isMobileShop: {
    type: Boolean,
    required: true,
  },
  pageHeader: {
    type: Object,
    default: null,
  },
  showNavigation: {
    type: Boolean,
    required: true,
  },
  showHeaderBlock: {
    type: Boolean,
    required: true,
  },
  showMobileNavigation: {
    type: Boolean,
    default: false,
  },
  isMain: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});

const router = useRouter();

const back = () =>
  props.isMain
    ? router.push({ name: ROUTE.Home })
    : router.push({ name: ROUTE.Dashboard.Dashboard });
</script>

<style scoped lang="scss">
.mobile-profile-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 12px 20px 18px;

  &__title {
    font: 500 $font-size-lg $font-family;
    line-height: 15px;
    color: $color-primary;
  }

  &__action {
    width: 28px;
  }
}
</style>

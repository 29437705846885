<template>
  <div>
    <Navigation />

    <div class="container">
      <div class="help__faq">
        <h2>{{ $t("common.faq") }}</h2>
      </div>
      <div class="faqs">
        <router-link
          v-for="category in categories"
          :key="category.id"
          :to="{ name: 'faq-category', params: { category: category.id } }"
          class="faqs__block"
        >
          <div class="faqs__image">
            <img
              v-if="category.icon === '0.svg'"
              src="../../assets/faq/0.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '1.svg'"
              src="../../assets/faq/1.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '2.svg'"
              src="../../assets/faq/2.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '3.svg'"
              src="../../assets/faq/3.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '4.svg'"
              src="../../assets/faq/4.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '5.svg'"
              src="../../assets/faq/5.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '6.svg'"
              src="../../assets/faq/6.svg"
              alt="icon"
            />
            <img
              v-else-if="category.icon === '7.svg'"
              src="../../assets/faq/7.svg"
              alt="icon"
            />
          </div>
          <div class="faqs__text">
            <h4 v-dompurify-html="category.name"></h4>
            <p v-dompurify-html="category.intro"></p>
          </div>
        </router-link>
      </div>
    </div>
    <ContactUs />
    <Footer />
  </div>
</template>

<script setup>
import backService from "@/plugins/service";
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import ContactUs from "@/components/ContactUs.vue";
import { ref, watch } from "vue";
import i18n from "@/i18n";

const loading = ref(true);
const categories = ref([]);

const getCategories = () => {
  backService
    .get("/faq/categories")
    .then((response) => {
      loading.value = false;
      categories.value = response.data;
    })
    .catch((error) => {
      console.error(error);
      loading.value = false;
    });
};

getCategories();

watch(
  () => i18n.global.locale,
  () => getCategories(),
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.help {
  &__faq {
    margin-bottom: 30px;
    margin-top: 50px;

    h2 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $help-header-font-size;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }

  &__faq-header {
    padding: 14px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-weight: 100;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &__faq-content {
    margin-bottom: 20px;
  }

  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 21px;

    margin-bottom: 80px;
  }

  &__block {
    flex-basis: 100%;
    max-width: 386px;
    border: 1px solid #dcdee9;
    text-align: center;
    padding: 30px;
    height: 230px;
    text-decoration: none;
  }

  &__header {
    h2 {
      color: $color-blue;
      font-family: $font-family;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 53px;
    }

    margin-bottom: 40px;
    margin-top: 50px;
  }

  &__block-image {
  }

  &__block-header {
    margin-top: 32px;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-slg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
  }

  &__block-description {
    opacity: 0.5;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
}

.accordion__trigger {
  img {
    transition: all 0.5s;
  }
}

.accordion__trigger_active {
  img {
    transform: rotate(181deg);
  }

  h3 {
    opacity: 1;
    color: $color-blue !important;
  }
}

.faqs {
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 21px;

  &__block {
    display: flex;
    align-items: center;
    gap: 30px;
    border: 1px solid #dcdee9;
    min-width: 571px;
    text-decoration: none;
    min-height: 80px;
    padding: 21px 0;

    @media screen and (max-width: 900px) {
      min-width: initial;
      width: 100%;
    }

    img {
      padding: 24px 0px 24px 31.5px;
      height: 80px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 73px;
  }

  &__text {
    padding: 0px 21px 0px 0px;

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
    }

    p {
      opacity: 0.5;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
    }
  }
}
</style>

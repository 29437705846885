<template>
  <div v-if="course" class="current-course">
    <img :src="course.image" alt="" />
    <div class="current-course__content">
      <h3>{{ course.title }}</h3>
      <Progress
        :done="course.completed.done"
        :total="course.completed.total"
        :percent="percent"
        show-text
      />
    </div>
  </div>
</template>
<script>
import PercentOval from "@/components/PercentOval";
import Progress from "@/components/Progress.vue";

export default {
  components: {
    Progress,
    PercentOval,
  },
  props: {
    course: {},
  },
  data() {
    return {};
  },
  computed: {
    percent() {
      return Math.round(
        (this.course.completed.done / this.course.completed.total) * 100,
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.current-course {
  position: relative;
  height: 308px;

  img {
    width: 100%;
    height: 308px;
    object-fit: cover;
  }

  &__content {
    padding-top: 50px;
    padding-left: 30px;
    position: absolute;
    top: 0px;
    width: 100%;
    justify-content: flex-start;
    background-color: rgba(21, 31, 109, 0.3);
    height: 100%;

    h3 {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 31px;
    }
  }
}
</style>

<template>
  <div class="form-group">
    <label v-if="label" :for="name" @click="$refs.input.focus()"
      >{{ label }}
    </label>
    <textarea
      v-model="innerValue"
      :name="name"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :rows="rows"
    >
    </textarea>
  </div>
</template>

<script>
/** @deprecated */
export default {
  props: {
    rows: Number,
    maxLength: Number,
    inputValue: {
      default: "",
      type: [String, Number],
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    whiteInput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      innerValue: "",
    };
  },
  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val;
      }
    },
  },
  mounted() {
    if (this.inputValue !== null) {
      this.innerValue = this.inputValue;
    }
  },
};
</script>

<style scoped lang="scss">
.form-group {
  textarea {
    border: 1px solid $color-primary;
    opacity: 1;
    border-radius: 4px;
    padding: 15px 20px;
    display: block;
    width: 100%;
    font-family: $font-family;
  }
  .error {
    color: red;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-size: 13px;
    margin-top: 5px;
  }
}
</style>

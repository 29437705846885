<template>
  <div class="navbar-mobile">
    <div class="container">
      <div class="navbar-mobile__wrapper">
        <Search
          v-if="isSearchEnabled"
          :search-results="searchResults"
          :search-value="searchValue"
          @disable:search="emit('disable-search')"
          @search="emit('search', $event)"
          @submit:search="emit('submit-search', $event)"
          @show:suggestions="emit('show-suggestions', $event)"
          @open:course="emit('open-course', $event)"
        />
        <template v-else>
          <div class="navbar-mobile__wrap">
            <a href="#" @click.prevent="popupStore.openMenu()">
              <svg
                v-if="!open"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="14px"
                viewBox="0 0 24 14"
                version="1.1"
              >
                <title>EECCA10A-AF6E-4E74-9E42-EA54B9DB9D10</title>
                <g
                  id="desktop"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="homepage-mobile"
                    transform="translate(-20.000000, -134.000000)"
                    stroke="#1B214A"
                    stroke-width="2"
                  >
                    <g id="menu" transform="translate(0.000000, 107.000000)">
                      <path
                        id="Combined-Shape"
                        d="M20,28 L44,28 M20,34 L44,34 M20,40 L44,40"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="33.9411255px"
                height="33.9411255px"
                viewBox="0 0 33.9411255 33.9411255"
                version="1.1"
              >
                <title>24184269-9C75-49FD-9BC6-B549D1C06594</title>
                <g
                  id="desktop"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="sidemenu"
                    transform="translate(-15.029437, -124.029437)"
                    stroke="#1B214A"
                    stroke-width="2"
                  >
                    <g id="menu" transform="translate(0.000000, 107.000000)">
                      <path
                        id="Combined-Shape"
                        d="M20,34 L44,34 M32,22 L32,46"
                        transform="translate(32.000000, 34.000000) rotate(-315.000000) translate(-32.000000, -34.000000) "
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div>

          <div class="navbar-mobile__brand">
            <router-link :to="{ name: ROUTE.Home }">
              <img src="../assets/menu/hl-logo.svg" alt="hybridlab" />
            </router-link>

            <template v-if="!isShoppingEnabled">
              <router-link
                :to="{ name: ROUTE.Home }"
                :title="$t('common.home')"
              >
                <img src="../assets/menu/lsmu-logo.svg" alt="lsmu" />
              </router-link>
            </template>
          </div>

          <div class="navbar-mobile__wrap">
            <router-link
              v-if="isShoppingEnabled"
              class="navbar__link navbar__link--icon"
              :to="{ name: 'cart' }"
            >
              <div class="navbar__cart">
                <span v-if="!cartStore.isEmpty">
                  {{ cartStore.count }}
                </span>
                <img src="../assets/menu/cart-blue.svg" alt="cart" />
              </div>
            </router-link>
            <div
              v-if="auth2Store.isLoggedIn"
              class="navbar__link navbar__link--icon navbar__link--round"
              @click.prevent="popupStore.openMenuSettings"
            >
              <Avatar
                :image="settingsStore.values.image"
                :account="accountStore.values"
              />
            </div>
            <router-link
              v-else
              :to="{ name: ROUTE.Login }"
              class="navbar__link navbar__link--icon"
            >
              <img src="../assets/avatar.svg" alt="login" />
            </router-link>
          </div>
        </template>
      </div>
    </div>

    <div v-if="open" class="navbar-mobile-container">
      <div class="container">
        <ul class="navbar--mobile">
          <li class="navbar__item navbar__item--dropdown">
            <router-link class="navbar__link" :to="{ name: ROUTE.Discover }">
              {{ $t("megamenu.private.title") }}
            </router-link>
          </li>
          <li class="navbar__item navbar__item--dropdown">
            <router-link
              class="navbar__link"
              :to="{ name: 'business-and-institutions' }"
            >
              {{ $t("megamenu.business.title") }}
            </router-link>
          </li>
          <li class="navbar__item">
            <router-link class="navbar__link" :to="{ name: 'about' }">
              {{ $t("navigation.about") }}
            </router-link>
          </li>
          <li class="navbar__item">
            <router-link class="navbar__link" :to="{ name: 'howitworks' }">
              {{ $t("navigation.how-it-works") }}
            </router-link>
          </li>
          <li class="navbar__item">
            <router-link
              class="navbar__link"
              :to="{ name: 'page', params: { id: aboutPage } }"
            >
              {{ $t("footer.hybridlab.about") }}
            </router-link>
          </li>
          <li class="navbar__item">
            <router-link :to="{ name: 'contacts' }" class="navbar__link">
              {{ $t("navigation.help") }}
            </router-link>
          </li>
          <DropdownItemMobile
            :items="[
              {
                to: { name: 'projects.in-progress' },
                label: $t('navigation.projects.in_progress'),
              },
              {
                to: { name: 'projects.done' },
                label: $t('navigation.projects.done'),
              },
            ]"
          >
            {{ $t("navigation.projects.dropdown") }}
          </DropdownItemMobile>
          <template v-if="!isShoppingEnabled">
            <li v-if="auth2Store.isLoggedIn" class="navbar__item">
              <router-link
                :to="{ name: ROUTE.Dashboard.Courses }"
                class="navbar__link"
              >
                {{ $t("navigation.courses") }}
              </router-link>
            </li>
          </template>
          <template v-else>
            <DropdownItemMobile
              v-if="auth2Store.isLoggedIn"
              :items="[
                {
                  to: { name: ROUTE.Dashboard.Courses },
                  label: $t('common.my_courses'),
                },
                {
                  to: { name: 'discover' },
                  label: $t('common.all_courses'),
                },
              ]"
            >
              {{ $t("navigation.courses") }}
            </DropdownItemMobile>
            <li v-else class="navbar__item">
              <router-link :to="{ name: ROUTE.Discover }" class="navbar__link">
                {{ $t("navigation.courses") }}
              </router-link>
            </li>
          </template>
        </ul>

        <ul class="navbar--languages">
          <LanguageDropdown :callback="changeLanguage" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import Search from "@/components/Search.vue";
import { useStores } from "@/store/use.store";
import { ROUTE } from "@/router/routenames";
import Avatar from "@/components/Avatar.vue";
import LanguageDropdown from "@/components/navbar/LanguageDropdown.vue";
import DropdownItemMobile from "@/components/navbar/DropdownItemMobile.vue";

const aboutPage = process.env.VUE_APP_ABOUT_PAGE;

defineProps({
  isSearchEnabled: { type: Boolean, required: true },
  isShoppingEnabled: { type: Boolean, required: true },
  searchResults: { type: Array, default: () => [] },
  searchValue: { type: String, default: "" },
  open: { type: Boolean, required: true },
  changeLanguage: { type: Function, required: true },
});

const emit = defineEmits([
  "disable-search",
  "search",
  "submit-search",
  "show-suggestions",
  "open-course",
]);

const { popupStore, auth2Store, settingsStore, accountStore, cartStore } =
  useStores();
</script>

<style scoped lang="scss">
.navbar-mobile {
  position: sticky;
  background: white;
  width: 100%;
  top: 0;
  display: none;
  height: $header-height;
  z-index: 99;

  @media screen and (max-width: $breakpoint-xl) {
    display: flex;
    align-items: center;
  }

  &-container {
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - $header-height);
    background: white;
    z-index: 99;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      svg {
        width: 30px;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__brand {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      height: 52px;
    }
  }
}

.navbar__link--icon {
  @media screen and (max-width: 900px) {
    padding: 5px 0;
  }

  img {
    margin-right: 10px;
  }
}

.navbar__link--round {
  img {
    border-radius: 30px;
  }
}

.navbar__cart {
  display: flex;
  align-items: center;
}
</style>

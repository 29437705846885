<template>
  <div>
    <Navigation :is-static="false" />
    <PageHeader
      :image="header?.image"
      :title="header?.title"
      :description="header?.description"
    >
      <template #content>
        <h2 v-dompurify-html="purchasedStore.course?.title"></h2>
        <div class="header-content">
          <Progress
            :done="gradesStore.data?.gradedItems"
            :total="gradesStore.data?.totalItems"
            :percent="gradedCoursesPercent"
            show-text
          />
          <div class="header-content__vertical-line" />
          <span
            class="header-content__grade"
            v-text="$t('grades.grade_text', { grade: gradesStore.data?.grade })"
          />
        </div>
      </template>
    </PageHeader>
    <Loading v-if="gradesStore.loading" />
    <div v-else class="container">
      <go-back-button @go:back="goBack" />
      <span
        v-if="!gradesStore.showGrades"
        class="text-danger grades-not-visible"
        v-text="$t('grades.you_are_not_allowed_to_see_grades_for_this_course')"
      />
      <div v-else class="grades-category">
        <accordion :padding="34">
          <grade-category
            v-for="children in gradesStore.data.children"
            :key="children.id"
            :category="children"
          />
        </accordion>
        <grade-items-table
          :items="gradesStore.data.items"
          :base-category="gradesStore.data"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { useGradesStore } from "@/store/modules/grades.store";
import { computed, onMounted, ref, watch } from "vue";
import Accordion from "@/components/accordion/Accordion.vue";
import GradeItemsTable from "@/components/grade/GradeItemsTable.vue";
import GradeCategory from "@/components/grade/GradeCategory.vue";
import { useRoute } from "vue-router";
import { usePageStore } from "@/store/modules/page.store";
import i18n from "@/i18n";
import Navigation from "@/components/Navigation.vue";
import PageHeader from "@/components/PageHeader.vue";
import Footer from "@/components/Footer.vue";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import Progress from "@/components/Progress.vue";
import GoBackButton from "@/components/navigation/GoBackButton.vue";
import Loading from "@/components/Loading.vue";

const gradesStore = useGradesStore();
const pagesStore = usePageStore();
const purchasedStore = getPurchasedStore();

const route = useRoute();

const header = ref(null);
const chapters = ref([]);

const gradedCoursesPercent = computed(() => 0);

const requestHeader = async () => {
  const { id, chapters: book } = await pagesStore.book("grades");
  header.value = await pagesStore.request(id);
  chapters.value = book;
};
const goBack = () => {
  history.back();
};

onMounted(async () => {
  await purchasedStore.request(route.params.course);
  await gradesStore.request();
  requestHeader();
});

watch(
  () => i18n.global.locale,
  async () => {
    requestHeader();
  },
);
</script>

<style lang="scss" scoped>
.accordion {
  @media only screen and (max-width: $breakpoint-sm) {
    margin-bottom: 0;
  }
}
.grades-not-visible {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  &__vertical-line {
    width: 1px;
    background-color: #ffffff;
    opacity: 0.2;
    height: 15px;
  }

  &__grade {
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size-md;
    color: $color-white;
  }
}
</style>

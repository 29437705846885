<template>
  <div class="courses">
    <div class="courses__header">
      <div class="courses__column courses__column--switch">
        <SearchForm
          v-if="isSearchEnabled"
          :route-name="routeName"
          @disable:search="disableSearch"
        />
        <div
          v-if="!isSearchEnabled"
          class="courses__column courses__column--switch__wrapper"
        >
          <h3 v-if="title" v-text="title" />
        </div>
        <a
          v-if="!isSearchEnabled"
          href="#"
          class="navbar__link navbar__link__right"
          @click="toggleSearch"
        >
          <img src="../assets/icons/search-gray.svg" alt="search" />
        </a>
      </div>
      <div class="courses__header-column"></div>
    </div>

    <div class="courses__wrap">
      <Loading v-if="purchasedStore.loading" />
      <div v-else-if="!courses.length" class="courses__no-courses">
        <img :src="EmptyCoursesIcon" alt="courses" />
        <h3
          class="courses__no-courses-title"
          v-text="$t('common.no_enrolled_courses')"
        />
        <p
          v-if="isShopEnabled"
          class="courses__no-courses-description"
          v-text="$t('common.no_enrolled_courses')"
        />
        <custom-button
          v-if="isShopEnabled"
          :margin-top="75"
          class="courses__no-courses-list-button"
          type="default"
          size="md"
          @click="goToCourseList"
        >
          <template #text>
            <div class="courses__no-courses-list-button-text">
              <img :src="ArrowLongLeftBlue" alt="courses" />
              <span v-text="$t('common.go_course_list')" />
            </div>
          </template>
        </custom-button>
      </div>
      <CourseListItem
        v-for="course in courses"
        :key="course.id"
        :course="course"
      />
    </div>
  </div>
</template>

<script setup>
import SearchForm from "@/components/search/SearchForm.vue";
import Loading from "@/components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import CourseListItem from "@/components/course/CourseListItem.vue";
import CustomButton from "@/components/CustomButton.vue";
import EmptyCoursesIcon from "@/assets/no-results/courses.svg";
import ArrowLongLeftBlue from "@/assets/arrow_long_left_blue.svg";
import { computed, ref, watch } from "vue";
import { ROUTE } from "@/router/routenames";
import { isShoppingEnabled } from "@/util/helpers";
import { getPurchasedStore } from "@/store/modules/purchased.store";

const props = defineProps({
  routeName: { type: String, required: true },
  courses: { type: Array, default: () => [] },
  title: { type: String, default: "" },
  searchOpen: { type: Boolean, default: false },
});
const emit = defineEmits(["filter:courses"]);

const route = useRoute();
const router = useRouter();
const purchasedStore = getPurchasedStore();

const isSearchEnabled = ref(props.searchOpen);

getCourses();

const isShopEnabled = computed(() => isShoppingEnabled());

const goToCourseList = () => router.push({ name: ROUTE.Discover });
const toggleSearch = (event) => {
  event.preventDefault();
  isSearchEnabled.value = !isSearchEnabled.value;
};
const disableSearch = () => {
  isSearchEnabled.value = false;
};

watch(
  () => route.query.q,
  () => getCourses(),
);

async function getCourses() {
  if (route.query.q) {
    isSearchEnabled.value = true;
  }
  emit("filter:courses", route.query.q);
}
</script>

<style lang="scss" scoped>
.courses {
  padding-top: 30px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    h3 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }
  }

  &__no-courses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-title {
      margin-top: 46px;
      font-size: 500 $font-size-lg $font-family;
      color: $color-primary;
    }

    &-description {
      margin-top: 26px;
      font-size: 500 $font-size-md $font-family;
      color: $color-primary;
    }

    &-list-button {
      width: 100%;
      max-width: 330px;
      padding: 0 60px;

      &-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        font: 500 $font-size-md $font-family;
        color: $color-primary;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }

  &__wrap {
    @media only screen and (max-width: $breakpoint-sm) {
      display: grid;
      grid-template-columns: auto auto;
      row-gap: 50px;
      column-gap: 20px;
    }
  }

  &__settings {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 900px) {
      // flex-direction: column;
    }
  }

  &__settings-label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__column {
    display: flex;
    align-items: center;

    &--switch {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: $breakpoint-lg) {
        align-items: flex-start;
      }

      &__wrapper {
        gap: 40px;
        @media screen and (max-width: $breakpoint-lg) {
          gap: 10px;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      form {
        width: 100%;
      }
    }
  }
}
</style>

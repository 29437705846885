<template>
  <div>
    <Modal :name="'share_course'" :width="480" :toggle="true" @close="close()">
      <template #header>
        <h3>{{ $t("common.share_course") }}</h3>
      </template>
      <template #body>
        <div class="share-course">
          <div class="share-course__block">
            <p>{{ $t("common.share_on") }}</p>
            <div class="share-course__socials">
              <a
                :href="`https://www.linkedin.com/sharing/share-offsite/?url=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/linkedin-in.svg" alt=""
              /></a>
              <a
                :href="`https://www.facebook.com/sharer.php?u=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/facebook-f.svg" alt=""
              /></a>
              <a
                :href="`fb-messenger://share/?link=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/facebook-messenger.svg" alt=""
              /></a>
              <a
                :href="`https://www.instagram.com/?url=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/instagram.svg" alt=""
              /></a>
              <a
                :href="`https://twitter.com/intent/tweet?url=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/twitter.svg" alt=""
              /></a>
              <a
                :href="`whatsapp://send?text=${getUrl()}`"
                data-action="share/whatsapp/share"
                class="share-course__social"
                ><img src="../../assets/socials/whatsapp.svg" alt=""
              /></a>
              <a
                :href="`https://telegram.me/share/url?url=${getUrl()}`"
                class="share-course__social"
                ><img src="../../assets/socials/telegram-plane.svg" alt=""
              /></a>
            </div>
          </div>
          <div class="share-course__block">
            <p>{{ $t("common.or_copy_link") }}</p>

            <div class="form-group-share">
              <img src="../../assets/link.svg" />
              <div class="form-group-share__wrap">
                <input class="" :value="getUrl()" />
                <CustomButton type="copy" @click="copy()">
                  <template #text>
                    {{ $t("common.copy") }}
                  </template>
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import CustomButton from "@/components/CustomButton";
import Modal from "@/components/Modal";
import { useAlertStore } from "@/store";

export default {
  components: {
    CustomButton,
    Modal,
  },
  setup() {
    const alertStore = useAlertStore();
    return {
      alertStore,
    };
  },
  data() {
    return {};
  },
  methods: {
    getUrl() {
      return window.location.href;
    },
    async copy() {
      await navigator.clipboard.writeText(this.getUrl());
      this.alertStore.success("Pavyko!", "Nuorodą sėkmingai nukopijuota.");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group-share {
  width: 396px;
  border: 1px solid #dcdee9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  img {
    margin-left: 10px;
    margin-right: 10px;
  }

  input {
    flex-basis: 100%;
    height: 30px;
    border: 1px solid transparent;
    outline: none;

    &:focus {
      border-color: transparent;
    }
  }
}

.share-course {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__block {
    p {
      margin-bottom: 12px;

      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 10px;

    a {
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border: 1px solid #dcdee9;
      background-color: #ffffff;
    }
  }
}
</style>

import axios from "axios";
import i18n from "@/i18n";
import { convertEnToGb } from "./axios";
import { useAuth2Store } from "@/store/modules/auth2.store";

const backService = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

backService.interceptors.request.use(async (request) => {
  const auth2Store = useAuth2Store();

  request.headers["Accept-Language"] = i18n.global.locale;
  request.headers["X-Oc-Merchant-Language"] = convertEnToGb(i18n.global.locale);

  if (auth2Store.token) {
    request.headers.Authorization = `Bearer ${auth2Store.token}`;
  }

  return request;
});

export default backService;

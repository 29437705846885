<template>
  <div class="form-group">
    <label v-if="label" :for="id">
      {{ label }}
      <slot name="label" />
    </label>
    <textarea
      v-model="innerValue"
      :name="name"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :rows="rows"
      :disabled="disabled"
    >
    </textarea>
    <ErrorMessage
      v-if="errors.length && name"
      v-slot="{ message }"
      :name="name"
    >
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { uniqid } from "@/plugins/misc";
import { ErrorMessage } from "vee-validate";
import { computed, defineEmits, defineProps, ref } from "vue";

const id = uniqid("textarea-input-");
const input = ref(null);
const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: [String, Number],
  label: String,
  placeholder: String,
  name: String,
  maxLength: Number,
  rows: [Number],
  errors: {
    type: Array,
    default: () => [],
  },
});

// eslint-disable-next-line no-unused-vars
const focus = () => input.value.focus();
const innerValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>

<style lang="scss">
.form-group {
  textarea {
    border: 1px solid $color-primary;
    opacity: 1;
    border-radius: 4px;
    padding: 15px 20px;
    display: block;
    width: 100%;
    font-family: $font-family;
  }
  .error {
    color: red;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-size: 13px;
    margin-top: 5px;
  }
}
</style>

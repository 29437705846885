<template>
  <div>
    <Navigation />

    <div class="information">
      <div class="container">
        <Loading v-if="loading" />
        <h2 v-dompurify-html="data.title"></h2>

        <div
          v-dompurify-html="data.description"
          class="information__content"
        ></div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import Loading from "@/components/Loading.vue";
import { useRoute } from "vue-router";
import axios from "axios";

export default {
  components: {
    Navigation,
    Footer,
    Loading,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    pageId() {
      return this.$route.meta.id || this.route.params.id;
    },
  },
  watch: {
    pageId: {
      handler(newValue, oldValue) {
        this.getInformationPage(newValue);
      },
    },
    "$i18n.locale": function (newVal, oldVal) {
      this.getInformationPage(this.pageId);
    },
  },
  async mounted() {
    await this.getInformationPage(this.pageId);
  },
  async created() {},
  methods: {
    async getInformationPage(id) {
      let vm = this;
      this.loading = true;
      await axios
        .get(`/information/${id}`)
        .then((response) => {
          vm.data = response.data.data;
          vm.loading = false;
        })
        .catch((error) => {
          vm.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.information {
  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: right;
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: center;

    margin-top: 40px;
    margin-bottom: 40px;

    @media screen and (max-width: $breakpoint-sm) {
      line-height: 40px;
      text-align: center;
    }
  }

  &__content {
    color: $color-blue;
    font-family: $font-family;
    margin-bottom: 30px;

    ul {
      li {
        line-height: 1.38;
      }
    }

    p {
      @media screen and (max-width: $breakpoint-sm) {
        line-height: 30px;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
